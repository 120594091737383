import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Button, Loading } from 'components/elements';
import { Constants } from 'components/main/calendar/utils';
import Toast from 'components/toast';
import { Card } from 'interfaces/cards';
import moment from 'moment';
import { FC, useContext, useEffect, useState } from 'react';
import CardsService from 'services/card-service';
import { EventNames } from 'services/provider-app-mixpanel-events';
import { ProviderAppMixpanelInstance } from 'services/provider-app-mixpanel-service';
import { UserContext } from 'state/contexts/user';

const BASE_CARD_EDIT_URL = 'https://www.enaradrive.com/cards/';

const RecentCards: FC<{ patientId: number }> = ({ patientId }) => {
  const { state: userState } = useContext(UserContext);

  const [cards, setCards] = useState<Card[] | undefined>();
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(false);

  const getRecentCards = async () => {
    setLoading(true);
    const recentCards = await CardsService.getRecentCards(patientId);
    setLoading(false);

    if (!recentCards) {
      return Toast.show('error', `Error loading recent cards, please try again later!`);
    }

    setTotalRows(recentCards.length);
    setCards(recentCards);
  };

  useEffect(() => {
    getRecentCards();
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const handleEditCard = (cardId: number) => () => {
    if (!cardId) {
      return;
    }

    ProviderAppMixpanelInstance.track({
      eventName: EventNames.RecentCardEdit,
      cardId,
      memberId: patientId,
      providerId: userState.id,
    });

    window.open(`${BASE_CARD_EDIT_URL}${cardId}/edit`, '_blank');
  };

  const displayCardsTable = () => {
    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cards?.map((card) => {
                return (
                  <TableRow key={card.uuid} component='tr'>
                    <TableCell>{moment(card.createdAt).format(Constants.DATE_FORMAT)}</TableCell>
                    <TableCell>{card.title}</TableCell>
                    <Button variant='link' onClick={handleEditCard(card.id)}>
                      Edit
                    </Button>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component='div'
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    );
  };

  const displayContent = () => {
    if (loading) {
      return <Loading />;
    }

    if (!cards || cards.length === 0) {
      return <div>No recent cards found</div>;
    }

    return displayCardsTable();
  };

  return displayContent();
};

export default RecentCards;
