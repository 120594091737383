import api from 'api';
import axios from 'axios';
import { Button } from 'components/elements';
import Spinner from 'components/elements/loaders/spinner';
import { SectionMessage } from 'components/general-styles';
import Icon from 'components/icon';
import moment from 'moment-timezone';
import { schedulingV3Client } from 'node-api/scheduleV3/client.v3';
import { FC, useEffect, useState } from 'react';
import { EventNames } from 'services/provider-app-mixpanel-events';
import { ProviderAppMixpanelInstance } from 'services/provider-app-mixpanel-service';
import { ClickableElement } from 'services/shared-mixpanel-service';
import { debug } from 'utils/helpers';
import { DEFAULT_TIMEZONE } from 'utils/timezones';
import {
  Action,
  Call,
  Day,
  Description,
  Image,
  Information,
  Item,
  Moment,
  Month,
  PatientImage,
  PatientMissing,
  PatientName,
  Time,
} from './style';

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss Z';

type ScheduleMember = { id: number; uuid: string; name: string; picture: string };

type ScheduleCall = {
  id: number;
  start_at: string;
  end_at: string;
  patient: ScheduleMember;
  provider: ScheduleMember;
};

export const ActiveSessions: FC<{ onDisplayMember: (p: { uuid: string }) => void }> = ({
  onDisplayMember,
}) => {
  const [callsList, setCallsList] = useState<ScheduleCall[]>([]);
  const [now, setNow] = useState(moment());
  const [pagination, setPagination] = useState({ current: 1, total: 0 });
  const [fetching, setFetching] = useState(true);
  const [loading, setLoading] = useState(false);
  const [calling, setCalling] = useState(false);
  const [callingId, setCallingId] = useState(0);

  const handleLoad = async () => {
    setLoading(true);

    try {
      const { data } = await api().get<{ schedulings: ScheduleCall[] }>(
        'appointments/provider/AUTH_TOKEN/online',
        {
          params: {
            page: pagination.current + 1,
          },
        }
      );

      setPagination((c) => ({
        ...c,
        current: pagination.current + 1,
      }));

      setCallsList((c) => [...c, ...data.schedulings]);
    } catch (err: unknown) {
      if (!axios.isCancel(err)) {
        debug(err as string);
      }
    }

    setLoading(false);
  };

  const handleCallPatient = async (e: null, call: ScheduleCall) => {
    setCallingId(call.id);
    setCalling(true);

    ProviderAppMixpanelInstance.track({
      eventName: EventNames.MemberCallJoinAppointmentSummary,
      targetLabel: 'Join Call',
      targetType: ClickableElement.BUTTON,
      memberUuid: call.patient.uuid,
      memberName: `${call.patient.name}`,
      providerUuid: call.provider.uuid,
      source: 'appointment-summary',
    });

    try {
      const url = await schedulingV3Client.getProviderVideoUrl(
        call.patient,
        {
          id: call.provider.id,
          uuid: call.provider.uuid,
        },
        'provider',
        'appointment-summary'
      );
      window.open(url, '_blank');
    } catch (err: unknown) {
      debug(err as string);
    }

    setCalling(false);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getList = async () => {
      try {
        const { data } = await api().get('appointments/provider/AUTH_TOKEN/online', {
          params: {
            page: 1,
          },
          cancelToken: source.token,
        });

        setPagination({
          current: 1,
          total: data.pages,
        });

        setCallsList(data.schedulings || []);
        setFetching(false);
      } catch (err) {
        if (!axios.isCancel(err)) {
          debug(err as string);
          setFetching(false);
        }
      }
    };

    getList();
    const updateNow = setInterval(() => setNow(moment()), 10000);

    return () => {
      source.cancel();
      clearInterval(updateNow);
    };
  }, []);

  return (
    <>
      {fetching && (
        <SectionMessage>
          <p>Loading appointments</p>
          <Spinner size={20} />
        </SectionMessage>
      )}

      {!fetching && callsList.length === 0 && (
        <SectionMessage>
          <p>There are no upcoming appointments</p>
        </SectionMessage>
      )}

      {!fetching && callsList.length > 0 && (
        <>
          {callsList.map((call) => {
            const isPatientMissing = !(call.patient && Object.keys(call.patient).length > 0);

            const startAtWithTimezone = moment.tz(
              moment(call.start_at, DATE_TIME_FORMAT),
              DEFAULT_TIMEZONE
            );

            const live = now.isSame(startAtWithTimezone, 'd');

            const status = now.isBetween(
              startAtWithTimezone.clone().subtract(5, 'minutes'),
              startAtWithTimezone.clone().add(30, 'minutes')
            );

            return (
              <Call key={`call-${call.id}`}>
                <Moment>
                  <Day>{startAtWithTimezone.format('DD')}</Day>
                  <Month>{startAtWithTimezone.format('MMMM')}</Month>
                  <Time>{startAtWithTimezone.format('hh:mm A')}</Time>
                </Moment>

                <Information isPatientMissing={isPatientMissing}>
                  {isPatientMissing === false ? (
                    <Item>
                      <PatientImage onClick={() => onDisplayMember(call.patient)}>
                        {call.patient.picture ? (
                          <img src={call.patient.picture} alt={`${call.patient.name}`} />
                        ) : (
                          <Icon icon='userBubble' color='turquoise' size={30} />
                        )}
                      </PatientImage>
                      <Description>
                        <h4>Patient</h4>

                        <PatientName onClick={() => onDisplayMember(call.patient)}>
                          <p>{call.patient.name}</p>
                        </PatientName>
                      </Description>
                    </Item>
                  ) : (
                    <Item>
                      <PatientImage isPatientMissing={isPatientMissing}>
                        <Icon icon='userBubble' color='grayLight' size={30} />
                      </PatientImage>
                      <Description>
                        <h4>Patient</h4>
                        <div>
                          <PatientMissing>Missing</PatientMissing>
                        </div>
                      </Description>
                    </Item>
                  )}

                  <Item>
                    <Image>
                      {call.provider.picture ? (
                        <img src={call.provider.picture} alt={`${call.provider.name}`} />
                      ) : (
                        <Icon icon='userBubble' color='turquoise' size={30} />
                      )}
                    </Image>
                    <Description>
                      <h4>Provider</h4>
                      <p>{call.provider.name}</p>
                    </Description>
                  </Item>

                  <Item>
                    <Description>
                      <h4>Status</h4>
                      <p>{status ? 'Live' : 'Not live'}</p>
                    </Description>
                  </Item>

                  <Item>
                    <Button
                      onClick={(e) => handleCallPatient(e, call)}
                      disabled={!live || isPatientMissing}
                      submitting={calling && callingId === call.id}
                      size='normal'>
                      Join call
                    </Button>
                  </Item>
                </Information>
              </Call>
            );
          })}

          <Action>
            {pagination.current + 1 <= pagination.total && (
              <Button submitting={loading} onClick={handleLoad}>
                Load more
              </Button>
            )}
          </Action>
        </>
      )}
    </>
  );
};
