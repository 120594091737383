import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';
import { Action, FeaturesState, initialState, reducer } from '../reducers/features';

const FeaturesContext = createContext<{ state: FeaturesState; dispatch: Dispatch<Action> }>({
  state: initialState,
  dispatch: () => null,
});

const FeaturesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <FeaturesContext.Provider value={{ state, dispatch }}>{children}</FeaturesContext.Provider>
  );
};

export { FeaturesContext, FeaturesProvider };
