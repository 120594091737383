import LegacyMixpanelService from 'services/mixpanel-service';
import { ProviderAppMixpanelInstance } from 'services/provider-app-mixpanel-service';

interface UserForMixpanelConfig {
  id: number;
  uuid: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  avatar: string;
  birthdate: string;
}

export function configureMixpanel(user: UserForMixpanelConfig) {
  LegacyMixpanelService.set({
    $first_name: user.first_name,
    $last_name: user.last_name,
    $email: user.email,
    $phone: user.phone,
    $avatar: user.avatar,
    Birthdate: user.birthdate,
  });
  LegacyMixpanelService.identify(user.id);

  ProviderAppMixpanelInstance.identify(user.uuid);
  ProviderAppMixpanelInstance.set({
    userId: user.id,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    phone: user.phone,
    avatar: user.avatar,
    birthdate: user.birthdate,
  });
}
