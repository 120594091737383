import { Reducer } from 'react';
import { Action as DefaultAction } from 'state/types';
import { Provider } from './provider';

export enum ActionTypes {
  SET = 'SET',
  TOGGLE_MODAL = 'TOGGLE_MODAL',
}

export type State = {
  modal: boolean;
  data: Provider[];
};

type Payload = {
  [ActionTypes.TOGGLE_MODAL]: never;
  [ActionTypes.SET]: {
    data: Provider[];
  };
};

export type Action = DefaultAction<Payload>;

export const initialState: State = {
  modal: false,
  data: [],
};

export const reducer: Reducer<State, Action> = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET: {
      const { payload } = action;
      return {
        ...state,
        data: payload.data,
      };
    }

    case ActionTypes.TOGGLE_MODAL: {
      return {
        ...state,
        modal: !state.modal,
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};
