import { Box, Chip, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { DIAGNOSES } from 'components/elements/notes/medical/diagnoses';
import {
  DiagnosisOutput,
  DiagnosisSummaryContentType,
  MeetingDurationContentType,
} from 'node-api/scribe/Scribe.types';
import { EvaluationAndManagementByCPTCode, NoteEditionHandlers } from './render-sections';

type Props = {
  content: DiagnosisSummaryContentType & { meetingDurationContent: MeetingDurationContentType };
  handlers: NoteEditionHandlers;
};

export const DiagnosisSummarySection = ({ content, handlers }: Props) => {
  return (
    <Box mb={2}>
      <Typography mb={1} fontWeight='600'>
        Diagnosis
      </Typography>

      <Typography>
        During this encounter, I spent approximately
        <Select
          key={content.meetingDurationContent.data.cptCode}
          id='diagnosis-duration-select'
          size='small'
          sx={{
            margin: '0 1em 1em 1em',
            width: '10em',
            fontSize: '15px',
            borderRadius: '40px',
            height: '3em',
          }}
          value={content.meetingDurationContent.data.cptCode}
          inputProps={{
            id: 'diagnosis-duration-select-input',
          }}
          onChange={(v) => {
            const { meetingDurationRange } =
              EvaluationAndManagementByCPTCode[Number(v.target.value)];
            handlers.handleChangeMeetingDuration({
              ...content.meetingDurationContent,
              data: {
                ...content.meetingDurationContent.data,
                cptCode: Number(v.target.value),
                meetingDurationRange,
                meetingDuration: meetingDurationRange,
              },
            });
          }}>
          {Object.entries(EvaluationAndManagementByCPTCode).map(([cptCode, evaluationBy]) => (
            <MenuItem
              key={`${cptCode}-${evaluationBy.meetingDurationRange}`}
              value={Number(cptCode)}>
              {`${evaluationBy.meetingDurationRange}`}
            </MenuItem>
          ))}
        </Select>
        addressing the following:
      </Typography>
      <Typography mb={2} color='#979797'>
        Select up to 4 diagnosis for each field
      </Typography>
      {Object.entries(content.data).map(([premise, diagnoses]) => {
        const diagnosisPremise = premise.replace(/([A-Z])/g, ' $1');

        return (
          <Box className='diagnosis-item'>
            <Typography mb={1}>
              {`${diagnosisPremise[0].toUpperCase() + diagnosisPremise.slice(1)}`}
            </Typography>
            <Select
              size='small'
              fullWidth
              multiple
              value={content.data?.[premise as keyof Omit<DiagnosisOutput, 'sectionId'>]}
              onChange={(v) =>
                handlers.handleChangeDiagnosis({
                  data: v.target.value as string[],
                  premise: premise as keyof Omit<DiagnosisOutput, 'sectionId'>,
                })
              }
              input={<OutlinedInput label='Chip' />}
              renderValue={(selected) => {
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        onDelete={() =>
                          handlers.handleDeleteDiagnosis(
                            premise as keyof Omit<DiagnosisOutput, 'sectionId'>,
                            value
                          )
                        }
                        onMouseDown={(event) => event.stopPropagation()}
                        sx={{
                          backgroundColor: '#1A667A',
                          color: 'white',
                          '> svg.MuiChip-deleteIcon': {
                            color: '#9FC9D5',
                          },
                        }}
                      />
                    ))}
                  </Box>
                );
              }}>
              {DIAGNOSES?.map((v: string) => {
                return (
                  <MenuItem key={v} value={v}>
                    {v}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        );
      })}
    </Box>
  );
};
