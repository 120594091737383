import { NoteDetail } from 'components/elements/notes/notes.type';

export enum MeetingSummaryStatus {
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
  REVIEWED = 'REVIEWED',
}

export enum ScribeOutputType {
  MeetingDuration = 'meeting-duration',
  MeetingContext = 'meeting-context',
  MedicalProblems = 'medical-problems',
  DiagnosisSummary = 'diagnosis-summary',
}

export interface MeetingContextOutput {
  context: string;
}

export interface MeetingDurationOutput {
  meetingDuration: string;
  meetingDurationRange: string;
  cptCode: number;
}

export interface MedicalProblemOutput {
  name: string;
  history?: string;
  dataReview?: string;
  icd10Code: string;
  subjectiveUpdates?: string;
  plan?: string;
}

export interface DiagnosisOutput {
  stableChronicDecease?: string[];
  minorOrSelfLimitedProblems?: string[];
  acuteUncomplicatedIllnessOrInjury?: string[];
  acuteComplicatedInjury?: string[];
  acuteIllnessWithSystemicSymptoms?: string[];
  acuteOrChronicIllnessOrInjury?: string[];
  chronicDiseaseOrTreatmentSideEffect?: string[];
  severeChronicDiseaseOrTreatmentSideEffect?: string[];
  newUndiagnosedProblem?: string[];
}

export type MeetingSummaryResultsOutput =
  | MeetingContextOutput
  | MeetingDurationOutput
  | MedicalProblemOutput
  | DiagnosisOutput;

export interface MeetingSummarySection {
  id: number;
  meetingSummaryId: number;
  meetingTemplateSectionId: number | null;
  resolvedPrompt: string;
  results: MeetingSummaryResultsOutput;
  outputType: ScribeOutputType;
  order: number;
  createdAt: string;
  updatedAt: string;
}

export type MeetingSummary = {
  id: number;
  meetingId: number;
  meetingTemplateId: number;
  status: MeetingSummaryStatus;
  createdAt: string;
  updatedAt: string;
};

export type ProviderNoteData = {
  provider: {
    firstName: string;
    lastName: string;
    specialty: string;
  };
};

export type NoteExcerpt = {
  meetingSummary: MeetingSummary;
  meeting: Meeting;
  meetingType: MeetingType;
};

export enum MeetingTypeStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum MeetingStatus {
  PENDING = 'PENDING',
  TRANSCRIBING = 'TRANSCRIBING',
  COLLECTING_DATA = 'COLLECTING_DATA',
  SUMMARIZING = 'SUMMARIZING',
  SUMMARY_COMPLETED = 'SUMMARY_COMPLETED',
}

export type Meeting = {
  id: number;
  meetingTypeId: number;
  internalProviderId: string;
  internalPatientId: string;
  internalAppointmentId: string;
  rawAppointment: {
    [key: string]: string;
  };
  provider: {
    firstName: string;
    lastName: string;
    specialty: string;
  };
  status: MeetingStatus;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export type MeetingType = {
  id: number;
  customerId: number;
  medicalSpecialtyId: number | null;
  meetingTemplateId: number;
  internalCode: string;
  status: MeetingTypeStatus;
  createdAt: string;
  updatedAt: string;
};
export interface MeetingContextContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.MeetingContext;
  data: MeetingContextOutput;
}

export interface MeetingDurationContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.MeetingDuration;
  data: MeetingDurationOutput;
}

export interface MedicalProblemsContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.MedicalProblems;
  data: MedicalProblemOutput[];
}

export interface DiagnosisSummaryContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.DiagnosisSummary;
  data: DiagnosisOutput;
}

export type SummaryContentType =
  | DiagnosisSummaryContentType
  | MedicalProblemsContentType
  | MeetingDurationContentType
  | MeetingContextContentType;

export type NoteSectionsWithStatus = {
  content?: SummaryContentType[];
  status: MeetingSummaryStatus;
};

export type UpdateSummaryResponse = {
  status: boolean;
  note?: NoteDetail;
};

export type GetAllSummariesPayload = {
  internalProviderId?: string;
  externalProviderId?: string;
  internalPatientId: string;
  specialties?: string;
  page?: number;
  month: string;
  pageSize?: number;
  status?: MeetingSummaryStatus;
};
