import { Add, Close, Delete } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import {
  MEDICAL_PROBLEMS_WITH_ICD10_CODES,
  MedicalProblems,
} from 'components/elements/notes/medical/problems';
import { MedicalProblemsContentType } from 'node-api/scribe/Scribe.types';
import { MouseEvent, useState } from 'react';
import { transformStringWithSpacesToKebab } from 'utils/stringHelpers';
import '../../css/note-edition.css';
import { NoteEditionHandlers } from './render-sections';

type Props = {
  content: MedicalProblemsContentType;
  handlers: NoteEditionHandlers;
};

export type ProblemOption = { label: MedicalProblems; value: string };

const popoverStatesByMedicalProblems = Object.values(MedicalProblems).reduce(
  (pv, cv) => ({ ...pv, [transformStringWithSpacesToKebab(cv)]: false }),
  {} as { [pvqProblem: string]: boolean }
);

export const MedicalProblemSection = ({ content, handlers }: Props) => {
  const [openPopover, setOpenPopover] = useState<{ [pvqProblem: string]: boolean }>(
    popoverStatesByMedicalProblems
  );
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
  const [selectedProblem, setSelectedProblem] = useState<ProblemOption | null>(null);
  const [showAutocompleteError, setShowAutocompleteError] = useState<boolean>(false);

  const handleOpenPopover = (e: MouseEvent, popoverName: string) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setOpenPopover((prev) => ({ ...prev, [popoverName]: !openPopover[popoverName] }));
  };

  const handleClosePopover = (e: MouseEvent, popoverName: string) => {
    e.stopPropagation();
    setAnchorEl(null);
    setOpenPopover((prev) => ({ ...prev, [popoverName]: !openPopover[popoverName] }));
  };

  const medicalProblemOptions = Object.entries(MEDICAL_PROBLEMS_WITH_ICD10_CODES).map(
    ([problem, { icd10Code }]) => ({
      label: problem,
      value: icd10Code,
    })
  );

  return (
    <Box mb={2} display='flex' flexDirection='column'>
      <Typography fontWeight='600' mb={1}>
        Problems
      </Typography>
      <Box>
        {content.data.map((medicalProblem, index) => {
          const { plan, history, dataReview, subjectiveUpdates, name, icd10Code } =
            medicalProblem ?? {};

          if (!content.sectionId || !medicalProblem) {
            return [];
          }

          return (
            <Box>
              <Accordion defaultExpanded className='note-edition-accordion'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography fontWeight='600'>{`${name} - ${icd10Code}`}</Typography>
                  <Popover
                    open={openPopover?.[transformStringWithSpacesToKebab(name)]}
                    key={`${name}-${index}`}
                    anchorEl={anchorEl as Element}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}>
                    <Typography sx={{ p: 2 }}> Are you sure you want to delete it?</Typography>

                    <Box display='flex' alignItems='center' justifyContent='center'>
                      <Button
                        className='medical-problem-section-button cancel-problem-button'
                        startIcon={<Close color='error' />}
                        variant='outlined'
                        onClick={(e) =>
                          handleOpenPopover(e, transformStringWithSpacesToKebab(name))
                        }>
                        Cancel
                      </Button>

                      <Button
                        className='medical-problem-section-button remove-problem-button'
                        key={`remove-button-${index}`}
                        startIcon={<Delete />}
                        variant='contained'
                        onClick={(e) => {
                          handleClosePopover(e, transformStringWithSpacesToKebab(name));

                          handlers.handleDeleteProblem(index);
                        }}>
                        Remove
                      </Button>
                    </Box>
                  </Popover>

                  <Button
                    className='medical-problem-section-button delete-problem-button'
                    key={`delete-problem-button-${index}`}
                    startIcon={<Close color='error' />}
                    onClick={(e) => handleOpenPopover(e, transformStringWithSpacesToKebab(name))}>
                    Delete this problem
                  </Button>
                </AccordionSummary>

                <Box mb={2}>
                  <Typography mb={1}>History:</Typography>
                  <TextField
                    multiline
                    value={history}
                    fullWidth
                    onChange={(v) =>
                      handlers.handleChangeMedicalProblem({
                        ...content,
                        data: {
                          ...medicalProblem,
                          history: v.target.value,
                        },
                        index,
                      })
                    }
                  />
                </Box>

                <Box mb={2}>
                  <Typography mb={1}>Data Review:</Typography>
                  <TextField
                    multiline
                    value={dataReview}
                    fullWidth
                    onChange={(v) =>
                      handlers.handleChangeMedicalProblem({
                        ...content,
                        data: {
                          ...medicalProblem,
                          dataReview: v.target.value,
                        },
                        index,
                      })
                    }
                  />
                </Box>

                <Box mb={2}>
                  <Typography mb={1}>Subjective Updates:</Typography>
                  <TextField
                    multiline
                    value={subjectiveUpdates}
                    fullWidth
                    onChange={(v) =>
                      handlers.handleChangeMedicalProblem({
                        ...content,
                        data: {
                          ...medicalProblem,
                          subjectiveUpdates: v.target.value,
                        },
                        index,
                      })
                    }
                  />
                </Box>

                <Box mb={2}>
                  <Typography mb={1}>Plan:</Typography>
                  <TextField
                    multiline
                    value={plan}
                    fullWidth
                    onChange={(v) =>
                      handlers.handleChangeMedicalProblem({
                        ...content,
                        data: {
                          ...medicalProblem,
                          plan: v.target.value,
                        },
                        index,
                      })
                    }
                  />
                </Box>
              </Accordion>
            </Box>
          );
        })}
      </Box>

      <Box
        display='flex'
        mt={2}
        mb={2}
        pt={5}
        sx={{
          borderTop: '1px solid #DBDBDB',
        }}>
        <Autocomplete
          value={selectedProblem}
          fullWidth
          getOptionDisabled={(option) => !!content.data.find((p) => p.name === option.label)}
          disablePortal
          onChange={(event: unknown, newValue: { label: string; value: string } | null) => {
            setShowAutocompleteError(false);
            setSelectedProblem(newValue as ProblemOption);
          }}
          options={medicalProblemOptions}
          sx={{ width: 'calc(70% - 1em)', marginRight: '1em' }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Search problem'
              sx={{
                height: '100%',
              }}
              error={showAutocompleteError}
              helperText={showAutocompleteError ? 'Please select a problem to add it' : ''}
            />
          )}
        />
        <Button
          startIcon={<Add />}
          variant='outlined'
          className='medical-problem-section-button add-other-problem'
          onClick={() => {
            if (selectedProblem) {
              setShowAutocompleteError(false);
              setSelectedProblem(null);
              handlers.handleAddProblem(selectedProblem);
            } else {
              setShowAutocompleteError(true);
            }
          }}
          size='medium'>
          Add other problem
        </Button>
      </Box>
    </Box>
  );
};
