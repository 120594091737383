import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';
import { Action, State, initialState, reducer } from '../reducers/program';

const ProgramContext = createContext<{ state: State; dispatch: Dispatch<Action> }>({
  state: initialState,
  dispatch: () => null,
});

const ProgramProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <ProgramContext.Provider value={{ state, dispatch }}>{children}</ProgramContext.Provider>;
};

export { ProgramContext, ProgramProvider };
