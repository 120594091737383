import { ICard, ICategory } from 'interfaces/cards';
import { Reducer } from 'react';
import { Action as DefaultAction } from 'state/types';

export enum ActionTypes {
  TOGGLE_MODAL = 'TOGGLE_MODAL',
  SHOW = 'SHOW',
}

export type State = {
  id?: number;
  modal: boolean;
  message?: string;
  yesFunc?: () => void;
  noFunc?: () => void;
};

type Payload = {
  [ActionTypes.TOGGLE_MODAL]: never;
  [ActionTypes.SHOW]: {
    id?: number;
    cards?: ICard[];
    category?: ICategory;
    subCategoryTitle?: string;
    forced?: boolean;
    yesText?: string;
    noText?: string;
    message: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onYes: (onClose: () => void, id: string, ...params: any[]) => void;
    onNo: (onClose: () => void) => void;
    callBack?: () => void;
    role_id?: number;
  };
};

export type Action = DefaultAction<Payload>;

export const initialState: State = {
  modal: false,
  id: undefined,
  message: undefined,
  yesFunc: undefined,
  noFunc: undefined,
};

export const reducer: Reducer<State, Action> = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_MODAL: {
      return {
        ...state,
        modal: !state.modal,
      };
    }
    case ActionTypes.SHOW: {
      return {
        ...action.payload,
        modal: true,
      };
    }
    default:
      throw new Error('Unexpected action');
  }
};
