import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import api from 'api';
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { UserContext } from 'state/contexts/user';
import { debug } from 'utils/helpers';
import { Item, Record, SearchWrapper } from './style';

const useStyles = makeStyles(() => ({
  inputRoot: {
    font: "17px/19px 'Proxima Nova Rg'",

    '&&[class*="MuiOutlinedInput-root"] $input': {
      padding: '1px 4px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
    '& .MuiAutocomplete-popupIndicatorOpen': {
      transform: 'rotate(0deg)',
    },
  },
  popper: {
    top: '-5px !important;',
  },
}));

const Search = ({ history }) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const { state: userState } = React.useContext(UserContext);

  const classes = useStyles();

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    const source = axios.CancelToken.source();

    const getPatients = async () => {
      try {
        const original_user =
          (localStorage.getItem('Origu') && localStorage.getItem('Origu').split('|')[1]) || null;
        const { data } = await api().get('/patients/search_list?auth_token=AUTH_TOKEN', {
          cancelToken: source.token,
          params: {
            provider_uuid: userState.uuid,
            ...(userState.isOriginalUser === false && original_user !== null && { original_user }),
          },
        });

        if (active) {
          setOptions(
            data.patients.map((key) => ({ ...key, name: `${key.first_name} ${key.last_name}` }))
          );
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          debug(err);
        }
      }
    };

    getPatients();

    return () => {
      active = false;
    };
  }, [loading, userState.isOriginalUser, userState.uuid]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <>
      <SearchWrapper>
        <Autocomplete
          id='search-patients'
          style={{ width: 260, height: 38 }}
          autoHighlight
          classes={classes}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onChange={(event, p) => {
            if (p?.uuid) {
              history.push(`/patient/${p.uuid}`);
            }
          }}
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name}
          options={options}
          loading={loading}
          noOptionsText='No patients found'
          popupIcon={<SearchIcon />}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder='Search Patients'
              variant='outlined'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color='inherit' size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(p) => {
            return (
              <Record key={`patient-${p.uuid}`}>
                <Item>
                  {p.first_name} {p.last_name}
                </Item>
              </Record>
            );
          }}
        />
      </SearchWrapper>
    </>
  );
};

Search.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default Search;
