import { GetAllSummariesByDateResponse, NoteDetail } from 'components/elements/notes/notes.type';
import { NODE_API } from 'config';
import { SharedEnaraNodeClient } from 'node-api/common/SharedEnaraNodeClient';
import {
  GetAllSummariesPayload,
  NoteSectionsWithStatus,
  UpdateSummaryResponse,
} from './Scribe.types';

export default class ScribeClient extends SharedEnaraNodeClient {
  constructor() {
    super(NODE_API.transcribe, false);
  }

  async getSummaries(params: GetAllSummariesPayload): Promise<GetAllSummariesByDateResponse> {
    const { data } = await this.axiosClient.get<GetAllSummariesByDateResponse>(
      `/meetings/summaries`,
      {
        params,
      }
    );
    return data;
  }

  async getSummary(summaryId: number): Promise<NoteDetail | null> {
    const { data } = await this.axiosClient.get<NoteDetail>(`/meetings/summaries/${summaryId}`);

    return data;
  }

  async updateSummary(
    summaryId: number,
    payload: NoteSectionsWithStatus
  ): Promise<UpdateSummaryResponse> {
    const response = await this.axiosClient.put<UpdateSummaryResponse>(
      `/meetings/summaries/${summaryId}`,
      payload
    );

    return response.data;
  }
}
