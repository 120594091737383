import { IconButton } from 'components/elements';
import ConfirmationDialog from 'components/elements/modal/confirmation';
import Icon from 'components/icon';
import { ACUITY, ROR } from 'config';
import Can from 'config/casl/can';
import { EFeatures } from 'lib/feature/features.types';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import MixpanelService from 'services/mixpanel-service';
import { ConfirmationContext } from 'state/contexts/confirmation';
import { FeaturesContext } from 'state/contexts/features';
import { UserContext } from 'state/contexts/user';
import { ActionTypes } from 'state/reducers/confirmation';
import logoSrc from '../navbar/assets/logo.png';
import Search from './search';
import { Avatar, Logo, Name, Nav, UserSection, Wrapper } from './style';
import UserDropdown from './user-dropdown';

const Header = ({ history, location }) => {
  const [showMenu, toggleMenu] = useState(false);

  const { state: userState } = useContext(UserContext);
  const { state: featuresState } = useContext(FeaturesContext);
  const { state: confirmationState, dispatch: confirmationDispatch } =
    useContext(ConfirmationContext);

  return (
    <>
      <Wrapper>
        <div style={{ display: 'inline-flex' }}>
          <Nav>
            <Can I='enter' on='Dashboard'>
              {() => (
                <NavLink
                  exact
                  to='/'
                  onClick={() =>
                    MixpanelService.track('Changed section', {
                      Section: 'Dashboard',
                    })
                  }
                  activeClassName='selected'>
                  <Logo src={logoSrc} alt='Enara logo' />
                </NavLink>
              )}
            </Can>
          </Nav>

          <Search history={history} />
        </div>

        <UserSection>
          {`${location?.search}`.includes('new_content') ? (
            <IconButton onClick={() => history.push('/content')}>[AssignCards]</IconButton>
          ) : null}

          {featuresState?.[EFeatures.ProviderShowAcuityButton] && (
            <IconButton
              onClick={() => {
                MixpanelService.track('Clicked Acuity Calendar');
                window.open(`${ACUITY.url}`, '_blank');
              }}>
              <div data-tip='Acuity Scheduling' data-for='acuity-button' key='acuity-button'>
                <ReactTooltip id='acuity-button' place='bottom' type='dark' effect='solid' />
                <Icon icon='Calendar' color='gray' size={25} />
              </div>
            </IconButton>
          )}

          <IconButton onClick={() => window.open(`${ROR.ror_new_card_url}`, '_blank')}>
            <Icon icon='NewCard' color='gray' size={25} />
          </IconButton>

          <IconButton onClick={() => window.open(`${ROR.ror_categorize_card_url}`, '_blank')}>
            <Icon icon='CategorizeCard' color='white' size={25} />
          </IconButton>

          <Name>
            {userState.avatar ? (
              <Avatar
                src={userState.avatar}
                alt={`${userState.first_name} ${userState.last_name}`}
              />
            ) : (
              <Icon icon='userBubble' color='turquoise' size={30} />
            )}

            {`${userState.first_name} ${userState.last_name}`}
          </Name>

          <div style={{ position: 'relative' }}>
            <IconButton onClick={() => toggleMenu((prev) => !prev)}>
              <Icon icon='arrow' color='gray' size={16} />
            </IconButton>
            {showMenu && <UserDropdown toggle={toggleMenu} history={history} user={userState} />}
          </div>
        </UserSection>
        {confirmationState.modal && (
          <ConfirmationDialog
            onClose={() => confirmationDispatch({ type: ActionTypes.TOGGLE_MODAL })}
          />
        )}
      </Wrapper>
    </>
  );
};

Header.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};

export default Header;
