import { createContext, Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { Action, initialState, reducer, State } from '../reducers/providers';

const ProvidersContext = createContext<{ state: State; dispatch: Dispatch<Action> }>({
  state: initialState,
  dispatch: () => null,
});

const ProvidersProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ProvidersContext.Provider value={{ state, dispatch }}>{children}</ProvidersContext.Provider>
  );
};

export { ProvidersContext, ProvidersProvider };
