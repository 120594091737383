import { Typography } from '@mui/material';
import axios from 'axios';
import { Accordion, AutoCompleteFilter, Badge, Loading } from 'components/elements';
import { Constants } from 'components/main/calendar/utils';
import Toast from 'components/toast';
import { APP_NAME } from 'config';
import { IDropdownOption } from 'interfaces/common';
import { EFeatures } from 'lib/feature/features.types';
import { capitalize } from 'lodash';
import moment from 'moment-timezone';
import MembersClient from 'node-api/MembersClient';
import { IExercise, MemberExercise } from 'node-api/member.types';
import { useContext, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import MixpanelService, {
  ChangeElementTypes,
  ClickElementTypes,
  EventHandlerNames,
  TrackableElement,
} from 'services/mixpanel-service';
import { FeaturesContext } from 'state/contexts/features';
import { PatientContext } from 'state/contexts/patient';
import { getSearchDateRange, getTimeWithTimezoneAsString } from 'utils/dateHelpers';
import { debug } from 'utils/helpers';
import { UTC_TIMEZONE } from 'utils/timezones';
import {
  CalendarWrapper,
  CircleExercise,
  Content,
  DayExercises,
  ExercisesWrapper,
  Legend,
  LegendExercise,
  MonthsWithNewLogs,
  TextExercise,
} from './style';

const Exercises = ({
  patientId,
  notificationsData,
  reloadTabs,
}: {
  patientId: number;
  notificationsData: IExercise[];
  reloadTabs: () => void;
}) => {
  const { state: memberState } = useContext(PatientContext) as unknown as {
    state: { slide: boolean; data: { id: number; timezone: string }[] };
    dispatch: unknown;
  };
  const { state: featuresState } = useContext(FeaturesContext);
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
  const [monthData, setMonthData] = useState<MemberExercise[]>([]);
  const [dayData, setDayData] = useState<MemberExercise[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [highlightedDates, setHighlightedDates] = useState<Date[]>([]);
  const [unreviewedDates, setUnreviewedDates] = useState<Date[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [unreviewedMonths, setUnreviewedMonths] = useState<IDropdownOption[]>([]);
  const [unreviewedExercises, setUnreviewedExercises] = useState<IExercise[]>(notificationsData);

  const memberTimezone = useMemo(() => {
    const member = memberState.data.find((memb) => memb.id === patientId);
    return member?.timezone || UTC_TIMEZONE;
  }, [patientId]);

  const getYearAndMonthName = (date: string) => {
    return moment(date).format(Constants.MONTH_NAME_YEAR_FORMAT);
  };

  const parseNotificationsData = (data: IExercise[]): IDropdownOption[] => {
    const months = new Set<string>();
    data.forEach((exercise) => {
      const month = moment
        .utc(exercise.exerciseDate)
        .tz(memberTimezone)
        .format(Constants.FIRST_DAY_FORMAT);
      months.add(month);
    });

    const monthsList: string[] = Array.from(months).sort((a, b) => (a > b ? -1 : 1));
    return monthsList.map((month) => {
      return { id: month, name: getYearAndMonthName(month) };
    });
  };

  const searchDateRange = useMemo(() => {
    return getSearchDateRange(selectedMonth);
  }, [selectedMonth]);

  const getDayExercises = (date: Date): MemberExercise[] => {
    const selected = moment(date).format(Constants.DATE_FORMAT);
    const result = monthData
      .filter((exercise) => exercise.exerciseDate.format().startsWith(selected))
      .sort((a, b) => (a.exerciseDate > b.exerciseDate ? 1 : -1));
    return result;
  };

  const highlightUnreviewedDates = (data: IExercise[]) => {
    const days: Date[] = [];
    data.forEach((exercise: IExercise) => {
      days.push(
        new Date(
          moment.utc(exercise.exerciseDate).tz(memberTimezone).format(Constants.DATETIME_FORMAT)
        )
      );
    });
    setUnreviewedDates(days);
  };

  const highlightDates = (data: MemberExercise[]) => {
    setHighlightedDates(
      data.map((exercise) => new Date(exercise.exerciseDate.format(Constants.DATETIME_FORMAT)))
    );
  };

  const handleDayChange = async (date: Date) => {
    MixpanelService.trackClick({
      name: 'Date in Exercise calendar',
      type: ClickElementTypes.BUTTON,
    });
    setSelectedDate(date);
    const dayExercises = getDayExercises(date);
    setDayData(dayExercises);
    const selected = moment(date).format(Constants.DATE_FORMAT);

    const exerciseIDsToReview: number[] = [];
    const unreviewedExercisesUpdated: IExercise[] = [];

    unreviewedExercises.forEach((exercise: IExercise) => {
      if (
        moment.utc(exercise.exerciseDate).tz(memberTimezone).format(Constants.DATE_FORMAT) ===
        selected
      ) {
        exerciseIDsToReview.push(exercise.id);
      } else {
        unreviewedExercisesUpdated.push(exercise);
      }
    });
    // We have some IDs to review
    if (exerciseIDsToReview.length) {
      try {
        await new MembersClient().reviewExercise({
          memberId: patientId,
          source: APP_NAME || '',
          memberActivityIds: exerciseIDsToReview,
        });
        setUnreviewedExercises(unreviewedExercisesUpdated);
        // Removing Badge from the Exercise tab and the profile tab if needed
        if (unreviewedExercisesUpdated.length === 0) {
          reloadTabs();
        }
      } catch (e) {
        console.error(`Couldn't mark the exercise IDs <${exerciseIDsToReview}> as reviewed!`);
      }
    }
  };

  const handleMonthChange = (date: Date) => {
    setSelectedDate(date);
    setSelectedMonth(date);
    setDayData([]);
  };

  const getSectionLabel = (section: MemberExercise) => {
    return `${capitalize(section.memberExercise[0]?.value || '')}, at ${getTimeWithTimezoneAsString(
      section.exerciseDate
    )}`;
  };

  useEffect(() => {
    if (featuresState?.[EFeatures.ProviderShowExerciseNotifications]) {
      setUnreviewedMonths(parseNotificationsData(unreviewedExercises));
      highlightUnreviewedDates(unreviewedExercises);
    }
  }, [unreviewedExercises]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getData = async () => {
      const message = `Couldn't load exercises!`;
      try {
        const exercises = await new MembersClient().exercises(
          { memberId: patientId, startDate: searchDateRange.start, endDate: searchDateRange.end },
          source.token
        );

        if (exercises.success !== true) {
          throw new Error(message);
        }

        const exercisesParsed = exercises.data.map((exercise) => ({
          ...exercise,
          exerciseDate: moment.utc(exercise.exerciseDate).tz(memberTimezone),
        }));

        highlightDates(exercisesParsed);

        setMonthData(exercisesParsed);
      } catch (err) {
        if (!axios.isCancel(err)) {
          Toast.show('error', message);
          debug(err as string);
        }
      }
      setLoading(false);
    };

    getData();

    return () => {
      source.cancel();
    };
  }, [selectedMonth]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Content>
            <ExercisesWrapper>
              <DayExercises>
                <Accordion
                  name='Exercise'
                  list={dayData}
                  getSectionLabel={getSectionLabel}
                  mixpanel={{ name: 'Exercise', source: 'Exercises Tab' }}
                />
              </DayExercises>
              <CalendarWrapper>
                {unreviewedMonths.length > 0 && (
                  <MonthsWithNewLogs>
                    <Typography variant='body1'>Months with new logs</Typography>
                    <TrackableElement
                      name='Months with new logs'
                      type={ChangeElementTypes.DROPDOWN}
                      eventHandlerName={EventHandlerNames.ONCHANGE}>
                      <AutoCompleteFilter
                        id='months-with-new-logs'
                        name='monthsWithNewLogs'
                        label=''
                        placeholder=''
                        width='220px'
                        getData={() => unreviewedMonths}
                        onChange={(event, option) => {
                          if (option?.id) handleMonthChange(new Date(option.id));
                        }}
                        renderOption={(opt) => (
                          <Badge key={`badge-${opt.name}`} top={5} right={-8} size={7} visible>
                            <div>{opt.name}</div>
                          </Badge>
                        )}
                      />
                    </TrackableElement>
                  </MonthsWithNewLogs>
                )}
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDayChange}
                  onMonthChange={handleMonthChange}
                  highlightDates={[
                    {
                      'react-datepicker__day--highlighted-green': highlightedDates,
                    },
                    {
                      'react-datepicker__day--highlighted-red': unreviewedDates,
                    },
                  ]}
                  showMonthDropdown
                  dropdownMode='select'
                  inline
                />
              </CalendarWrapper>
            </ExercisesWrapper>

            <Legend>
              <LegendExercise>
                <CircleExercise />
                <TextExercise>Exercise Logged</TextExercise>
              </LegendExercise>
            </Legend>
          </Content>
        </>
      )}
    </>
  );
};

export default Exercises;
