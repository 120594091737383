import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';
import { initialState, reducer } from '../reducers/patientTags';

const PatientTagsContext = createContext(initialState);

const PatientTagsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <PatientTagsContext.Provider value={{ state, dispatch }}>
      {children}
    </PatientTagsContext.Provider>
  );
};

PatientTagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PatientTagsContext, PatientTagsProvider };
