import { Box, Button, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { getFormattedDateFromISOString } from 'utils/dateHelpers';
import {
  MeetingSummaryStatus,
  NoteExcerpt,
  ProviderNoteData,
} from '../../../../node-api/scribe/Scribe.types';
import { NotePreview } from '../note/NotePreview';
import './css/list-note.css';

export type NoteListItemProps = {
  noteExcerpt: NoteExcerpt & ProviderNoteData;
};

export const NoteListItem = ({ noteExcerpt }: NoteListItemProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <Box
      className='note-section-list-row note-section-element-row'
      display='flex'
      gap={2}
      justifyContent='space-between'
      alignItems='center'>
      <Box display='flex' alignItems='center' gap={1} minWidth='50%'>
        <Box className='note-list-row-icon' />
        <Typography
          sx={{
            color: '#404040',
            opacity: '60%',
          }}>
          {noteExcerpt.meetingType.internalCode}
        </Typography>
        <Typography
          sx={{
            color: '#404040',
            opacity: '60%',
          }}>{` ${getFormattedDateFromISOString(
          noteExcerpt.meeting.createdAt,
          'h:mma'
        )}`}</Typography>
        <Typography>{`${noteExcerpt.provider?.firstName || 'unknown'} ${
          noteExcerpt.provider?.lastName || 'unknown'
        }, `}</Typography>
        <Typography>{noteExcerpt.provider?.specialty}</Typography>
      </Box>

      <Modal open={openModal} className='note-modal'>
        <NotePreview noteExcerpt={noteExcerpt} onClosePreview={() => setOpenModal(!openModal)} />
      </Modal>

      {noteExcerpt.meetingSummary.status === MeetingSummaryStatus.READY_FOR_REVIEW ? (
        <Button
          variant='text'
          onClick={() => setOpenModal(true)}
          sx={{
            fontSize: '1em',
            minWidth: '10em',
            width: 'auto',
            fontWeight: 600,
            color: '#1A667A',
          }}
          fullWidth>
          Review Notes
        </Button>
      ) : (
        <Box display='flex' minWidth='20%' width='auto' sx={{ textWrap: 'nowrap' }}>
          <Typography fontSize='1em' margin='auto' color='#85868C'>
            Marked as reviewed
          </Typography>
          <Button
            onClick={() => setOpenModal(true)}
            sx={{
              minWidth: '8em',
              fontWeight: 500,
              textTransform: 'none',
              fontSize: '15px',
              color: '#1A667A',
            }}
            fullWidth>
            View notes
          </Button>
        </Box>
      )}
    </Box>
  );
};
