import { useContext } from 'react';
import { EventContext } from 'state/contexts/event';
import { ProgramContext } from 'state/contexts/program';
import { ProvidersContext } from 'state/contexts/providers';
import { StickyContext } from 'state/contexts/sticky';
import { TagContext } from 'state/contexts/tag';
import { TaskContext } from 'state/contexts/task';
import { ActionTypes } from 'state/reducers/providers';
import { ActionTypes as TaskActionTypes } from 'state/reducers/task';
import AddNewEventNote from './profile/add-new-event-note';
import AddNewSticky from './profile/add-new-sticky-note';
import AddNewTask from './profile/add-new-task';
import AddTag from './profile/add-tag';
import ManagePrograms from './profile/manage-programs/ManagePrograms';
import ManageProvider from './profile/manage-provider';

const MainProfileActions = () => {
  const { state: stickyState, dispatch: stickyDispatch } = useContext(StickyContext);
  const { state: eventState, dispatch: eventDispatch } = useContext(EventContext);
  const { state: taskState, dispatch: taskDispatch } = useContext(TaskContext);
  const { state: programState, dispatch: programDispatch } = useContext(ProgramContext);
  const { state: tagState, dispatch: tagDispatch } = useContext(TagContext);
  const { state: ProvidersState, dispatch: providersDispatch } = useContext(ProvidersContext);

  return (
    <>
      {taskState.modal && (
        <AddNewTask onClose={() => taskDispatch({ type: TaskActionTypes.ToggleModal })} />
      )}
      {stickyState.modal && (
        <AddNewSticky onClose={() => stickyDispatch({ type: 'TOGGLE_MODAL' })} />
      )}
      {eventState.modal && (
        <AddNewEventNote onClose={() => eventDispatch({ type: 'TOGGLE_MODAL' })} />
      )}
      {programState.modal && (
        <ManagePrograms onClose={() => programDispatch({ type: 'TOGGLE_MODAL' })} />
      )}
      {tagState.modal && <AddTag onClose={() => tagDispatch({ type: 'TOGGLE_MODAL' })} />}
      {ProvidersState.modal && (
        <ManageProvider onClose={() => providersDispatch({ type: ActionTypes.TOGGLE_MODAL })} />
      )}
    </>
  );
};

export default MainProfileActions;
