import { createContext, Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { Actions, initialState, InitialStateType, reducer } from '../reducers/reviewLists4';

const ReviewLists4Context = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<Actions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const ReviewLists4Provider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ReviewLists4Context.Provider value={{ state, dispatch }}>
      {children}
    </ReviewLists4Context.Provider>
  );
};
export { ReviewLists4Context, ReviewLists4Provider };
