import api from 'api';
import { Button, Datepicker, Modal, Select, TextArea } from 'components/elements';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import MixpanelService, {
  ClickElementTypes,
  DATE_FORMAT,
  EventHandlerNames,
  TrackableElement,
} from 'services/mixpanel-service';
import { PatientContext } from 'state/contexts/patient';
import { ProviderContext } from 'state/contexts/provider';
import { TaskContext } from 'state/contexts/task';
import { UserContext } from 'state/contexts/user';
import { ActionTypes } from 'state/reducers/task';
import { debug, escapeHTMLData } from 'utils/helpers';
import * as yup from 'yup';
import AddNewTasksList from './add-new-tasks-list';
import { Actions, AddBulkTasksList, Display, Header } from './style';

const schema = yup.object().shape({
  assigned_to: yup.string().required('A provider is required'),
  start_date: yup
    .date()
    .typeError('Start date must be a valid date')
    .required('A star date is required'),
  due_date: yup
    .date()
    .min(yup.ref('start_date'), 'Start date must be earlier than Due date')
    .optional()
    .typeError('Due date must be a valid date'),
  description: yup.string().required('A description is required'),
});

const AddNewTask = ({ onClose }) => {
  const { dispatch: taskDispatch } = useContext(TaskContext);
  const { state: patientState } = useContext(PatientContext);
  const { state: providerState } = useContext(ProviderContext);
  const { state: userState } = useContext(UserContext);

  const [patient] = useState(patientState.data.find((p) => p.current));
  const [providers] = useState(providerState.data);
  const [open, setOpen] = useState(true);
  const [selected, setSelected] = useState('');

  const { handleSubmit, register, errors, formState, setValue, getValues, watch } = useForm({
    validationSchema: schema,
  });

  watch(['start_date']);
  register({ name: 'start_date' });
  watch(['due_date']);
  register({ name: 'due_date' });

  // add new task list
  const handleAddNewTaskList = () => {
    setSelected('add_new_task_list');
    setOpen(true);
  };

  const submit = async (payload) => {
    const { assigned_to, start_date, due_date, description } = payload;

    try {
      const origu = localStorage.getItem('Origu') && localStorage.getItem('Origu').split('|');
      const original_user_uuid = (origu && origu[0]) || null;
      const original_user = (origu && origu[1]) || null;
      const newDescription = escapeHTMLData(description);
      const {
        data: { data },
      } = await api().post('/tasks', {
        auth_token: 'AUTH_TOKEN',
        patient_id: patient.id,
        description: newDescription,
        assigned_to,
        start_date,
        due_date,
        ...(userState.isOriginalUser === false &&
          original_user !== null && { original_user_uuid, original_user }),
      });

      MixpanelService.trackClick({
        name: 'Add New Task',
        type: ClickElementTypes.BUTTON,
        Source: 'Add New Task Modal',
        inputData: {
          assignedTo: assigned_to,
          startDate: moment(start_date).format(DATE_FORMAT),
          dueDate: moment(due_date).format(DATE_FORMAT),
          description: newDescription,
        },
      });

      taskDispatch({
        type: ActionTypes.Add,
        payload: {
          id: patient.id,
          data,
        },
      });

      return onClose();
    } catch (err) {
      debug(err);
    }
  };

  const getLabel = (provider) => {
    const name = `${provider.first_name} ${provider.last_name}`;
    const role = provider.roles && provider.roles[0] ? ` - ${provider.roles[0].description}` : '';
    return name + role;
  };

  useEffect(() => {
    setValue('start_date', new Date(), true);
  }, []);

  return (
    <>
      {selected === 'add_new_task_list' && open && (
        <AddNewTasksList open={open} setOpen={setOpen} onClose1={onClose} />
      )}
      <Modal onClose={onClose}>
        <AddBulkTasksList>
          <TrackableElement
            name='Create Bulk Task'
            type={ClickElementTypes.BUTTON}
            eventHandlerName={EventHandlerNames.ONCLICK}>
            <Button variant='link' onClick={handleAddNewTaskList}>
              Create Bulk Task
            </Button>
          </TrackableElement>
        </AddBulkTasksList>
        <form onSubmit={handleSubmit(submit)}>
          <Header>
            <h2>Add New Task</h2>
            <p>Assign a task to a provider</p>
          </Header>

          <Select
            errors={errors.assigned_to}
            label='Assign new task to'
            name='assigned_to'
            register={register}
            options={providers.map((provider) => ({
              label: getLabel(provider),
              value: provider.id,
            }))}
          />

          <Display>
            <Datepicker
              errors={errors.start_date}
              label='Task Start Date'
              name='start_date'
              date={getValues().start_date}
              setValue={setValue}
              dateFormat='yyyy-MM-dd'
            />
            <Datepicker
              errors={errors.due_date}
              label='Task Due Date'
              name='due_date'
              date={getValues().due_date}
              setValue={setValue}
              dateFormat='yyyy-MM-dd'
            />
          </Display>

          <TextArea
            errors={errors.description}
            label='Task Description'
            name='description'
            register={register}
            type='text'
          />

          <Actions>
            <Button variant='link' onClick={onClose}>
              Cancel action
            </Button>
            <Button type='submit' submitting={formState.isSubmitting}>
              Add New Task
            </Button>
          </Actions>
        </form>
      </Modal>
    </>
  );
};

AddNewTask.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddNewTask;
