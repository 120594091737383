import moment, { Moment } from 'moment';

export const ONLY_DATE_FORMAT = 'YYYY-MM-DD';

/**
 *
 * @param {Date} date
 * @returns {{ start: string, end: string}} - start is previous month, format: 'YYYY-MM-22' and end is next month, format 'YYYY-MM-07'
 */
export const getSearchDateRange = (date: Date) => {
  const end = moment(date).add(1, 'month').format('YYYY-MM-07');
  const start = moment(date).subtract(1, 'month').format('YYYY-MM-22');
  const obj = { start, end };
  return obj;
};

export const getTimeWithTimezoneAsString = (date: Moment) => {
  /**
   * Here we're using Moment instead of Date, since Date objects don't handle timezones
   */
  return date.format('LT (z)');
};

export const fillSeriesWithZerosForMissingDates = (
  initialSeries: { date: string; value: number }[],
  format: string
) => {
  const series: { date: string; value: number }[] = [];
  initialSeries.forEach((item, index) => {
    if (index !== 0) {
      let nextDayDate = moment(series[series.length - 1].date, format).add(1, 'days');
      while (nextDayDate < moment(item.date, format)) {
        // it'll fill missing entries with 0s until it finds the next date in initialSeries
        series.push({ date: nextDayDate.format(format), value: 0 });
        nextDayDate = moment(nextDayDate, format).add(1, 'days');
      }
    }
    series.push(item);
  });
  return series;
};

export const getDateTimeFromString = (date: string) => moment(date).format('MM/DD/YY h:mm:ss a');

export const getDateWithUTCFormatFromString = (date: string) => moment(date).tz('UTC');

export const getDateFromNMonthsAgo = (totalMonths: number) =>
  moment().subtract(totalMonths, 'M').format('YYYY-MM-DD');

export const getDateFromNMonthsLater = (totalMonths: number) =>
  moment().add(totalMonths, 'M').format('YYYY-MM-DD');

export const getDateFromNDaysAgo = (days: number, format = 'YYYY-MM-DD') =>
  moment().subtract(days, 'd').format(format);

export const getFormattedDateFromISOString = (date: string, format: string) =>
  moment(date).format(format);

export const getDateFromMillis = (dateInMs: number) => moment(dateInMs).toISOString();

export const getYYYYMMfromISO = (date: string) => (date.split('T')?.[0] ?? '').slice(0, -3);
