import { Button } from 'components/elements';
import { EFeatures } from 'lib/feature/features.types';
import { User } from 'node-api/schedule/Appointments.types';
import { FC, HTMLAttributes, useContext, useMemo } from 'react';
import { ClickElementTypes, EventHandlerNames, TrackableElement } from 'services/mixpanel-service';
import { FeaturesContext } from 'state/contexts/features';
import { PatientContext } from 'state/contexts/patient';
import { ActionTypes as MemberActionTypes } from 'state/reducers/patient';

const SEND_SCHEDULING_LINK_BUTTON_TEXT = 'Send Link';

export const SendSelfScheduleLinkButton: FC<
  {
    appointmentTypeId?: number;
    appointmentScheduleLink?: string;
    memberId: number;
    provider?: User;
  } & HTMLAttributes<HTMLButtonElement>
> = ({ appointmentTypeId, appointmentScheduleLink, memberId, provider, style }) => {
  const { dispatch: patientDispatch } = useContext(PatientContext);
  const { state: featuresState } = useContext(FeaturesContext);

  const canShowSchedulingLink = useMemo(
    () => !!(appointmentTypeId && provider?.calendarId),
    [appointmentTypeId, provider?.calendarId]
  );

  const handleSendSchedulingLink = () => {
    patientDispatch({
      type: MemberActionTypes.SET_MESSAGE,
      payload: {
        memberId,
        message: appointmentScheduleLink ?? '',
      },
    });
  };

  return (
    (featuresState?.[EFeatures.ProviderShowSchedulingLinkButton] && canShowSchedulingLink && (
      <TrackableElement
        style={style}
        name={SEND_SCHEDULING_LINK_BUTTON_TEXT}
        type={ClickElementTypes.BUTTON}
        eventHandlerName={EventHandlerNames.ONCLICK}>
        <Button style={style} onClick={handleSendSchedulingLink}>
          {SEND_SCHEDULING_LINK_BUTTON_TEXT}
        </Button>
      </TrackableElement>
    )) ||
    null
  );
};
