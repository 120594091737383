import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import api from 'api';
import { Datepicker } from 'components/elements';
import Toast from 'components/toast';
import moment from 'moment';
import { useContext, useState } from 'react';
import MixpanelService, { ClickElementTypes, DATE_FORMAT } from 'services/mixpanel-service';
import { ChartContext } from 'state/contexts/chart';
import { ActionTypes } from 'state/reducers/chart';
import { Program } from 'state/reducers/program';
import ProgramHistory from './ProgramHistory';

type NewProgramInput = {
  id: number | null;
  startDate: Date;
};

const AddNewProgram = ({
  onClose,
  patient,
  programs,
}: {
  onClose: () => void;
  patient: { id: number };
  programs: Program[];
}) => {
  const { dispatch: chartDispatch } = useContext(ChartContext);

  const [newProgram, setNewProgram] = useState<NewProgramInput>({
    id: null,
    startDate: new Date(),
  });

  const handleSubmit = async () => {
    const { id: programId, startDate } = newProgram;

    if (!programId || !startDate) {
      Toast.show('error', 'Complete required fields');
      return;
    }
    const program = programs.find(({ id }) => id === programId);
    if (!program) {
      return;
    }
    const startDateToSend = moment(startDate).format(DATE_FORMAT);

    let data;

    try {
      const response = await api().post('/patients/add_program', {
        auth_token: 'AUTH_TOKEN',
        patient_id: patient.id,
        program_id: programId,
        program_start_date: startDateToSend,
      });
      data = response.data;
    } catch (err) {
      console.error(err);
      Toast.show('error', 'There was an issue adding the new program');
      return;
    }

    MixpanelService.trackClick({
      name: 'Add Program',
      type: ClickElementTypes.BUTTON,
      Source: 'Manage programs modal',
      inputData: {
        program: program.text,
        startDate: startDateToSend,
      },
    });

    const programAssignmentId = data.data?.id;
    chartDispatch({
      type: ActionTypes.ADD,
      payload: {
        type: 'programs',
        values: {
          start_date: `${moment(startDate).format('YYYYMMDDTHHmmss')}+0000`,
          value: program.text,
          user_program_id: programAssignmentId,
          text: program.text,
          display_value: program.display_value,
        },
      },
    });

    onClose();
  };

  return (
    <Box className='manage-programs-tab-content'>
      <ProgramHistory />
      <Divider orientation='vertical' variant='middle' flexItem />
      <Box className='manage-programs-section'>
        <Box className='title'>
          <Typography component='h5' variant='h5' marginBottom={3}>
            Start New Program
          </Typography>
        </Box>
        <Box className='manage-programs-form-content'>
          <FormControl fullWidth>
            <InputLabel>Program</InputLabel>
            <Select
              label='Program'
              onChange={(event: SelectChangeEvent) => {
                setNewProgram((program) => ({ ...program, id: Number(event.target.value) }));
              }}>
              {programs.map(({ display_value: displayValue, id }) => (
                <MenuItem value={id}>{displayValue}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Datepicker
            errors=''
            label='Program Start Date'
            name='programStartDate'
            date={newProgram.startDate}
            setValue={(fieldName: string, value: Date) => {
              setNewProgram((program) => ({
                ...program,
                startDate: value,
              }));
            }}
            dateFormat='yyyy-MM-dd'
          />
          <Box className='actions'>
            <Button className='primary' variant='contained' onClick={handleSubmit}>
              Add Program
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddNewProgram;
