export enum MedicalProblems {
  Obesity = 'Obesity',
  MorbidObesity = 'Morbid obesity',
  Overweight = 'Overweight',
  WeightGain = 'Weight gain',
  OtherObesity = 'Other obesity',
  ObesityUnspecified = 'Obesity, unspecified',
  HeartAttackOrHeartDisease = 'Heart attack or heart disease',
  HeartPalpitationsOrAbnormalHeartRhythm = 'Heart palpitations or abnormal heart rhythm',
  Stroke = 'Stroke',
  NeurologicalDisorders = 'Neurological (brain) disorders',
  Migraines = 'Migraines',
  HighBloodPressure = 'High blood pressure',
  HighCholesterolOrBorderlineCholesterol = 'High cholesterol or borderline cholesterol',
  Prediabetes = 'Prediabetes',
  Type1Diabetes = 'Type 1 diabetes',
  Type2Diabetes = 'Type 2 diabetes',
  LungDisease = 'Lung disease',
  LiverDisease = 'Liver disease',
  GallbladderDisease = 'Gallbladder disease',
  BowelDisease = "Bowel disease (e.g. Crohn's, irritable bowel)",
  KidneyDiseaseOrKidneyStones = 'Kidney disease/kidney stones',
  Anemia = 'Anemia',
  Cancer = 'Cancer',
  Osteoporosis = 'Osteoporosis',
  SleepApnea = 'Sleep apnea',
  PCOS = 'PCOS',
  Pancreatitis = 'Pancreatitis',
  ThyroidDisease = 'Thyroid disease',
  Glaucoma = 'Glaucoma',
  ArthritisOrChronicPain = 'Arthritis or chronic pain',
  Gout = 'Gout',
  Depression = 'Depression',
  Anxiety = 'Anxiety',
  BipolarDisorder = 'Bipolar disorder',
  EatingDisorder = 'Eating disorder',
  SubstanceAbuse = 'Substance abuse',
  OtherPsychologicalCondition = 'Other psychological condition',
}

export const MEDICAL_PROBLEMS_WITH_ICD10_CODES = {
  [MedicalProblems.Obesity]: {
    icd10Code: 'E66.09',
  },
  [MedicalProblems.MorbidObesity]: {
    icd10Code: 'E66.01',
  },
  [MedicalProblems.Overweight]: {
    icd10Code: 'E66.3',
  },
  [MedicalProblems.WeightGain]: {
    icd10Code: 'R63.5',
  },
  [MedicalProblems.HeartAttackOrHeartDisease]: {
    icd10Code: 'I21.9',
  },
  [MedicalProblems.HeartPalpitationsOrAbnormalHeartRhythm]: {
    icd10Code: 'I49.9',
  },
  [MedicalProblems.Stroke]: {
    icd10Code: 'I63.9',
  },
  [MedicalProblems.NeurologicalDisorders]: {
    icd10Code: 'G93.89',
  },
  [MedicalProblems.Migraines]: {
    icd10Code: 'G43.801',
  },
  [MedicalProblems.HighBloodPressure]: {
    icd10Code: 'I10',
  },
  [MedicalProblems.HighCholesterolOrBorderlineCholesterol]: {
    icd10Code: 'E78.5',
  },
  [MedicalProblems.Prediabetes]: {
    icd10Code: 'R73.03',
  },
  [MedicalProblems.Type1Diabetes]: {
    icd10Code: 'E10.8',
  },
  [MedicalProblems.Type2Diabetes]: {
    icd10Code: 'E11.8',
  },
  [MedicalProblems.LungDisease]: {
    icd10Code: 'J44.9',
  },
  [MedicalProblems.LiverDisease]: {
    icd10Code: 'K76.0',
  },
  [MedicalProblems.GallbladderDisease]: {
    icd10Code: 'K82.8',
  },
  [MedicalProblems.BowelDisease]: {
    icd10Code: 'K50.10',
  },
  [MedicalProblems.KidneyDiseaseOrKidneyStones]: {
    icd10Code: 'Q61.8',
  },
  [MedicalProblems.Anemia]: {
    icd10Code: 'D64.6',
  },
  [MedicalProblems.Cancer]: {
    icd10Code: 'C00-D49',
  },
  [MedicalProblems.Osteoporosis]: {
    icd10Code: 'M81.8',
  },
  [MedicalProblems.SleepApnea]: {
    icd10Code: 'G47.39',
  },
  [MedicalProblems.PCOS]: {
    icd10Code: 'E28.2',
  },
  [MedicalProblems.Pancreatitis]: {
    icd10Code: 'K86.89',
  },
  [MedicalProblems.ThyroidDisease]: {
    icd10Code: 'E07.9',
  },
  [MedicalProblems.Glaucoma]: {
    icd10Code: 'H40.9',
  },
  [MedicalProblems.ArthritisOrChronicPain]: {
    icd10Code: 'G89.29',
  },
  [MedicalProblems.Gout]: {
    icd10Code: 'M10.9',
  },
  [MedicalProblems.Depression]: {
    icd10Code: 'F32.A',
  },
  [MedicalProblems.Anxiety]: {
    icd10Code: 'F41.9',
  },
  [MedicalProblems.BipolarDisorder]: {
    icd10Code: 'F31.9',
  },
  [MedicalProblems.EatingDisorder]: {
    icd10Code: 'F50.9',
  },
  [MedicalProblems.SubstanceAbuse]: {
    icd10Code: 'F19.10',
  },
  [MedicalProblems.OtherPsychologicalCondition]: {
    icd10Code: 'F99',
  },
};
