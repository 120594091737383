import axios from 'axios';
import { Loading } from 'components/elements';
import Icon from 'components/icon';
import Toast from 'components/toast';
import parse from 'html-react-parser';
import { EFeatures } from 'lib/feature/features.types';
import moment from 'moment';
import { AttentionDimension } from 'node-api/attention/AttentionApi.types';
import { AttentionClient } from 'node-api/attention/AttentionApiClient';
import { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { ILegacyNodeCardComment, ILegacyNodeCardListEntry } from 'services/card-service.types';
import { EventNames } from 'services/provider-app-mixpanel-events';
import { ProviderAppMixpanelInstance } from 'services/provider-app-mixpanel-service';
import { ClickableElement } from 'services/shared-mixpanel-service';
import { FeaturesContext } from 'state/contexts/features';
import { PatientContext } from 'state/contexts/patient';
import { debug } from 'utils/helpers';
import CardsService from '../../../../services/card-service';
import iphoneShapeSrc from '../../../icon/pngs/iphone-device-2.png';
import Modal from '../modal';
import {
  Avatar,
  Body,
  Card,
  CardContent,
  CardTitle,
  CenterContent,
  Comment,
  Comments,
  Container,
  Footer,
  Header,
  HiddenSection,
  Input,
  Line,
  PostComment,
  PostCommentHeader,
  SectionComments,
  SectionDetails,
  SectionDetailsRow,
  SectionImage,
  SectionName,
  SectionPatient,
  SectionTitle,
  SubmitButton,
} from './style';
import { IPhoneContent } from './styleModern';

const CardDetailsModal: FC<{
  onClose: (elementId: number | string) => void;
  card: ILegacyNodeCardListEntry;
  memberId: number;
}> = ({ onClose, card, memberId }) => {
  const [cardContent, setCardContent] = useState('<div></div>');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [commentContent, setCommentContent] = useState('');
  const [comments, setComments] = useState<ILegacyNodeCardComment[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingContent, setLoadingContent] = useState(true);

  const { state: patientState } = useContext(PatientContext);
  const member = patientState.data.find((p) => p.id === memberId);

  const { state: featuresState } = useContext(FeaturesContext);
  const canMarkAsReviewed = featuresState?.[EFeatures.ProviderProfileShowNeedsAttention];

  const getCardContent = async (cardUuid: string) => {
    const uuid = cardUuid;
    const cardData = await CardsService.getCard(uuid);

    if (!cardData) {
      setLoadingContent(false);
      return Toast.show('error', `Error loading card content, please try again later!`);
    }

    setCardContent(cardData.card.content);

    setLoadingContent(false);
  };

  const setDimensions = (html: string) => {
    const parsed = parse(html);
    if (!Array.isArray(parsed)) {
      if (parsed.props.width && parsed.props.height) {
        const w = parseInt(parsed.props.width, 10);
        const h = parseInt(parsed.props.height, 10);
        setWidth(`${w + 340}px`);
        setHeight(`${h + 50}px`);
      }
    }
  };

  const handleMarkCardCommentAsTendedTo = async () => {
    const attentionClient = new AttentionClient();
    if (member && card) {
      attentionClient.markMemberAsTendedToForDimension({
        memberIds: [Number(member.id)],
        dimensionKey: AttentionDimension.CardComments,
      });
    }
  };

  const getComments = async () => {
    try {
      const legacyCard = await CardsService.getLegacyCardContentAndComments(card.card_id);
      setComments(legacyCard.comments);
      setDimensions(legacyCard.content);

      setLoading(false);

      // TODO: Decide if we want to mark the card as tended to here or when a comment is posted
      handleMarkCardCommentAsTendedTo();
    } catch (err) {
      if (!axios.isCancel(err)) {
        debug(err as string);
        setLoading(false);
      }
    }
  };

  const refreshComments = () => {
    setCommentContent('');
    setLoading(true);
    const source = axios.CancelToken.source();
    getComments();
    return source.cancel;
  };

  const handlePostComment = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!member || !card) {
      return;
    }
    try {
      await CardsService.postComment({ member, card, comment: commentContent });

      ProviderAppMixpanelInstance.track({
        eventName: EventNames.CardDetailClickCommentButton,
        targetLabel: 'send',
        targetType: ClickableElement.BUTTON,
        'card-id': card.card_id,
        'member-id': member.id,
      });

      refreshComments();
    } catch (err) {
      debug(err as string);
    }
  };

  const handleMarkAsReviewed = async () => {
    if (!member || !card) {
      return;
    }
    try {
      await CardsService.markAsReviewed({ member, card });

      ProviderAppMixpanelInstance.track({
        eventName: EventNames.CardDetailClickReviewButton,
        targetLabel: 'Reviewed',
        targetType: ClickableElement.BUTTON,
        'card-id': card.card_id,
        'member-id': memberId,
      });
      refreshComments();
    } catch (err: unknown) {
      debug(err as string);
    }
  };

  const renderComment = () => {
    return (
      <Comment>
        <Header>
          <SectionPatient>
            <SectionImage>
              {member?.avatar?.thumb?.url ? (
                <Avatar
                  src={member.avatar?.thumb?.url}
                  alt={`${member.first_name} ${member.last_name}`}
                />
              ) : (
                <Icon icon='userBubble' size={35} color='turquoise' />
              )}
            </SectionImage>
            <SectionName>
              <div>{`${member?.first_name} ${member?.last_name}`}</div>
            </SectionName>
          </SectionPatient>
          <SectionDetails>
            <SectionTitle>{`${card.title}`}</SectionTitle>
            <HiddenSection>
              <SectionDetailsRow>
                <div>Created by</div>
                <div>{`${card.provider_first_name} ${card.provider_last_name}`}</div>
              </SectionDetailsRow>
              <SectionDetailsRow>
                <div>Due at</div>
                <div>{`${moment
                  .tz(card.due_at, 'America/Los_Angeles')
                  .format('MMM Do YYYY')}`}</div>
              </SectionDetailsRow>
              <SectionDetailsRow>
                <div>Status</div>
                <div>{`${card.completed ? 'Completed' : 'Not completed'}`}</div>
              </SectionDetailsRow>
            </HiddenSection>
          </SectionDetails>
        </Header>
        <Line />
        <Body>
          {loading ? (
            <Loading />
          ) : (
            comments.map((singleComment) => (
              <div key={`wrapper-${singleComment.comment_date}`}>
                <Comments key={`comment-${singleComment.comment_date}`}>
                  <div>
                    {singleComment.sender_avatar_url ? (
                      <Avatar src={singleComment.sender_avatar_url} />
                    ) : null}
                  </div>
                  <SectionComments>
                    <div>{`${singleComment.sender_first_name} ${singleComment.sender_last_name}`}</div>
                    <div>{singleComment.content}</div>
                  </SectionComments>
                </Comments>
              </div>
            ))
          )}
        </Body>
        <Footer>
          <PostCommentHeader>
            <PostComment>
              <p>Post a Comment</p>
            </PostComment>
          </PostCommentHeader>
          <form onSubmit={handlePostComment}>
            <Input onChange={(e) => setCommentContent(e.target.value)} value={commentContent} />
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
              {canMarkAsReviewed && (
                <SubmitButton
                  onClick={handleMarkAsReviewed}
                  type='button'
                  disabled={!!commentContent}>
                  Reviewed
                </SubmitButton>
              )}
              <SubmitButton type='submit' disabled={!commentContent}>
                Comment
              </SubmitButton>
            </div>
          </form>
        </Footer>
      </Comment>
    );
  };

  useEffect(() => {
    getCardContent(card.uuid);
    const cancel = refreshComments();

    return () => {
      cancel();
    };
  }, []);

  return (
    <Modal width={width} height={height} onClose={onClose} minwidth='400px' minheight='630px'>
      <Container>
        <Card
          key={`card-${card.card_id}`}
          style={{
            overflow: 'hidden',
          }}>
          <IPhoneContent id='iphone-emulator' preview={`url(${iphoneShapeSrc})`}>
            <CardTitle>{card.title}</CardTitle>
            <div
              style={{
                marginLeft: '50px',
              }}>
              {moment.tz(card.due_at, 'America/Los_Angeles').format('MMMM DD, YYYY')}
            </div>

            {loadingContent ? (
              <CenterContent>
                <Loading />
              </CenterContent>
            ) : (
              <CardContent dangerouslySetInnerHTML={{ __html: cardContent }} />
            )}
          </IPhoneContent>
        </Card>
        {card.uuid ? renderComment() : null}
      </Container>
    </Modal>
  );
};

export default CardDetailsModal;
