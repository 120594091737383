import api from 'api';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';

import { debug } from 'utils/helpers';

import { StickyContext } from 'state/contexts/sticky';

import { Sticky } from 'components/elements';

const StickyNotes = ({ patientId }) => {
  const { state: stickyState, dispatch: stickyDispatch } = useContext(StickyContext);

  const [notes, setNotes] = useState([]);

  const source = axios.CancelToken.source();

  const handleClose = async (id) => {
    try {
      stickyDispatch({
        type: 'DELETE',
        payload: {
          patientId,
          id,
        },
      });

      await api().put(`sticks/${id}?auth_token=AUTH_TOKEN`);
    } catch (err) {
      debug('Error', err);
    }
  };

  const getStickyNotes = async () => {
    try {
      const {
        data: { data },
      } = await api().get(`sticks/${patientId}/AUTH_TOKEN`, {
        cancelToken: source.token,
      });

      stickyDispatch({
        type: 'SET',
        payload: {
          patientId,
          data,
        },
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        debug(err);
      }
    }
  };

  useEffect(() => {
    setNotes(stickyState.data[patientId]);
  }, [stickyState.data]);

  useEffect(() => {
    if (stickyState.reload !== 0) {
      setTimeout(() => {
        getStickyNotes();
      }, 1000);
    }
  }, [stickyState.reload]);

  useEffect(() => {
    setNotes([]);
    getStickyNotes();

    return () => {
      source.cancel();
    };
  }, [patientId]);

  return (
    <>
      {notes.map((sticky) => (
        <Sticky
          key={`sticky-${sticky.id}`}
          backgroundColor='turquoise'
          color='teal'
          onClose={() => handleClose(sticky.id)}>
          Sticky Note: {sticky.description}
        </Sticky>
      ))}
    </>
  );
};

StickyNotes.propTypes = {
  patientId: PropTypes.number.isRequired,
};

export default StickyNotes;
