import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';
import { initialState, reducer } from '../reducers/sticky';

const StickyContext = createContext(initialState);

const StickyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <StickyContext.Provider value={{ state, dispatch }}>{children}</StickyContext.Provider>;
};

StickyProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { StickyContext, StickyProvider };
