import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';
import { Action, State, initialState, reducer } from '../reducers/chat';

const ChatContext = createContext<{ state: State; dispatch: Dispatch<Action> }>({
  state: initialState,
  dispatch: () => null,
});

const ChatProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <ChatContext.Provider value={{ state, dispatch }}>{children}</ChatContext.Provider>;
};

export { ChatContext, ChatProvider };
