import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';
import { initialState, reducer } from '../reducers/tag';

const TagContext = createContext(initialState);

const TagProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <TagContext.Provider value={{ state, dispatch }}>{children}</TagContext.Provider>;
};

TagProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { TagContext, TagProvider };
