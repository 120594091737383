import {
  Avatar,
  ChatContainer,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  MessageSeparator,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import Axios from 'axios';
import Toast from 'components/toast';
import { CHATS } from 'config';
import { useContext, useEffect, useRef, useState } from 'react';
import Pusher from 'services/pusher-service';
import { PatientContext } from 'state/contexts/patient';
import { ProviderContext } from 'state/contexts/provider';
import { UserContext } from 'state/contexts/user';
import { ActionTypes as ProviderActionTypes } from 'state/reducers/provider';
import { getChatMessages, getProviderRole, getProvidersForMember, submitMessage } from '../helper';
import { ChatMessage } from '../types';
import { ChatBoxInput } from './ChatBoxInput';
import { ChatBoxActionStyle, ChatBoxMentionStyle } from './ChatBoxInputStyle';
import './chatbox-styles.css';

const MESSAGE_DELAY_INDICATOR_3_5HOURS_IN_MS = 24 * 60 * 60 * 1000 * 3.5;

type Props = {
  roomType: 'direct' | 'team';
  memberId: number;
  onReceiveMessage?: () => unknown;
};

const sortAndDeDuplicateMessages = (messages: ChatMessage[]) =>
  messages
    .sort((a, b) => a.id - b.id) // Make sure messages are sorted, which could happen if there's a race condition between multiple loads
    .filter(
      // De-duplicate messages, it could happen due to sending while loading new messages
      (m2, index, self) => index === 0 || self[index - 1].id !== m2.id
    );

export const ChatBox: React.FC<Props> = ({ roomType, memberId, onReceiveMessage }) => {
  const { state: patientState } = useContext(PatientContext);
  const { state: userState } = useContext(UserContext);
  const { state: providerState, dispatch: providerDispatch } = useContext(ProviderContext);

  const member = patientState.data.find((p) => p.id === memberId);

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [previousMessageId, setPreviousMessageId] = useState(0);
  const [lastReadMessageId, setLastReadMessageId] = useState(0);

  const [loadingMorePrevious, setLoadingMorePrevious] = useState(false);
  const messageListRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (member) {
      const source = Axios.CancelToken.source();
      getProvidersForMember(member, source.token)
        .then(({ providers, partnerships }) => {
          providerDispatch({
            type: ProviderActionTypes.SET,
            payload: { providers: { data: providers, partnerships } },
          });
        })
        .catch((error) => {
          if (!Axios.isCancel(error)) {
            console.error(error);
            Toast.show('error', 'Failed to load providers');
          }
        });
      return () => {
        source.cancel();
      };
    }
  }, [member, providerDispatch]);

  const loadNewerMessages = async () => {
    if (!member) {
      return;
    }
    const lastMessage = messages[messages.length - 1];
    const { messages: updatedMessages } = await getChatMessages(member, roomType, {
      direction: 'next',
      lastMessageId: lastMessage ? lastMessage.id : 0,
    });
    //
    setMessages(
      // Notice: Always use a function to update state, as it guarantees the latest state
      (m) =>
        sortAndDeDuplicateMessages(
          [...m, ...updatedMessages] // Append new messages
            .filter((m2) => m2.direction !== 'outgoing-temporary') // Remove temporary messages
        )
    );
  };

  const loadPreviousMessages = async (forcePreviousId?: number) => {
    if (!member) {
      return;
    }
    if (!forcePreviousId && (previousMessageId === 0 || loadingMorePrevious)) {
      return;
    }
    setLoadingMorePrevious(true);

    const { messages: updatedMessages, previousMessageId: updatedPreviousId } =
      await getChatMessages(member, roomType, {
        direction: 'prev',
        firstMessageId: forcePreviousId || previousMessageId,
      });
    setPreviousMessageId(updatedPreviousId);
    setMessages(
      // Notice: Always use a function to update state, as it guarantees the latest state
      (m) => sortAndDeDuplicateMessages([...updatedMessages, ...m]) // Prepend new messages
    );
    setLoadingMorePrevious(false);
  };

  const handleReceiveMessage = async () => {
    if (!member) {
      return;
    }
    await loadNewerMessages();
    if (onReceiveMessage) {
      onReceiveMessage();
    }
  };

  // Subscribe to websocket notifications for new massages
  useEffect(() => {
    if (member) {
      const socketName = `${roomType}-${userState.uuid}`;
      Pusher.connect(socketName, userState.auth_token);
      Pusher.subscribe(socketName, `${CHATS.pusher[roomType].channel}-${userState.uuid}`);

      Pusher.bind(
        socketName,
        CHATS.pusher[roomType].event,
        ({ sender_uuid: senderUuid }: { sender_uuid: string; patient_uuid: string }) => {
          if (senderUuid === member.uuid) {
            handleReceiveMessage();
          }
        }
      );

      return () => {
        Pusher.disconnect(socketName);
      };
    }
  }, [member, roomType]);

  // Get first batch of messages
  useEffect(() => {
    if (member) {
      (async () => {
        const { messages: updatedMessages, previousMessageId: updatedPreviousId } =
          await getChatMessages(member, roomType, {
            direction: 'next',
            lastMessageId: 0,
          });
        setPreviousMessageId(updatedPreviousId);
        setMessages(updatedMessages);
        const uLastReadMessageId = updatedMessages[updatedMessages.length - 1]?.id;
        if (uLastReadMessageId) {
          setLastReadMessageId(uLastReadMessageId);
        }
        // The backend only sends very few messages,
        // so we need to load more and force scroll to appear (if we want auto-load)
        loadPreviousMessages(updatedPreviousId);
      })();
    }
  }, [member, roomType]);

  const handleSendMessage = async (
    _textRichContent: string,
    plainText: string
    // mentions: MentionItem[]
  ) => {
    if (!member) {
      return;
    }
    const message = plainText.trim();
    if (message) {
      const tempMessage = await submitMessage({
        member,
        type: roomType,
        content: message,
        providerIds: [],
      });
      /* Hack to make the message appear immediately, as it was sent by the user */
      // We need the last message, so we can override the senderName
      const lastMessageIfMine =
        messages[messages.length - 1]?.direction === 'outgoing'
          ? messages[messages.length - 1]
          : null;
      if (lastMessageIfMine) {
        // Given we don't use avatars or anything else, we only need to update the senderName
        // We use `senderName` during render to identify message position (single, first, normal, last, etc).
        tempMessage.senderName = lastMessageIfMine.senderName;
      }

      // We need to add the message to the list, so we can render it (but it's fake)
      setMessages((m) => sortAndDeDuplicateMessages([...m, tempMessage]));
      setLastReadMessageId(tempMessage.id);

      // We re-fetch the messages, so we can get the real message from the server
      await loadNewerMessages();
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (messageListRef.current as any)?.scrollToBottom('auto');
      }, 100);
    }
  };

  // ChatBox allows to have Message and MessageSeparator elements.
  // All of them have to have the same hierarchy,
  // so we first map messages with their separators,
  // then flatten the structure.
  // If ChatBox receives <><MessageSeparator .../></Message .../></> or [<.../><.../>], auto-scroll breaks!
  const messageElements: (ChatMessage | string)[] = messages
    .map((message, index) => {
      const isFirstUnread =
        message.direction === 'incoming' && messages[index - 1]?.id === lastReadMessageId;
      const thisAndPreviousAreNotTransient =
        index > 0 &&
        message.direction !== 'outgoing-temporary' &&
        messages[index - 1]?.direction !== 'outgoing-temporary';
      const timeBetweenMessages =
        index > 0
          ? new Date(message.createdAt).getTime() -
            new Date(messages[index - 1].createdAt).getTime()
          : 0;
      const longDelayBetweenMessages =
        thisAndPreviousAreNotTransient &&
        timeBetweenMessages > MESSAGE_DELAY_INDICATOR_3_5HOURS_IN_MS;

      const messageDateTimeInBrowserTimezone = new Date(message.createdAt).toLocaleString('en-US', {
        dateStyle: 'medium',
        timeStyle: 'short',
      });

      return isFirstUnread
        ? [`${messageDateTimeInBrowserTimezone} • Unread`, message]
        : index === 0 || longDelayBetweenMessages
        ? [`${messageDateTimeInBrowserTimezone}`, message]
        : [message];
    })
    // Before this we have [[message], [separator, message], [message], ... ]
    .flat(); // Now we have [ message, separator, message, message ... ]

  return (
    <MainContainer>
      <ChatContainer>
        <MessageList
          ref={messageListRef}
          onYReachStart={() => loadPreviousMessages()}
          autoScrollToBottom
          loadingMore={loadingMorePrevious}
          loadingMorePosition={loadingMorePrevious ? 'top' : undefined}>
          {messageElements.map((messageOrSeparator, index) => {
            if (typeof messageOrSeparator === 'string') {
              // Adding MessageSeparators is nice, however it makes the chat's scroll jump a bit.
              // TODO: Learn to use `MessageGroup`, but that's more complex
              return <MessageSeparator key={messageOrSeparator} content={messageOrSeparator} />;
            }
            const message: ChatMessage = messageOrSeparator;
            const prevMessage: ChatMessage = messageElements[index - 1] as ChatMessage;
            const nextMessage: ChatMessage = messageElements[index + 1] as ChatMessage;
            // These 2 will be false if prev/next is a separator text:
            const samePrev = prevMessage?.senderName === message.senderName;
            const sameNext = nextMessage?.senderName === message.senderName;

            const position = samePrev
              ? sameNext
                ? 'normal'
                : 'last'
              : sameNext
              ? 'first'
              : 'single';

            const provider = providerState.data.find((p) =>
              message.senderName?.includes(p.last_name)
            );
            const role = provider && getProviderRole(provider);

            return (
              <Message
                key={message.id} // Super important to use a unique key, as MessageList will use it to keep track of the right scroll position
                avatarSpacer={message.direction === 'incoming' && samePrev}
                avatarPosition='top-left'
                className={`provider__${role?.toLowerCase()}`}
                model={{
                  message: message.content,
                  sentTime: message.createdAt,
                  sender: message.senderName,
                  // direction could also be 'outgoing-temporary', in which case we consider it as 'outgoing'
                  direction: message.direction === 'incoming' ? 'incoming' : 'outgoing',
                  position,
                }}>
                {message.direction === 'incoming' && !samePrev && (
                  /* Avatar size is hacked via .css - If this is grouped within MessageGroup, hack needs to be removed */
                  <Avatar name={message.senderName} src={message.senderAvatarUrl || ''} />
                )}
              </Message>
            );
          })}
        </MessageList>
        <div
          // eslint-disable-next-line react/no-unknown-property
          as={MessageInput}>
          <ChatBoxInput
            onSend={handleSendMessage}
            autoFocus
            sendOnEnter
            placeholder={`Type a ${roomType} message...`}
            mentionOptions={
              roomType === 'team'
                ? {
                    // This is the POC. It's disabled for DIRECT chat, so you can only experience it by hacking this
                    '@': {
                      markup: '@[__display__](user:__id__)',
                      style: ChatBoxMentionStyle,
                      displayTransform: (_id, display) => `@${display}`,
                      items: providerState.data.map((p) => ({
                        id: p.id,
                        display: `${p.first_name} ${p.last_name}`,
                      })),
                    },
                  }
                : {
                    // For DIRECT messages
                    '#': {
                      markup: '#[[__display__]]',
                      style: ChatBoxActionStyle,
                      displayTransform: (_id, display) => `#${display}`,
                      items: [
                        { id: 'task:pa', display: 'Task: Prior Authorization (demo)' },
                        { id: 'task:refill', display: 'Task: Refill (demo)' },
                      ],
                    },
                  }
            }
          />
        </div>
      </ChatContainer>
    </MainContainer>
  );
};
