import { Reducer } from 'react';
import { Action as DefaultAction } from 'state/types';

export enum ActionTypes {
  SET = 'SET',
  TOGGLE_MODAL = 'TOGGLE_MODAL',
}

export type Program = {
  id: number;
  text: string;
  display_value: string;
};

export type State = {
  modal: boolean;
  data: Program[];
  groupedData: { id: string; text: string }[];
};

type Payload = {
  [ActionTypes.SET]: Program[];
  [ActionTypes.TOGGLE_MODAL]: never;
};

export type Action = DefaultAction<Payload>;

export const initialState: State = {
  modal: false,
  data: [],
  groupedData: [],
};

const groupData = (list: Program[]) => {
  const groupedData = [];
  const groupsObj: { [key: string]: string } = {};
  let groupsList = list.reduce((grps, obj) => {
    const g = obj.text.split('-');
    if (g[1]) {
      grps.push(g[0].trim());
    }
    return grps;
  }, [] as string[]);
  groupsList = [...new Set(groupsList)];

  for (const obj of list) {
    const g = obj.text.split('-')[0].trim();
    if (groupsList.includes(g)) {
      groupsObj[g] = `${groupsObj[g] || ''},${obj.id}`;
    } else {
      groupedData.push({ id: `${obj.id}`, text: obj.text });
    }
  }

  for (const key in groupsObj) {
    if (Object.prototype.hasOwnProperty.call(groupsObj, key)) {
      groupedData.push({ id: groupsObj[key].substr(1) as string, text: key });
    }
  }
  return groupedData;
};

export const reducer: Reducer<State, Action> = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_MODAL: {
      return {
        ...state,
        modal: !state.modal,
      };
    }
    case ActionTypes.SET: {
      const { payload } = action;
      return {
        ...state,
        data: payload,
        groupedData: groupData(payload),
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};
