import { Box } from '@mui/material';
import { Loading, Tabs } from 'components/elements';
import Toast from 'components/toast';
import { EFeatures } from 'lib/feature/features.types';
import AppointmentsClient from 'node-api/schedule/AppointmentsClient';
import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FeaturesContext } from 'state/contexts/features';
import MemberUpcomingAppointments from './MemberUpcomingAppointments';
import CreateNewScheduleWithinCategoryTab from './Schedule/CreateNewScheduleWithinCategoryTab';
import GroupClassScheduler from './Schedule/GroupClassScheduler';
import './ScheduleTab.css';
import { Text } from './style';
import { AppointmentCategory, ScheduleComponentProps } from './types';

const STATIC_CATEGORIES: AppointmentCategory[] = [
  { name: 'Group Class', categoryType: 'group-class' } as AppointmentCategory,
];

const ScheduleTab: FunctionComponent<ScheduleComponentProps> = ({ patient }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [appointmentCategories, setAppointmentCategories] = useState<AppointmentCategory[]>();

  const { state: featuresState } = useContext(FeaturesContext);

  const { id: memberId } = patient;
  const appointmentsClient = useMemo(() => new AppointmentsClient(), []);

  const getAppointmentTypes = useCallback(async () => {
    setIsLoading(true);

    try {
      const { categories } = await appointmentsClient.getAppointmentTypesForCareTeam({
        memberId,
      });

      setAppointmentCategories(categories);
    } catch (error) {
      Toast.show('error', 'Could not load categories for appointments!');
    }

    setIsLoading(false);
  }, [appointmentsClient, memberId]);

  useEffect(() => {
    getAppointmentTypes();
  }, [getAppointmentTypes]);

  return (
    <div className='schedule-tab'>
      <Box className='schedule-tab__new-box'>
        {isLoading ? (
          <Loading />
        ) : appointmentCategories?.length ? (
          <Tabs
            data={[
              ...appointmentCategories,
              ...(featuresState[EFeatures.ProviderShowGroupClasses] ? STATIC_CATEGORIES : []),
            ].map((e, index) => ({
              ...e,
              id: index + 1,
            }))}
            renderTabLabel={(data) => <Text>{data.name}</Text>}
            renderTabContent={(data) =>
              data.categoryType === 'group-class' ? (
                <GroupClassScheduler patient={patient} />
              ) : (
                <CreateNewScheduleWithinCategoryTab
                  data={data}
                  key={`category-${data.id}`}
                  patient={patient}
                />
              )
            }
          />
        ) : null}
      </Box>

      <Box className='schedule-tab__list-box'>
        <MemberUpcomingAppointments patient={patient} />
      </Box>
    </div>
  );
};

export default ScheduleTab;
