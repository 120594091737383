import { Box, List, ListItem, Typography } from '@mui/material';
import {
  DiagnosisSummaryContentType,
  MedicalProblemsContentType,
  MeetingContextContentType,
  MeetingDurationContentType,
  ScribeOutputType,
  SummaryContentType,
} from 'node-api/scribe/Scribe.types';

const renderDiagnosisSummarySection = (content: DiagnosisSummaryContentType) => {
  return (
    <Box mb={2}>
      <Typography>During this encounter, I addressing the following:</Typography>

      {Object.entries(content.data).map(([premise, diagnoses]) => {
        if (!Array.isArray(diagnoses) || diagnoses.length === 0) {
          return [];
        }

        const diagnosisPremise = premise.replace(/([A-Z])/g, ' $1');

        return (
          <List sx={{ listStyleType: 'disc', paddingLeft: '1em' }}>
            <ListItem sx={{ display: 'list-item', listStyle: 'disc', paddingLeft: '5px' }}>
              {`${diagnosisPremise[0].toUpperCase() + diagnosisPremise.slice(1)}: ${diagnoses.join(
                ', '
              )}`}
            </ListItem>
          </List>
        );
      })}
    </Box>
  );
};

const renderMedicalProblemsSection = (content: MedicalProblemsContentType) => {
  return (
    <>
      <Typography mb={2}>Problems</Typography>
      {content.data.map((problem) => {
        return (
          <Box mb={2}>
            <Typography mb={2} fontWeight={600}>
              {`${problem.name} - ${problem.icd10Code}`}
            </Typography>

            <Typography pl={4}>History:</Typography>
            <Typography mb={2} pl={6}>
              {problem?.history}
            </Typography>
            <Typography pl={4}>Data Review:</Typography>
            <Typography mb={2} pl={6}>
              {problem?.dataReview}
            </Typography>
            <Typography pl={4}>Subjective Updates:</Typography>
            <Typography mb={2} pl={6}>
              {problem?.subjectiveUpdates}
            </Typography>
            <Typography pl={4}>Plan:</Typography>
            <Typography mb={2} pl={6}>
              {problem?.plan}
            </Typography>
          </Box>
        );
      })}
    </>
  );
};

const renderMeetingContextSection = (content: MeetingContextContentType) => {
  return (
    <Typography mb={2} textAlign='justify'>
      {content.data.context}
    </Typography>
  );
};

const renderMeetingDurationSection = (content: MeetingDurationContentType) => {
  return (
    <Typography mb={2} textAlign='justify'>
      During this encounter, I spent approximately {content.data.meetingDurationRange}
    </Typography>
  );
};

export const renderSectionsByOutputType = (
  outputType: ScribeOutputType,
  data: SummaryContentType
) => {
  switch (outputType) {
    case ScribeOutputType.MeetingContext:
      return renderMeetingContextSection(data as MeetingContextContentType);

    case ScribeOutputType.MeetingDuration:
      return renderMeetingDurationSection(data as MeetingDurationContentType);

    case ScribeOutputType.MedicalProblems:
      return renderMedicalProblemsSection(data as MedicalProblemsContentType);

    case ScribeOutputType.DiagnosisSummary:
      return renderDiagnosisSummarySection(data as DiagnosisSummaryContentType);

    default:
      return <>There was an error showing the information</>;
  }
};
