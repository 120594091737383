import { Box, Modal, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import LoadingButton from 'components/atoms/wrappers/LoadingButton';
import Toast from 'components/toast';
import MembersClient from 'node-api/MembersClient';
import { FC, useContext, useState } from 'react';
import { PatientContext } from 'state/contexts/patient';
import { WeightsContext } from 'state/contexts/weights';
import { getDateTimeFromString } from 'utils/dateHelpers';
import './weight-bulk-delete.css';

type Props = {
  onClose: () => void;
  onUpdateWeights: () => void;
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 150 },
  { field: 'value', headerName: 'Weight', width: 150 },
  {
    field: 'date',
    headerName: 'Date',
    width: 250,
    valueFormatter: (param) => getDateTimeFromString(param.value),
  },
];

const WeightBulkDelete: FC<Props> = ({ onClose, onUpdateWeights }) => {
  const [weightsToDelete, setWeightToDelete] = useState<number[]>([]);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const { state: patientState } = useContext(PatientContext);
  const { state: weightsState } = useContext(WeightsContext);

  const patient = patientState.data.find((p) => p.current);

  const handleCellClickEvent: GridEventListener<'cellClick'> = ({ row }) => {
    const foundIdIndex = weightsToDelete.findIndex((id) => id === row.id);
    if (foundIdIndex !== -1) {
      const currentIds = [...weightsToDelete];
      currentIds.splice(foundIdIndex, 1);

      setWeightToDelete(currentIds);
      return;
    }

    setWeightToDelete([...weightsToDelete, row.id]);
  };

  const handleDeleteWeights = async () => {
    if (!patient) {
      return;
    }

    if (weightsToDelete.length === 0) {
      Toast.show('info', 'You have to select at least one weight to delete');
      return;
    }

    setIsDeleting(true);
    const deletePromises = [];
    const membersServiceClient = new MembersClient();
    for (const recordId of weightsToDelete) {
      const promise = membersServiceClient.deleteWeightInAllPlatforms(Number(patient.id), {
        healthRecordId: recordId,
        memberUuid: patient.uuid,
      });

      deletePromises.push(promise);
    }

    try {
      await Promise.all(deletePromises);
    } catch (err) {
      console.error('handleDeleteWeights::error: ', err);
    }

    onUpdateWeights();
    setIsDeleting(false);
    onClose();
  };

  return (
    <Modal open onClose={onClose} aria-labelledby='modal-modal-title'>
      <Stack className='bulk-modal'>
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          {`${patient?.first_name} ${patient?.last_name} weights`}
        </Typography>

        <DataGrid
          className='weight-bulk-grid'
          onCellClick={handleCellClickEvent}
          rows={weightsState.weight.weights}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 50 },
            },
          }}
          checkboxSelection
        />
        <Box margin={2} padding={2} sx={{ alignSelf: 'flex-end' }}>
          <LoadingButton
            variant='contained'
            onClick={handleDeleteWeights}
            disabled={isDeleting}
            loading={isDeleting}>
            Delete Selected Weights
          </LoadingButton>
        </Box>
      </Stack>
    </Modal>
  );
};

export default WeightBulkDelete;
