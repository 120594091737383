/* eslint-disable @typescript-eslint/no-use-before-define */
import { ResponsiveTreeMap } from '@nivo/treemap';
import axios from 'axios';
import { AutoCompleteFilter, Button, IconButton, Loading } from 'components/elements';
import Icon from 'components/icon';
import node_api from 'node-api';
import { useContext, useEffect, useState } from 'react';
import { debug } from 'utils/helpers';

import { ClinicContext } from 'state/contexts/clinic';

import {
  Actions,
  Data,
  FilterTitle,
  FilterTitleContainer,
  Filters,
  FiltersActions,
  Item,
  Lifespan,
  Record,
  Separator,
  Treemap,
} from './style';

const PatientsStatuses = () => {
  const [loading, setLoading] = useState(true);
  const [treeMapData, setTreeMapData] = useState({});
  const [showFilters, SetShowFilters] = useState(true);

  const { state: clinicState } = useContext(ClinicContext);

  const [clinics, setClinics] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState();

  const [mps, setMPs] = useState([]);
  const [selectedMP, setSelectedMP] = useState();

  const [rds, setRDs] = useState([]);
  const [selectedRD, setSelectedRD] = useState();

  const [ecs, setECs] = useState([]);
  const [selectedEC, setSelectedEC] = useState();

  const [reload, setReload] = useState(Date.now());

  const submit = async () => {
    setLoading(true);
    const source = axios.CancelToken.source();
    const mp = [
      ...((selectedMP && [selectedMP]) || []),
      ...((selectedRD && [selectedRD]) || []),
      ...((selectedEC && [selectedEC]) || []),
    ].join();
    const clinic_id = selectedClinic;
    const params = { auth_token: 'AUTH_TOKEN', ...(clinic_id && { clinic_id }), ...(mp && { mp }) };
    try {
      // Get TreeMap Data
      const {
        data: { data },
      } = await node_api().get('statuses', {
        cancelToken: source.token,
        params,
      });

      prepareTreeMapData(data);

      setLoading(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        debug(err);
        setLoading(false);
      }
    }
  };

  const prepareTreeMapData = (data) => {
    const tmp = { ...data, children: [...data.statuses.sort((a, b) => b.value - a.value)] };
    // const tmp = {...data, children: data.statuses.map(item => ({name: item.name, value: item.value}) )};
    setTreeMapData(tmp);
  };

  const setOtherFilters = (clinic) => {
    // MPs Data
    let mpsList = [];
    let rdsList = [];
    let ecsList = [];
    for (const roles of clinicState.roles) {
      const list = clinic
        ? roles.team
            .filter((key) => key.clinic_id === clinic.id)
            .map((key) => ({
              name: `${key.first_name} ${key.last_name}`,
              id: key.id,
              clinic_id: key.clinic_id,
            }))
        : roles.team.map((key) => ({
            name: `${key.first_name} ${key.last_name}`,
            id: key.id,
            clinic_id: key.clinic_id,
          }));
      switch (roles.name) {
        case 'Medical Provider':
          mpsList = list;
          break;
        case 'Dietitian':
          rdsList = list;
          break;
        case 'Exercise Coach':
          ecsList = list;
          break;
        default:
          break;
      }
    }
    setMPs(
      mpsList
        .filter(
          (ele, ind) =>
            ind === mpsList.findIndex((key) => key.name === ele.name && key.id === ele.id)
        )
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    );
    setRDs(
      rdsList
        .filter(
          (ele, ind) =>
            ind === rdsList.findIndex((key) => key.name === ele.name && key.id === ele.id)
        )
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    );
    setECs(
      ecsList
        .filter(
          (ele, ind) =>
            ind === ecsList.findIndex((key) => key.name === ele.name && key.id === ele.id)
        )
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    );
  };

  const setClinicFilter = (mp, rd, ec) => {
    // Clinics Data
    let clinicsList = [];
    if (mp !== undefined)
      clinicsList = clinicState.clinics.filter((key) => key.id === mp.clinic_id);
    if (rd !== undefined)
      clinicsList.length > 0
        ? (clinicsList = clinicsList.filter((key) => key.id === rd.clinic_id))
        : (clinicsList = clinicState.clinics.filter((key) => key.id === rd.clinic_id));
    if (ec !== undefined)
      clinicsList.length > 0
        ? (clinicsList = clinicsList.filter((key) => key.id === ec.clinic_id))
        : (clinicsList = clinicState.clinics.filter((key) => key.id === ec.clinic_id));
    if (mp === undefined && rd === undefined && ec === undefined)
      clinicsList = clinicState.clinics.sort((a, b) => (a.name > b.name ? 1 : -1));

    setClinics(clinicsList);
  };

  const clinicOnChange = (clinic) => {
    setSelectedClinic((clinic && clinic.id) || undefined);
    setOtherFilters(clinic);
  };

  const mpOnChange = (mp) => {
    setSelectedMP((mp && mp.id) || undefined);
    setClinicFilter(mp || undefined, undefined, undefined);
  };

  const rdOnChange = (rd) => {
    setSelectedRD((rd && rd.id) || undefined);
    setClinicFilter(undefined, rd || undefined, undefined);
  };

  const ecOnChange = (ec) => {
    setSelectedEC((ec && ec.id) || undefined);
    setClinicFilter(undefined, undefined, ec || undefined);
  };

  useEffect(() => {
    setLoading(true);
    const source = axios.CancelToken.source();
    const params = { auth_token: 'AUTH_TOKEN' };

    const getData = async () => {
      try {
        // Get TreeMap Data
        const {
          data: { data },
        } = await node_api().get('statuses', {
          cancelToken: source.token,
          params,
        });
        prepareTreeMapData(data);

        // ----  Get Filters Data ----
        // Clinics Data
        setClinics(
          clinicState.clinics
            .map((key) => ({ name: key.name, id: key.id }))
            .sort((a, b) => (a.name > b.name ? 1 : -1))
        );
        setOtherFilters();

        setLoading(false);
      } catch (err) {
        if (!axios.isCancel(err)) {
          debug(err);
          setLoading(false);
        }
      }
    };
    getData();

    return () => {
      source.cancel();
    };
  }, []);

  return (
    <>
      <FilterTitleContainer>
        <FilterTitle>Filters</FilterTitle>
        <div>
          <IconButton onClick={() => SetShowFilters((prev) => !prev)}>
            <Icon icon='arrow' color='gray' size={16} />
          </IconButton>
        </div>
      </FilterTitleContainer>
      {
        <FiltersActions showFilters={showFilters}>
          <Filters key={`filter-${reload}`}>
            <AutoCompleteFilter
              // id={'clinic-input'}
              id='clinic-input'
              name='clinic'
              // errors={errors.tag}
              label='Clinic'
              placeholder='All Clinics'
              // value={clinics[0]}
              // register={register}
              getData={() => clinics}
              onChange={(e, opt) => {
                clinicOnChange(opt);
                debug(opt);
              }}
              renderOption={(opt) => (
                <Record key={`option-${opt.id}`}>
                  <Item>{opt.name}</Item>
                </Record>
              )}
            />
            <Separator />
            <AutoCompleteFilter
              // id={'mp-input'}
              id='mp-input'
              name='mp'
              // errors={errors.tag}
              label='Medical Provider'
              placeholder='All Medical Providers'
              // register={register}
              getData={() => mps}
              onChange={(e, opt) => {
                mpOnChange(opt);
                debug(opt);
              }}
              renderOption={(opt) => (
                <Record key={`option-${opt.id}`}>
                  <Item>{opt.name}</Item>
                </Record>
              )}
            />
            <Separator />
            <AutoCompleteFilter
              // id={'rd-input'}
              id='rd-input'
              name='rd'
              // errors={errors.tag}
              label='Registered Dietitian'
              placeholder='All Registered Dietitians'
              // register={register}
              getData={() => rds}
              onChange={(e, opt) => {
                rdOnChange(opt);
                debug(opt);
              }}
              renderOption={(opt) => (
                <Record key={`option-${opt.id}`}>
                  <Item>{opt.name}</Item>
                </Record>
              )}
            />
            <Separator />
            <AutoCompleteFilter
              // id={'ec-input'}
              id='ec-input'
              name='ec'
              // errors={errors.tag}
              label='Exercise Coach'
              placeholder='All Exercise Coaches'
              // register={register}
              getData={() => ecs}
              onChange={(e, opt) => {
                ecOnChange(opt);
                debug(opt);
              }}
              renderOption={(opt) => (
                <Record key={`option-${opt.id}`}>
                  <Item>{opt.name}</Item>
                </Record>
              )}
            />
          </Filters>
          <Actions>
            <Button
              variant='link'
              onClick={() => {
                setReload(Date.now());
                setSelectedClinic();
                setSelectedMP();
                setSelectedRD();
                setSelectedEC();
                setOtherFilters();
                setClinicFilter();
              }}>
              Reset
            </Button>
            <Button type='submit' onClick={submit}>
              Submit
            </Button>
          </Actions>
        </FiltersActions>
      }
      {loading ? (
        <Loading />
      ) : (
        <Data>
          <Treemap>
            <ResponsiveTreeMap
              root={treeMapData}
              identity='name'
              // value="loc"
              innerPadding={3}
              outerPadding={3}
              // margin={{
              //   top: 10,
              //   right: 10,
              //   bottom: 10,
              //   left: 10
              // }}
              // label="loc"
              label={(data) => `${data.name} (${data.value})`}
              // labelFormat=".0s"
              labelSkipSize={12}
              labelTextColor='inherit:darker(1.2)'
              colors={[
                '#1A667A',
                '#488594',
                '#75A3AF',
                '#A3C2CA',
                '#A8D9D6',
                '#B9E1DE',
                '#CBE8E6',
                '#DCF0EF',
              ]}
              // colors={{ scheme: 'set2' }}
              // colors={{ scheme: 'nivo' }}
              // colors={{ scheme: 'reds' }}
              // colorBy="depth"
              colorBy='name'
              // colorBy="color"
              borderColor='inherit:darker(0.3)'
              animate
              motionStiffness={90}
              motionDamping={11}
            />
          </Treemap>
          <Lifespan>
            <div>Average</div>
            <div>Lifespan:</div>
            <div>{treeMapData.average_lifespan}</div>
          </Lifespan>
        </Data>
      )}
    </>
  );
};

export default PatientsStatuses;
