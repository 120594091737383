const types = {
  TOGGLE_PANEL: 'TOGGLE_PANEL',
  SET: 'SET',
  ADD: 'ADD',
  LOAD: 'LOAD',
  REVIEW: 'REVIEW',
  REVIEW_ALL: 'REVIEW_ALL',
};

const initialState = {
  panel: false,
  count: 0,
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PANEL: {
      return {
        ...state,
        panel: !state.panel,
      };
    }

    case types.SET: {
      const { count, data } = action.payload;
      return {
        ...state,
        count,
        data,
      };
    }

    case types.ADD: {
      const { data } = action.payload;
      return {
        ...state,
        count: state.count + 1,
        data: [...data, ...state.data],
      };
    }

    case types.LOAD: {
      const { data } = action.payload;
      return {
        ...state,
        data: [...state.data, ...data],
      };
    }

    case types.REVIEW: {
      const { id, status } = action.payload;
      return {
        ...state,
        count: status === 'true' ? state.count + 1 : state.count - 1,
        data: state.data.map((notification) => {
          if (notification.id !== id) return notification;
          return {
            ...notification,
            active: status,
          };
        }),
      };
    }

    case types.REVIEW_ALL: {
      return {
        ...state,
        count: 0,
        data: state.data.map((notification) => ({
          ...notification,
          active: 'false',
        })),
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};
export { initialState, reducer, types };
