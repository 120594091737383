import axios from 'axios';
import { CheckBox, Loading } from 'components/elements';
import Toast from 'components/toast';
import { MEMBERS_WEB_APP_URL } from 'config';
import { useWindowHasFocus } from 'hooks/useWindowFocus';
import { LOCAL_TIMEZONE } from 'lib/timezone';
import moment from 'moment';
import { schedulingV3Client } from 'node-api/scheduleV3/client.v3';
import { SanitizedCoreAppointment } from 'node-api/scheduleV3/types.v3';
import { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import AuthService from 'services/auth-service';
import { ChangeElementTypes, EventHandlerNames, TrackableElement } from 'services/mixpanel-service';
import { EventNames } from 'services/provider-app-mixpanel-events';
import { ProviderAppMixpanelInstance } from 'services/provider-app-mixpanel-service';
import { ClickableElement } from 'services/shared-mixpanel-service';
import { getAppointmentTypeCodes } from 'utils/schedule';
import { CanceledAppointment, ShowCanceledWrapper } from './Category/style';
import {
  AppointmentEntry,
  AppointmentEntryDate,
  AppointmentEntryDateDay,
  AppointmentEntryDescription,
  AppointmentEntryDescriptionDetails,
  AppointmentEntryDescriptionDetailsDuration,
  AppointmentEntryDescriptionTitle,
  AppointmentListMonth,
  AppointmentListTitle,
  AppointmentsContainer,
  AppointmentsList,
  AppointmentsSection,
} from './Schedule/style';
import { Constants } from './utils';

interface AppointmentsProps {
  patient: {
    id: number;
    clinic_id: number;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    last_timezone: string;
  };
}

const MemberUpcomingAppointments: FC<AppointmentsProps> = ({ patient }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showCanceled, setShowCanceled] = useState<boolean>(false);
  const futureAppointmentsSectionRef = useRef<HTMLDivElement>(null);

  const [appointmentsData, setAppointmentsData] = useState<SanitizedCoreAppointment[]>([]);
  const allPastAppointments = appointmentsData.filter((appointment) => !appointment.future);
  const allFutureAppointments = appointmentsData.filter((appointment) => appointment.future);

  const upcomingCanceledAppointmentsCount = allFutureAppointments.filter(
    (appointment) => appointment.canceled
  ).length;
  const pastCanceledAppointmentsCount = allPastAppointments.filter(
    (appointment) => appointment.canceled
  ).length;

  const displayedUpcomingAppointments = allFutureAppointments.filter(
    (appointment) => showCanceled || !appointment.canceled
  );

  const { id: memberId } = patient;

  const scrollToFirstFutureAppointment = () => {
    setTimeout(() => {
      if (futureAppointmentsSectionRef.current) {
        futureAppointmentsSectionRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    }, 100);
  };

  const windowHasFocus = useWindowHasFocus();
  useEffect(() => {
    const source = axios.CancelToken.source();
    const getData = async () => {
      setLoading(true);
      const message = `Couldn't load the member's upcoming and last appointments!`;
      try {
        const appointments = await schedulingV3Client.listAppointments({ memberId }, source.token);
        appointments.reverse();
        if (appointments.filter((appointment) => !appointment.canceled).length === 0) {
          setShowCanceled(true);
        }
        setAppointmentsData(appointments);
        scrollToFirstFutureAppointment();
      } catch (err) {
        if (!axios.isCancel(err)) {
          Toast.show('error', message);
        }
      }
      setLoading(false);
    };

    if (windowHasFocus) {
      getData();
    }

    return () => {
      source.cancel();
    };
  }, [windowHasFocus]);

  const getCheckBox = (canceledAppointmentsCount: number) => (
    <ShowCanceledWrapper
      onClick={() => {
        setShowCanceled((state) => !state);
        scrollToFirstFutureAppointment();
      }}>
      <TrackableElement
        name='Show canceled appointments'
        type={ChangeElementTypes.CHECKBOX}
        value={!showCanceled}
        eventHandlerName={EventHandlerNames.ONCLICK}>
        <CheckBox checked={showCanceled} />
      </TrackableElement>
      Show {canceledAppointmentsCount > 0 ? `${canceledAppointmentsCount} ` : ''}canceled
    </ShowCanceledWrapper>
  );

  const renderAppointment = (
    appointment: SanitizedCoreAppointment,
    options?: { style?: CSSProperties }
  ) => {
    const types = getAppointmentTypeCodes(appointment.appointmentType);
    const enabled = !appointment.canceled;
    const redirectUrl = `/members/${memberId}/appointments/${appointment?.id}?closeTabAfterAction=true`;
    const authToken = AuthService.getAuth()?.Authorization.token;
    const targetUrl = `${MEMBERS_WEB_APP_URL}/self-sign-in/${authToken}?redirect=${encodeURIComponent(
      redirectUrl
    )}`;

    return (
      <a
        href={targetUrl}
        onClick={() => {
          ProviderAppMixpanelInstance.track({
            eventName: EventNames.MemberScheduleViewOpenEventDetail,
            targetLabel: 'member-schedule-appointment-entry',
            targetType: ClickableElement.ENTRY,
            source: 'member-schedule',
          });
        }}
        target='_blank'
        style={enabled ? {} : { pointerEvents: 'none' }}
        rel='noopener noreferrer'>
        <AppointmentEntry
          style={{ ...options?.style, cursor: enabled ? 'pointer' : undefined }}
          key={appointment.id}>
          <AppointmentEntryDate typeColor={types[1]}>
            <div>{moment(appointment.startAtUTC).format('MMM')}</div>
            <AppointmentEntryDateDay>
              {moment(appointment.startAtUTC).format('D')}
            </AppointmentEntryDateDay>
            <div>{moment(appointment.startAtUTC).format('ddd')}</div>
          </AppointmentEntryDate>

          <AppointmentEntryDescription>
            <AppointmentEntryDescriptionTitle>
              {appointment.appointmentType.split('[')[0]?.trim()}
            </AppointmentEntryDescriptionTitle>
            <AppointmentEntryDescriptionDetails>
              {moment(appointment.startAtUTC).format(Constants.DAY_NAME_TIME)}
              {appointment.canceled ? (
                <CanceledAppointment>Canceled</CanceledAppointment>
              ) : (
                <AppointmentEntryDescriptionDetailsDuration>
                  🕐 {Number(appointment.durationMinutes)} Min
                </AppointmentEntryDescriptionDetailsDuration>
              )}
            </AppointmentEntryDescriptionDetails>
          </AppointmentEntryDescription>
        </AppointmentEntry>
      </a>
    );
  };

  let lastMonth = moment(displayedUpcomingAppointments[0]?.startAtUTC || undefined).format(
    Constants.MONTH_NAME_YEAR_FORMAT
  );

  return (
    <AppointmentsContainer>
      {upcomingCanceledAppointmentsCount + pastCanceledAppointmentsCount > 0 ? (
        <div>{getCheckBox(upcomingCanceledAppointmentsCount)}</div>
      ) : null}

      <AppointmentsList style={loading ? { opacity: 0.5 } : {}}>
        {allPastAppointments
          .filter((appointment) => showCanceled || !appointment.canceled)
          .map((appointment) =>
            renderAppointment(appointment, {
              style: { opacity: 0.5, filter: 'grayscale(50%)' },
            })
          )}
        <AppointmentsSection>
          <AppointmentListTitle>
            Upcoming
            {displayedUpcomingAppointments.length
              ? ` (${displayedUpcomingAppointments.length})`
              : ''}
          </AppointmentListTitle>
          <div ref={futureAppointmentsSectionRef} />
          <AppointmentListMonth>
            {lastMonth} (Times in {LOCAL_TIMEZONE?.description})
          </AppointmentListMonth>
        </AppointmentsSection>
        {displayedUpcomingAppointments.length > 0 ? (
          displayedUpcomingAppointments.map((appointment) => {
            const entry = renderAppointment(appointment);
            const thisMonth = moment(appointment.startAtUTC).format(
              Constants.MONTH_NAME_YEAR_FORMAT
            );
            if (lastMonth !== thisMonth) {
              lastMonth = thisMonth;
              return (
                <>
                  <AppointmentsSection>
                    <span />
                    <AppointmentListMonth>
                      {thisMonth} (Times in {LOCAL_TIMEZONE?.description})
                    </AppointmentListMonth>
                  </AppointmentsSection>
                  {entry}
                </>
              );
            }
            return entry;
          })
        ) : (
          <div>No upcoming appointments</div>
        )}
      </AppointmentsList>
      {loading && (
        <Loading
          style={{
            position: 'absolute',
            zIndex: 1,
            right: 0,
            bottom: 0,
            width: 40,
          }}
        />
      )}
    </AppointmentsContainer>
  );
};

export default MemberUpcomingAppointments;
