import { Section, SectionTitle } from 'components/general-styles';
import { WeeklyView } from 'components/main/appointments/WeeklyView';
import { EFeatures } from 'lib/feature/features.types';
import { FC, useContext, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { FeaturesContext } from 'state/contexts/features';
import { ActiveSessions } from './CallsList';

const LOCAL_STORAGE_KEY = 'appointment-summary-mode';

export const AppointmentSummary: FC<RouteComponentProps> = ({ history }) => {
  const { state: featuresState } = useContext(FeaturesContext);
  const canShowWeeklyView = featuresState?.[EFeatures.ProviderLandingShowWeekly];

  const [mode, setMode] = useState<'active-sessions' | 'weekly-view'>(
    (localStorage.getItem(LOCAL_STORAGE_KEY) as 'active-sessions' | 'weekly-view') ||
      'active-sessions'
  );

  const handleToggleMode = () => {
    const targetMode = mode === 'active-sessions' ? 'weekly-view' : 'active-sessions';
    // Store mode in local storage
    localStorage.setItem(LOCAL_STORAGE_KEY, targetMode);
    setMode(targetMode);
  };

  const handleDisplayMember = (member: { uuid: string }) => {
    if (member?.uuid) {
      history.push(`/members/${member.uuid}`);
    }
  };

  if (!canShowWeeklyView) {
    return (
      <Section>
        <SectionTitle>Appointment Summary</SectionTitle>
        <ActiveSessions onDisplayMember={handleDisplayMember} />
      </Section>
    );
  }

  return (
    <Section>
      <SectionTitle onClick={handleToggleMode} style={{ cursor: 'pointer' }}>
        <span style={{ fontWeight: mode === 'active-sessions' ? 'bold' : 'normal' }}>
          Appointment Summary
        </span>{' '}
        |{' '}
        <span style={{ fontWeight: mode === 'weekly-view' ? 'bold' : 'normal' }}>Weekly View</span>
      </SectionTitle>
      {mode === 'active-sessions' ? (
        <ActiveSessions onDisplayMember={handleDisplayMember} />
      ) : (
        <WeeklyView />
      )}
    </Section>
  );
};
