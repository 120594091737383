import CompactFilter, {
  CompactFilterLoading,
  OptionValue,
} from 'components/elements/auto-complete-filter/CompactFilter';
import { Clinic, DashboardFilters, FilterOption } from 'node-api/dashboard.types';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { EventNames } from 'services/provider-app-mixpanel-events';
import { ProviderAppMixpanelInstance } from 'services/provider-app-mixpanel-service';
import { ClickableElement, SelectableElement } from 'services/shared-mixpanel-service';
import { PROVIDER_DASHBOARD } from 'utils/constants';
import { getItemFromLocalStorage, saveOnLocalStorage } from 'utils/helpers';
import {
  CompactFilterSeparator,
  FilterLinkContainer,
  Filters,
  FiltersActions,
  SpanLabel,
} from './style';

export type MetricsFilterProps = {
  filters: DashboardFilters | null;
  membersAmount: number;
  showCompleteViewLink?: boolean;
  onSearch: (panelId: number, programId: number, clinicId: number) => void;
};

const MetricsFilter: FC<MetricsFilterProps> = ({
  filters,
  membersAmount,
  showCompleteViewLink = false,
  onSearch,
}: MetricsFilterProps): JSX.Element => {
  const [selectedClinic, setSelectedClinic] = useState<Clinic | null>(null);
  const [selectedProgram, setSelectedProgram] = useState<FilterOption | null>(null);
  const [selectedPanel, setSelectedPanel] = useState<FilterOption | null>(null);

  const [needsStore, setNeedsStore] = useState(false);

  const storeFiltersInLocalStorage = useCallback(() => {
    const providerDashboardStoredItems = getItemFromLocalStorage(PROVIDER_DASHBOARD);
    saveOnLocalStorage(PROVIDER_DASHBOARD, {
      ...providerDashboardStoredItems,
      filters: { clinic: selectedClinic, program: selectedProgram, panel: selectedPanel },
    });
  }, [selectedClinic, selectedProgram, selectedPanel]);

  const getFiltersFromLocalStorage = () => {
    const {
      filters: storedFilters,
    }: { filters: { clinic: Clinic; program: FilterOption; panel: FilterOption } } =
      getItemFromLocalStorage(PROVIDER_DASHBOARD) ?? {};
    return storedFilters;
  };

  // First time page loads, setting initial filters.
  useEffect(() => {
    if (!filters || selectedClinic) {
      return;
    }
    const storedFilters = getFiltersFromLocalStorage();
    if (!!storedFilters) {
      setSelectedClinic(storedFilters.clinic);
      setSelectedProgram(storedFilters.program);
      setSelectedPanel(storedFilters.panel);
    } else {
      // First time the provider is accessing provider dashboard
      if (filters?.clinics.length) {
        setSelectedClinic(filters.clinics[0]);
      }
      if (filters?.programs.length) {
        setSelectedProgram(filters.programs[0]);
      }
      if (filters?.panels.length) {
        setSelectedPanel(filters.panels[0]);
      }
    }
  }, [filters, onSearch, selectedClinic]);

  // When filters change, we need to perform a search
  useEffect(() => {
    if (filters && selectedClinic && selectedProgram && selectedPanel) {
      onSearch(selectedPanel.id, selectedProgram.id, selectedClinic.id);
    }
  }, [filters, selectedClinic, selectedPanel, selectedProgram, onSearch]);

  // When filters change manually, we need to store them in local storage
  useEffect(() => {
    if (needsStore) {
      storeFiltersInLocalStorage();
      setNeedsStore(false);
    }
  }, [needsStore, storeFiltersInLocalStorage]);

  const clinicOptions: OptionValue[] =
    filters?.clinics?.map((c) => ({
      ...c,
      label: c.id > 0 ? c.name : 'All clinics',
      value: c.id.toString(),
    })) || [];
  const programOptions: OptionValue[] =
    filters?.programs?.map((p) => ({ ...p, label: p.displayValue, value: p.name })) || [];
  const panelOptions: OptionValue[] =
    filters?.panels?.map((p) => ({ ...p, label: p.displayValue, value: p.name })) || [];

  const handleClinicChange = (
    _: React.ChangeEvent<Record<never, never>>,
    option: OptionValue | OptionValue[] | null
  ) => {
    const firstOption = Array.isArray(option) ? option[0] : option;
    const clinic = filters?.clinics.find((c) => c.id === Number(firstOption?.value));
    if (clinic) {
      ProviderAppMixpanelInstance.track({
        eventName: EventNames.MetricsFilterClinicChange,
        targetLabel: 'Clinic',
        targetType: SelectableElement.DROPDOWN,
        targetLocation: 'dashboards bar',
        clinic: clinic.id,
      });

      setSelectedClinic(clinic);
      setNeedsStore(true);
    }
  };

  const handleProgramChange = (
    _: React.ChangeEvent<Record<never, never>>,
    option: OptionValue | OptionValue[] | null
  ) => {
    const firstOption = Array.isArray(option) ? option[0] : option;
    const program = filters?.programs.find((p) => p.name === firstOption?.value);
    if (program) {
      ProviderAppMixpanelInstance.track({
        eventName: EventNames.MetricsFilterProgramChange,
        targetLabel: 'Program',
        targetType: SelectableElement.DROPDOWN,
        targetLocation: 'dashboards bar',
        program: program.name,
      });
      setSelectedProgram(program);
      setNeedsStore(true);
    }
  };

  const handlePanelChange = (
    _: React.ChangeEvent<Record<never, never>>,
    option: OptionValue | OptionValue[] | null
  ) => {
    const firstOption = Array.isArray(option) ? option[0] : option;
    const panel = filters?.panels.find((p) => p.name === firstOption?.value);
    if (panel) {
      ProviderAppMixpanelInstance.track({
        eventName: EventNames.MetricsFilterPanelChange,
        targetLabel: 'Panel',
        targetType: SelectableElement.DROPDOWN,
        targetLocation: 'dashboards bar',
        program: panel.name,
      });
      setSelectedPanel(panel);
      setNeedsStore(true);
    }
  };

  return (
    <FiltersActions showFilters={false}>
      <Filters>
        {filters ? (
          <>
            <CompactFilter
              key='clinic-input'
              id='clinic-input'
              name='clinic'
              label='Clinic '
              placeholder='Select Clinic'
              getData={() => clinicOptions}
              onChange={handleClinicChange}
              value={
                clinicOptions.find((c) => Number(c.value) === selectedClinic?.id) ||
                clinicOptions[0]
              }
            />
            <CompactFilterSeparator />
            <CompactFilter
              key='program-input'
              id='program-input'
              name='program'
              label='Program '
              placeholder='Select Program'
              getData={() => programOptions}
              onChange={handleProgramChange}
              value={
                programOptions.find((p) => p.value === selectedProgram?.name) || programOptions[0]
              }
            />
            <CompactFilterSeparator />
            <CompactFilter
              key='panel-input'
              id='panel-input'
              name='panel'
              label='Panel '
              placeholder='Select Panel'
              getData={() => panelOptions}
              onChange={handlePanelChange}
              value={panelOptions.find((p) => p.value === selectedPanel?.name) || panelOptions[0]}
            />
          </>
        ) : (
          // While we load, let's have placeholder components. Else, React complains about uncontrolled components.
          <>
            <CompactFilterLoading displayLabel='Clinic' />
            <CompactFilterSeparator />
            <CompactFilterLoading displayLabel='Program' />
            <CompactFilterSeparator />
            <CompactFilterLoading displayLabel='Panel' />
          </>
        )}
        <CompactFilterSeparator />
      </Filters>
      <FilterLinkContainer>
        <Link to='/members'>
          <SpanLabel>
            <b>{membersAmount} members</b> in this cohort
          </SpanLabel>
        </Link>

        {showCompleteViewLink && (
          <Link
            to='/dashboards/performance-insights'
            onClick={() =>
              ProviderAppMixpanelInstance.track({
                eventName: EventNames.MetricsCompleteDashboardView,
                targetLabel: 'View complete dashboard',
                targetType: ClickableElement.LINK,
                targetLocation: 'dashboards bar',
              })
            }>
            <SpanLabel>View complete dashboard</SpanLabel>
          </Link>
        )}
      </FilterLinkContainer>
    </FiltersActions>
  );
};

export default MetricsFilter;
