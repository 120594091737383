import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment-timezone';

export const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;

export const Constants = {
  DATE_FORMAT: 'YYYY-MM-DD',
  SHORT_DATETIME_FORMAT: 'YYYY-MM-DD hh:mm',
  FIRST_DAY_FORMAT: 'YYYY-MM-01',
  DATETIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  MM_DD_YYYY_FORMAT: 'MM-DD-YYYY',
  MONTH_FORMAT: 'YYYY-MM',
  MONTH_DAY_FORMAT: 'MM-DD',
  MMM_DAY_FORMAT: 'MMM DD',
  FULL_DATE_WITH_TIMEZONE_FORMAT: 'dddd, MMMM DD [at] hh:mm A ([GMT]Z)',
  OFFSET_FORMAT: '[GMT]Z',
  DAY_NAME_TIME: 'dddd h:mm A',
  TIME_WITH_TIMEZONE_FORMAT: 'hh:mm A',
  TIME_WITH_ABBREVIATED_TIMEZONE_FORMAT: 'hh:mm A z',
  TIMEZONE_FORMAT: 'zz',
  TIMEZONE_ABBREVIATED_FORMAT: 'z',
  MONTH_NAME_YEAR_FORMAT: 'MMMM YYYY',
};

export const today = () => moment();

export const daysDiff = (date: MaterialUiPickersDate) => {
  return Math.round((moment(date) - today()) / MILLISECONDS_IN_DAY);
};

export const displayTime = (timeWithOffset: string, timezone: string) => {
  const date = moment.tz(timeWithOffset, timezone);
  return date.format(Constants.TIME_WITH_ABBREVIATED_TIMEZONE_FORMAT);
};

export const displayDateTimeWithOffset = (datetimeWithOffset: string) => {
  const dateTime = moment.parseZone(datetimeWithOffset);
  return dateTime.format(Constants.FULL_DATE_WITH_TIMEZONE_FORMAT);
};

export const humanReadableDate = (daysFromToday: number) => {
  switch (daysFromToday) {
    case 0:
      return `today ${moment().format(Constants.MONTH_DAY_FORMAT)}`;
    case 1:
      return `tomorrow ${moment().add(1, 'days').format(Constants.MONTH_DAY_FORMAT)}`;
    default:
      return `${moment().add(daysFromToday, 'days').format(Constants.MM_DD_YYYY_FORMAT)}`;
  }
};
