import api from 'api';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import useForm from 'react-hook-form';
import * as yup from 'yup';

import { PatientContext } from 'state/contexts/patient';
import { PatientTagsContext } from 'state/contexts/patientTags';
import { TagContext } from 'state/contexts/tag';

import { AutoComplete, Button, Modal } from 'components/elements';
import { debug } from 'utils/helpers';
import { Actions, Header, Item, Record } from './style';

const schema = yup.object().shape({
  tag: yup.string().required('A tag is required'),
});

const AddTag = ({ onClose }) => {
  const { state: tagState } = useContext(TagContext);
  const { state: patientState } = useContext(PatientContext);
  const { dispatch: patientTagsDispatch } = useContext(PatientTagsContext);

  const [patient] = useState(patientState.data.find((p) => p.current));
  const [tags] = useState(tagState.data);
  const [selectedTag, setSelectedTag] = useState();

  const { handleSubmit, register, errors, formState, setError, reset } = useForm({
    validationSchema: schema,
  });

  const submit = async () => {
    // Add the selected tag to the current patient
    try {
      const {
        data: { data },
      } = await api().post(`/patients/${patient.uuid}/patient_tags`, {
        auth_token: 'AUTH_TOKEN',
        id: selectedTag.id,
      });

      patientTagsDispatch({
        type: 'SET',
        payload: {
          patientId: patient.id,
          data,
        },
      });

      return onClose();
    } catch (err) {
      debug(err);
      setError('tag', {
        type: 'manual',
        message:
          err.response?.status === 422
            ? 'This tag already exists on the patient profile!'
            : 'There was an error, please try again later.',
      });
    }
  };

  return (
    <Modal onClose={onClose}>
      <form onSubmit={handleSubmit(submit)}>
        <Header>
          <h2>Add New Tag</h2>
          <p>Add new tag to the patient profile</p>
        </Header>

        <AutoComplete
          id='tag-input'
          name='tag'
          placeholder='Type your new Tag'
          errors={errors.tag}
          label='Tag'
          register={register}
          getData={() => tags}
          onChange={(e, opt) => {
            if (reset) {
              reset();
            }
            setSelectedTag(opt);
          }}
          renderOption={(opt) => (
            <Record key={`option-${opt.id}`}>
              <Item>{opt.name}</Item>
            </Record>
          )}
        />

        <Actions>
          <Button variant='link' onClick={onClose}>
            Cancel action
          </Button>
          <Button type='submit' submitting={formState.isSubmitting}>
            Add Tag
          </Button>
        </Actions>
      </form>
    </Modal>
  );
};

AddTag.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddTag;
