import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';
import { Action, DataType, initialState, reducer } from '../reducers/clinic';

const ClinicContext = createContext<{ state: DataType; dispatch: Dispatch<Action> }>({
  state: initialState,
  dispatch: () => null,
});

const ClinicProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <ClinicContext.Provider value={{ state, dispatch }}>{children}</ClinicContext.Provider>;
};

export { ClinicContext, ClinicProvider };
