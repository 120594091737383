import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Typography } from '@mui/material';
import { MeetingSummaryStatus } from 'node-api/scribe/Scribe.types';
import { NoteDetail } from '../../notes.type';
import { renderSectionsByOutputType } from './section';

export type Props = {
  note: NoteDetail;
  handleMarkAsReviewed: (status: MeetingSummaryStatus.REVIEWED) => void;
};

export const NoteEditionPreview = ({ note, handleMarkAsReviewed }: Props) => {
  return (
    <Box className='note-edition-preview-content'>
      <Box className='note-preview-content-header'>
        <Typography
          component='h4'
          fontWeight={600}
          color='#353535'
          sx={{
            fontSize: '18px !important',
          }}>
          Preview
        </Typography>

        <Button
          size='small'
          startIcon={<CheckIcon />}
          onClick={() => handleMarkAsReviewed(MeetingSummaryStatus.REVIEWED)}
          style={{
            fontFamily: 'Proxima Nova Rg',
            color: '#1a667a',
            textTransform: 'capitalize',
            fontWeight: 500,
            fontSize: '1em',
            padding: 0,
          }}>
          Mark as reviewed and copy
        </Button>
      </Box>

      {note.content
        ?.sort((a, b) => a.order - b.order)
        .map((section) => {
          return renderSectionsByOutputType(section.outputType, section);
        })}

      <Typography textAlign='justify'>
        The remaining problems were not addressed today. During this encounter, I reviewed the data
        point mentioned in the clinical note, which included face to face and time spent placing a
        referral prescribing medication reviewing heart rate log documenting the visit
      </Typography>

      <Typography textAlign='justify'>
        I also additionally addressed the following problems: Lack of exercise Based on CMS
        definitions, I estimate that risk of treatment and diagnostic plan is Moderate.
      </Typography>

      <Box mt={2} mb={2}>
        <Typography>Originating site: {note.meeting.rawAppointment.originSite}</Typography>
        <Typography>Distant site: {note.meeting.rawAppointment.clinicName}</Typography>
        <Typography>Time started: {note.meeting.rawAppointment.startTime}</Typography>
        <Typography>Time ended: {note.meeting.rawAppointment.endTime}</Typography>
      </Box>

      <Typography textAlign='justify'>
        The patient initiated the telehealth visit by accessing the Enara app and patiently waiting
        for the scheduled appointment. Upon receiving the automatically provided video link, the
        patient clicked on it and remained in the virtual waiting room until I became available and
        logged into the link, thereby initiating the consultation. Telemedicine is an appropriate
        and effective means of providing clinical care to patients with the medical condition(s)
        described within the documentation of this telemedicine visit.
      </Typography>
    </Box>
  );
};
