/* eslint-disable @typescript-eslint/no-explicit-any */
import { MIXPANEL } from 'config';
import mixpanel from 'mixpanel-browser';
import React, { FC, ReactNode } from 'react';

mixpanel.init(MIXPANEL.key, {}, 'private');

export enum EventHandlerNames {
  ONCLICK = 'onClick',
  ONCHANGE = 'onChange',
}

export enum ClickElementTypes {
  LINK = 'Link',
  BUTTON = 'Button',
  TAB = 'Tab',
  IMAGE = 'Image',
  ACCORDION = 'Accordion',
}

export enum ChangeElementTypes {
  DROPDOWN = 'Dropdown',
  CHECKBOX = 'Checkbox',
  MATERIAL_DATE_PICKER = 'Material date picker',
}

export interface TrackProps {
  customEventName?: string;
  name: string;
  source?: string;
  [key: string]: any;
}

interface TrackClickProps extends TrackProps {
  type: ClickElementTypes;
  inputData?: { [key: string]: any };
}

interface TrackChangeProps extends TrackProps {
  type: ChangeElementTypes;
  value: string | number | boolean;
}

const MixpanelService = {
  identify(id: number) {
    mixpanel.private.identify(id);
  },

  alias(id: number) {
    mixpanel.private.alias(id);
  },

  set(props: any) {
    mixpanel.private.people.set(props);
  },

  track(eventName: string, props?: any) {
    mixpanel.private.track(eventName, props);
  },

  trackClick({ customEventName, name, type, source, inputData, ...restProps }: TrackClickProps) {
    this.track(customEventName || `Clicked ${name}`, {
      'Target Name': name,
      'Target Type': type,
      Source: source,
      'Input Data': inputData,
      ...restProps,
    });
  },

  trackChange({ customEventName, name, type, source, value, ...restProps }: TrackChangeProps) {
    this.track(customEventName || `Changed ${name}`, {
      'Field Name': name,
      'Field Type': type,
      Source: source,
      'Field Value': value,
      ...restProps,
    });
  },
};

export const DATE_FORMAT = 'YYYY-MM-DD';

export interface TrackableElementProps extends React.PropsWithChildren {
  customEventName?: string;
  name: string;
  type: ClickElementTypes | ChangeElementTypes;
  value?: string | number | boolean;
  source?: string;
  eventHandlerName: EventHandlerNames;
  [key: string]: any;
}

export const TrackableElement: FC<TrackableElementProps> = ({
  children,
  customEventName,
  name,
  type,
  value,
  source,
  eventHandlerName,
  ...extraProps
}) => {
  const updatedChildren = React.Children.map<ReactNode, ReactNode>(children, (child) => {
    if (React.isValidElement(child)) {
      // eslint-disable-next-line dot-notation
      const actualEventHandler = child.props[eventHandlerName];
      if (typeof actualEventHandler === 'function') {
        return React.cloneElement(child, {
          [eventHandlerName]: (e: any, p: any) => {
            actualEventHandler(e, p);

            if (type === ChangeElementTypes.DROPDOWN || type === ChangeElementTypes.CHECKBOX) {
              MixpanelService.trackChange({
                customEventName,
                name,
                type,
                source,
                value: value ?? p?.text,
                ...extraProps,
              });
            } else {
              MixpanelService.trackClick({
                customEventName,
                name,
                type: type as ClickElementTypes,
                source,
                ...extraProps,
              });
            }
          },
        });
      }
    }
    return child;
  });

  return <>{updatedChildren}</>;
};

export default MixpanelService;
