import { EventClickArg, EventContentArg, EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import Popover from 'components/elements/popover/Popover';
import { Box } from 'components/general-styles';
import Toast from 'components/toast';
import { ALLOWED_TIMEZONES, LOCAL_TIMEZONE } from 'lib/timezone';
import moment from 'moment';
import AppointmentsClient, { ScheduledAppointment } from 'node-api/schedule/AppointmentsClient';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import { EventNames } from 'services/provider-app-mixpanel-events';
import { ProviderAppMixpanelInstance } from 'services/provider-app-mixpanel-service';
import { ClickableElement } from 'services/shared-mixpanel-service';
import { UserContext } from 'state/contexts/user';
import { getAppointmentTypeCodes, getAppointmentWeeklyWindow } from 'utils/schedule';
import './WeeklyView.css';
import { SchedulePreviewCard } from './components/SchedulePreviewCard';

const LOCAL_STORAGE_TIMEZONE_KEY = 'calendar-timezone';

function parseAppointmentToCalendarEvent(appointment: ScheduledAppointment): EventInput {
  const memberFullName = [appointment.user.firstName, appointment.user.lastName].join(' ');
  return {
    // Base properties required by the package
    id: `${appointment.id}`,
    title: memberFullName,
    start: new Date(appointment.startAt),
    end: new Date(appointment.endAt),
    // Extended props
    extendedProps: appointment,
  };
}

function renderEventContent(eventInfo: EventContentArg, selectedEvent: EventImpl | null) {
  const { event, timeText } = eventInfo;
  const appointment = event.extendedProps as ScheduledAppointment;
  const types = getAppointmentTypeCodes(appointment.appointmentType);
  return (
    <div
      className={[
        'event-content',
        `intention-${types[0]}`,
        `specialty-${types[1]}`,
        `location-${types[2]}`,
        selectedEvent?.id === event.id ? 'selected' : '',
      ].join(' ')}>
      <span className='event-member'>{event.title}</span>
      <span className='event-time'>
        {timeText} <b>{types[2]}</b>
      </span>
    </div>
  );
}

export const WeeklyView: FC<RouteProps> = () => {
  const { state: userState } = useContext(UserContext);
  const weeklyViewRef = useRef<FullCalendar>(null);
  const [timezone, setTimezone] = useState(
    LOCAL_TIMEZONE ||
      ALLOWED_TIMEZONES.find(
        (tz) => tz.value === localStorage.getItem(LOCAL_STORAGE_TIMEZONE_KEY)
      ) ||
      ALLOWED_TIMEZONES[0]
  );
  const [appointments, setAppointments] = useState<ScheduledAppointment[]>([]);
  // Calendar start and end times
  const [dayStartTime, setDayStartTime] = useState<string>('08:00');
  const [dayEndTime, setDayEndTime] = useState<string>('19:00');
  const [startDate] = useState<moment.Moment>(moment().weekday(0));
  const [hiddenDays, setHiddenDays] = useState<number[]>([0, 6]);

  const [selectedEventElement, setSelectedEventElement] = useState<HTMLElement | null>(null);
  const [selectedEventObject, setSelectedEventObject] = useState<EventImpl | null>(null);

  const timezoneButtonLabel = `Timezone: ${timezone.description}`;

  useEffect(() => {
    // Load the appointments scheduled for the current week
    if (!userState?.id) {
      return;
    }
    const providerId = userState.id;

    const endDate = startDate.clone().add(7 + 7, 'days');
    (async () => {
      const scheduleServiceClient = new AppointmentsClient();
      try {
        const { appointments: scheduledAppointments } =
          await scheduleServiceClient.getScheduledAppointmentsForProvider(providerId, {
            endDate: endDate.toDate(),
            startDate: startDate.toDate(),
          });

        setAppointments(scheduledAppointments);
      } catch (err) {
        Toast.show('error', 'Could not get scheduled appointments');
      }
    })();
  }, [userState?.id, startDate]);

  useEffect(() => {
    if (appointments && appointments.length > 0) {
      const {
        startTime,
        endTime,
        hiddenDays: _hiddenDays,
      } = getAppointmentWeeklyWindow(appointments, timezone.value);
      setDayStartTime(startTime);
      setDayEndTime(endTime);
      setHiddenDays(_hiddenDays);
    }
  }, [appointments, timezone.value]);

  const handleRotateTimezone = () => {
    ProviderAppMixpanelInstance.track({
      eventName: EventNames.WeeklyViewRotateTimezone,
      targetLabel: timezoneButtonLabel,
      targetType: ClickableElement.BUTTON,
      source: 'weekly-view-toolbar',
    });

    const currentIndex = ALLOWED_TIMEZONES.findIndex((tz) => tz.value === timezone.value);
    const nextIndex = currentIndex + 1 >= ALLOWED_TIMEZONES.length ? 0 : currentIndex + 1;
    // Store the new timezone in local storage
    localStorage.setItem(LOCAL_STORAGE_TIMEZONE_KEY, ALLOWED_TIMEZONES[nextIndex].value);
    setTimezone(ALLOWED_TIMEZONES[nextIndex]);
  };

  const handleClickEvent = (arg: EventClickArg) => {
    ProviderAppMixpanelInstance.track({
      eventName: EventNames.WeeklyViewPreviewEvent,
      targetLabel: 'weekly-view-entry',
      targetType: ClickableElement.ENTRY,
      source: 'weekly-view',
    });

    setSelectedEventElement(arg.el);
    setSelectedEventObject(arg.event);
  };

  const handleClosePreviewCard = () => {
    ProviderAppMixpanelInstance.track({
      eventName: EventNames.ModalClose,
      targetLabel: 'no-label',
      targetType: ClickableElement.BACKGROUND,
      source: 'weekly-view-preview-card',
    });

    setSelectedEventElement(null);
    setSelectedEventObject(null);
  };

  return (
    <div className='calendar-container'>
      <Box className='calendar-box'>
        <FullCalendar
          // Hack that forces the calendar to re-render when the timezone changes
          key={`weekly-view-${timezone.value}`}
          ref={weeklyViewRef}
          // Styling
          eventClassNames={['calendar-event']}
          viewClassNames={['calendar-view']}
          // Main
          headerToolbar={{
            left: 'today prev,next',
            center: 'title',
            right: 'timezone',
          }}
          buttonText={{ today: 'Today' }}
          plugins={[timeGridPlugin, momentTimezonePlugin]}
          timeZone={timezone.value}
          initialView='timeGridWeek'
          // content
          events={appointments.map(parseAppointmentToCalendarEvent)}
          eventContent={(content) => renderEventContent(content, selectedEventObject)}
          // timegrid display
          height='calc(100vh - 160px)'
          allDaySlot={false}
          slotDuration='00:30:00'
          slotLabelInterval='01:00'
          slotMinTime={dayStartTime}
          slotMaxTime={dayEndTime}
          hiddenDays={hiddenDays}
          // Interaction
          eventClick={handleClickEvent}
          // Custom actions
          customButtons={{
            timezone: {
              text: timezoneButtonLabel,
              click: handleRotateTimezone,
            },
          }}
          // eslint-disable-next-line react/jsx-boolean-value
          nowIndicator={true}
        />
        <Popover
          anchorEl={selectedEventElement}
          anchorOrigin={{ vertical: 'bottom', horizontal: 20 }}
          onClose={handleClosePreviewCard}>
          {selectedEventObject?.extendedProps && (
            <SchedulePreviewCard
              appointment={selectedEventObject.extendedProps as ScheduledAppointment}
              timezone={timezone.value}
            />
          )}
        </Popover>
      </Box>
    </div>
  );
};
