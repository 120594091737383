import { AdvancedFeatures, DEFAULT_FEATURES, EFeatures } from 'lib/feature/features.types';
import { Reducer } from 'react';
import { Action as DefaultAction } from 'state/types';

export enum ActionTypes {
  SET = 'SET',
}

export type FeaturesState = {
  [key in EFeatures]: boolean;
} & {
  advanced: {
    // TODO: improve for more advanced feature using a T value
    [AdvancedFeatures.ProviderAllowedNewVideoCall]: number[] | boolean;
  };
};

type Payload = {
  [ActionTypes.SET]: FeaturesState;
};

export type Action = DefaultAction<Payload>;

export const initialState: FeaturesState = DEFAULT_FEATURES;

export const reducer: Reducer<FeaturesState, Action> = (
  state: FeaturesState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionTypes.SET: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};
