import { Link } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { ILegacyMyPanelResponseEntry } from 'node-api/panel/MyPanelLegacyClient.types';
import { EventNames } from 'services/provider-app-mixpanel-events';
import { ProviderAppMixpanelInstance } from 'services/provider-app-mixpanel-service';
import { ClickableElement } from 'services/shared-mixpanel-service';
import { getMemberProfileUrl } from 'utils/members';
import { PanelAvatarWithBadges } from './components/PanelAvatarWithBadges';
import { getActiveAttentionKeys, PanelRow, PanelSettings } from './types';

const trackViewProfile = (
  member: ILegacyMyPanelResponseEntry,
  source: string,
  eventName: EventNames = EventNames.MemberProfileOpen
) => {
  ProviderAppMixpanelInstance.track({
    eventName,
    targetLabel: 'View Profile',
    targetType: ClickableElement.LINK,
    'member-id': member.uuid,
    source,
  });
};

export const getColumnConfiguration = (settings: PanelSettings): GridColDef<PanelRow>[] => [
  {
    field: 'avatar',
    headerName: '#',
    width: 60,
    valueGetter: ({ row }) => getActiveAttentionKeys(row.attention, settings)?.length, // Sorts by the number of attentions
    renderCell: ({ row: { attention, member } }) => (
      <div
        style={{
          marginLeft: -5,
          display: 'flex',
          // space between avatar and open in new window icon
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Link
          href={getMemberProfileUrl(member)}
          onClick={() => trackViewProfile(member, 'panel-member-avatar')}>
          <PanelAvatarWithBadges settings={settings} attention={attention} member={member} />
        </Link>
      </div>
    ),
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    valueGetter: ({ row }) => `${row.member.last_name}, ${row.member.first_name}`,
  },
  {
    field: 'clinic_name',
    headerName: 'Clinic',
    width: 200,
    valueGetter: ({ row }) => row.clinic?.name || `Clinic ${row.member.clinic_id}`,
    filterable: false,
  },
  {
    field: 'patient_status',
    headerName: 'Status',
    width: 100,
    valueGetter: ({ row }) => row.member.patient_status || '',
    filterable: false,
  },
  {
    field: 'current_program',
    headerName: 'Program',
    width: 150,
    valueGetter: ({ row }) => row.member.current_program,
  },
  {
    field: 'program_start_days_ago',
    headerName: 'In Program',
    width: 150,
    align: 'right',
    valueGetter: ({ row }) => Number(row.member.program_start_days_ago || 0),
    renderCell: ({ row }) =>
      row.member.current_program
        ? row.member.program_start_days_ago <= 90
          ? `${row.member.program_start_days_ago} Days (Mo ${(
              row.member.program_start_days_ago / 30
            ).toFixed(0)})`
          : `Month ${Math.ceil(row.member.program_start_days_ago / 30.0).toFixed(0)}`
        : '',
  },
  {
    field: 'percent_weightloss',
    headerName: 'Weight Loss',
    align: 'right',
    valueGetter: ({ row }) => Number(row.member.percent_weightloss),
    renderCell: ({ row }) =>
      row.member.percent_weightloss ? `${row.member.percent_weightloss.toFixed(0)}%` : '',
  },
];
