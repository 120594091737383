import { Box, Typography } from '@mui/material';
import { NoteExcerpt, ProviderNoteData } from 'node-api/scribe/Scribe.types';
import { useEffect, useState } from 'react';
import { getFormattedDateFromISOString } from 'utils/dateHelpers';
import { NoteListItem } from './NoteListItem';

export type NotesListProps = {
  notes: { [date: string]: (NoteExcerpt & ProviderNoteData)[] };
  currentDate: string | null;
  showAllNotes: boolean;
};

export const NotesList = ({ notes, currentDate, showAllNotes }: NotesListProps) => {
  const [notesByMonth, setNotesByMonth] = useState<[string, (NoteExcerpt & ProviderNoteData)[]][]>(
    []
  );

  useEffect(() => {
    setNotesByMonth(Object.entries(notes));
  }, [notes]);

  if (notesByMonth.length === 0) {
    return (
      <Typography
        display='flex'
        alignItems='center'
        justifyContent='center'
        width='100%'
        height='100%'>
        There are no notes for the selected date or month
      </Typography>
    );
  }

  return (
    <Box>
      {notesByMonth.map(([date, notesByDate]) => {
        const selectedDate = currentDate?.substring(0, 10);
        const month = currentDate?.substring(0, 7);

        return (
          (date === selectedDate || (showAllNotes && month && date?.includes(month))) && (
            <Box className='note-section' key={date}>
              <Box
                className='note-section-title note-section-element-row'
                display='flex'
                justifyContent='space-between'>
                <Typography fontWeight={500}>
                  {getFormattedDateFromISOString(date, 'MMMM DD, YYYY')}
                </Typography>
                <Typography fontWeight={500}>
                  {' '}
                  {getFormattedDateFromISOString(date, 'dddd')}
                </Typography>
              </Box>

              <Box className='note-section-list'>
                {notesByDate.map((noteExcerpt) => {
                  return <NoteListItem noteExcerpt={noteExcerpt} />;
                })}
              </Box>
            </Box>
          )
        );
      })}
    </Box>
  );
};
