import { WarningRounded } from '@mui/icons-material';
import { Box, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Toast from 'components/toast';
import { AppointmentTypeWithProviders, User } from 'node-api/schedule/Appointments.types';
import AppointmentsClient from 'node-api/schedule/AppointmentsClient';
import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { PatientContext } from 'state/contexts/patient';
import { AppointmentsWrapper } from '../Category/style';
import { ScheduleSelectedAppointment } from '../ScheduleAppointment/ScheduleSelectedAppointment';
import { WarningBox, WarningInformation, WarningSpan } from '../style';

type Props = {
  patient: {
    id: number;
    clinic_id: number;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    last_timezone: string;
  };
};

const GroupClassScheduler: FC<Props> = ({ patient }) => {
  const [inputAppointmentType, setInputAppointmentType] = useState<string>('');
  const [inputProvider, setInputProvider] = useState<string>('');
  const [selectedProvider, setSelectedProvider] = useState<User | null>(null);
  const [selectedGroupClassAppointment, setSelectedGroupClassAppointment] =
    useState<AppointmentTypeWithProviders | null>(null);
  const [allGroupClassAppointmentTypes, setAllGroupClassAppointmentTypes] = useState<
    AppointmentTypeWithProviders[]
  >([]);

  const { state: patientState } = useContext(PatientContext);

  useEffect(() => {
    const currentPatient = patientState.data.filter((p) => !!p.current)[0];

    if (!currentPatient) {
      return;
    }

    (async () => {
      try {
        const groupClassAppointments =
          await new AppointmentsClient().getAvailableGroupClassAppointments(currentPatient.uuid);

        setAllGroupClassAppointmentTypes(groupClassAppointments);
      } catch (error) {
        Toast.show('error', 'Could not load group class appointments!');
      }
    })();
  }, [patientState.data]);

  const renderAppointmentsScheduler = (): ReactNode => {
    if (!selectedGroupClassAppointment) {
      return null;
    }

    const { providers } = selectedGroupClassAppointment;

    if (providers.length === 0) {
      return (
        <WarningBox>
          <WarningInformation>
            <WarningSpan>
              <WarningRounded fontSize='large' />
            </WarningSpan>
            <b>There are no providers assigned to this group class!</b>
          </WarningInformation>
        </WarningBox>
      );
    }

    if (!selectedProvider) {
      return null;
    }

    return (
      <Box>
        <ScheduleSelectedAppointment
          appointmentType={selectedGroupClassAppointment}
          provider={selectedProvider}
          patient={patient}
          isMainProvider={false}
        />
      </Box>
    );
  };

  return (
    <AppointmentsWrapper>
      <div className='schedule-tab__new-box__selectors'>
        <Box className='schedule-tab__new-box__selectors__column'>
          <Autocomplete
            value={selectedGroupClassAppointment}
            onChange={(_, newValue: AppointmentTypeWithProviders | null) => {
              if (newValue) {
                setSelectedGroupClassAppointment(newValue);
                setSelectedProvider(null);
              }
            }}
            inputValue={inputAppointmentType}
            onInputChange={(_, newInputValue) => setInputAppointmentType(newInputValue)}
            options={allGroupClassAppointmentTypes}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} variant='outlined' label='Group Class' />
            )}
          />
        </Box>
        <Box className='schedule-tab__new-box__selectors__column'>
          <Autocomplete
            value={selectedProvider}
            onChange={(_, newValue: User | null) => {
              if (newValue) {
                setSelectedProvider(newValue);
              }
            }}
            inputValue={inputProvider}
            onInputChange={(_, newInputValue) => setInputProvider(newInputValue)}
            options={selectedGroupClassAppointment?.providers || []}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
            renderInput={(params) => <TextField {...params} variant='outlined' label='Provider' />}
          />
        </Box>
      </div>

      {renderAppointmentsScheduler()}
    </AppointmentsWrapper>
  );
};

export default GroupClassScheduler;
