import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';
import { initialState, reducer } from '../reducers/notification';

const NotificationContext = createContext(initialState);

const NotificationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <NotificationContext.Provider value={{ state, dispatch }}>
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { NotificationContext, NotificationProvider };
