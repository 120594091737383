import { EFeatures } from 'lib/feature/features.types';
import BloodPressureChart from './blood-pressure-chart';
import CardChart from './card-chart';
import GlucoseChart from './glucose-chart';
import HeartRateChart from './heart-rate-chart';
import { MedicationHistoryChart } from './medication-history/MedicationHistoryChart';
import StepChart from './step-chart';
import { GraphTab } from './types';
import VitalsTable from './vitals/VitalsTable';
import WeightChart from './weight-chart';

export enum Graphs {
  Weights = 'weights',
  HeartRate = 'heart_rate',
  Glucose = 'glucose',
  Steps = 'steps',
  BloodPressure = 'blood_pressure',
  Card = 'card',
  Exercises = 'exercises',
  Vitals = 'vitals',
  Notes = 'notes',
}

export const GRAPH_TABS: GraphTab[] = [
  {
    name: Graphs.Weights,
    label: 'Weights',
    notificationsFieldName: 'health_data_weights',
    componentFunction: WeightChart,
  },
  {
    name: Graphs.HeartRate,
    label: 'Heart Rate',
    notificationsFieldName: 'health_data_heart_rates',
    componentFunction: HeartRateChart,
  },
  {
    name: Graphs.Glucose,
    label: 'Glucose',
    notificationsFieldName: 'health_data_glucoses',
    componentFunction: GlucoseChart,
  },
  {
    name: Graphs.Steps,
    label: 'Steps',
    notificationsFieldName: 'health_data_steps',
    componentFunction: StepChart,
  },
  {
    name: Graphs.BloodPressure,
    label: 'Blood Pressure',
    notificationsFieldName: 'health_data_blood_pressures',
    componentFunction: BloodPressureChart,
  },
  {
    name: 'exercises',
    label: 'Exercises',
    notificationsFieldName: 'exercise',
    tabReviewedByContent: true,
    notificationFeatureFlag: EFeatures.ProviderShowExerciseNotifications,
    componentFunction: null,
  },
  {
    name: 'medication',
    label: 'Medication History',
    componentFunction: MedicationHistoryChart,
    displayFeatureFlag: EFeatures.ProviderProfileChartMedicationHistory,
  },
  {
    name: Graphs.Card,
    label: 'Card',
    componentFunction: CardChart,
  },
  {
    name: Graphs.Vitals,
    label: 'Vitals',
    componentFunction: VitalsTable,
    displayFeatureFlag: EFeatures.ProviderShowVitals,
  },
];
