import { Close } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, IconButton, Typography } from '@mui/material';
import Loader from 'components/elements/loading/Loader';
import Toast from 'components/toast';
import { MeetingSummaryStatus, NoteExcerpt } from 'node-api/scribe/Scribe.types';
import ScribeClient from 'node-api/scribe/ScribeClient';
import { useEffect, useState } from 'react';
import { getFormattedDateFromISOString } from 'utils/dateHelpers';
import '../css/notes.css';
import { NoteDetail } from '../notes.type';
import './css/note-edition.css';
import './css/note-preview.css';
import './css/note.css';
import { NoteEdition } from './NoteEdition/NoteEdition';
import { renderSectionsByOutputType } from './NoteEditionPreview/section';

type Props = {
  noteExcerpt: NoteExcerpt;
  onClosePreview: () => void;
};

export const NotePreview = ({ noteExcerpt, onClosePreview }: Props) => {
  const [note, setNote] = useState<NoteDetail | null>(null);
  const [canEditNote, setCanEditNote] = useState<boolean>(false);

  const handleMarkAsReviewed = async () => {
    const client = new ScribeClient();

    const response = await client.updateSummary(noteExcerpt.meetingSummary.id, {
      status: MeetingSummaryStatus.REVIEWED,
    });

    if (!response.status || !response.note) {
      Toast.show('error', 'There was an error updating note');
      return;
    }

    setNote(response.note);
    Toast.show('success', 'Note updated successfully');
  };

  const handleEditNote = () => setCanEditNote(!canEditNote);

  useEffect(() => {
    (async () => {
      const client = new ScribeClient();

      const response = await client.getSummary(noteExcerpt.meetingSummary.id);

      if (!response) {
        Toast.show('error', 'There was an error obtaining the note');
        return;
      }

      setNote(response);
    })();
  }, []);

  return note && canEditNote ? (
    <NoteEdition
      note={note}
      onCloseNoteEdition={() => {
        setCanEditNote(!canEditNote);
        onClosePreview();
      }}
    />
  ) : (
    <Box className='note-preview-container'>
      {!note ? (
        <Loader
          style={{
            alignContent: 'center',
            justifyContent: 'center',
            margin: '25% 0',
          }}
        />
      ) : (
        <>
          <Box className='note-preview-header'>
            <Box display='flex' alignItems='center'>
              <Box className='note-list-row-icon' mr={1} />
              <Typography
                mr={1}
                sx={{
                  color: '#404040',
                  opacity: '60%',
                }}>{`${note.meetingType.internalCode} ${getFormattedDateFromISOString(
                note.meeting.createdAt,
                'h:mma'
              )}`}</Typography>
              <Typography
                color='#323648'
                mr={
                  1
                }>{`${note.provider.firstName} ${note.provider.lastName}, ${note.provider.specialty}`}</Typography>
            </Box>

            <IconButton size='small' onClick={() => onClosePreview()}>
              <Close />
            </IconButton>
          </Box>

          <Box className='note-preview-content-container'>
            <Box className='note-preview-content-header' mr={1}>
              <Typography
                component='h4'
                fontWeight={600}
                color='#353535'
                sx={{
                  fontSize: '18px !important',
                }}>
                Review Notes
              </Typography>
              <Box>
                <Button
                  variant='text'
                  startIcon={<EditIcon />}
                  onClick={handleEditNote}
                  style={{
                    color: '#1a667a',
                    textTransform: 'capitalize',
                    fontWeight: 500,
                    display:
                      note.meetingSummary.status === MeetingSummaryStatus.REVIEWED
                        ? 'none'
                        : 'initial',
                  }}>
                  Edit
                </Button>
                <Button
                  variant='text'
                  disabled={note.meetingSummary.status === MeetingSummaryStatus.REVIEWED}
                  startIcon={<CheckIcon />}
                  onClick={handleMarkAsReviewed}
                  style={{
                    fontFamily: 'Proxima Nova Rg',
                    color: '#1a667a',
                    textTransform: 'capitalize',
                    fontWeight: 500,
                  }}>
                  {note.meetingSummary.status === MeetingSummaryStatus.REVIEWED
                    ? 'Marked as reviewed'
                    : 'Mark as reviewed and copy'}
                </Button>
              </Box>
            </Box>

            {note.content
              ?.sort((a, b) => a.order - b.order)
              ?.map((section) => {
                return renderSectionsByOutputType(section.outputType, section);
              })}

            <Box>
              <Typography textAlign='justify'>
                The remaining problems were not addressed today. During this encounter, I reviewed
                the data point mentioned in the clinical note, which included face to face and time
                spent placing a referral prescribing medication reviewing heart rate log documenting
                the visit
              </Typography>

              <Typography textAlign='justify'>
                I also additionally addressed the following problems: Lack of exercise Based on CMS
                definitions, I estimate that risk of treatment and diagnostic plan is Moderate.
              </Typography>

              <Box mt={2} mb={2}>
                <Typography>Originating site: {note.meeting.rawAppointment.originSite}</Typography>
                <Typography>Distant site: {note.meeting.rawAppointment.clinicName}</Typography>
                <Typography>Time started: {note.meeting.rawAppointment.startTime}</Typography>
                <Typography>Time ended: {note.meeting.rawAppointment.endTime}</Typography>
              </Box>

              <Typography textAlign='justify'>
                The patient initiated the telehealth visit by accessing the Enara app and patiently
                waiting for the scheduled appointment. Upon receiving the automatically provided
                video link, the patient clicked on it and remained in the virtual waiting room until
                I became available and logged into the link, thereby initiating the consultation.
                Telemedicine is an appropriate and effective means of providing clinical care to
                patients with the medical condition(s) described within the documentation of this
                telemedicine visit.
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
