import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';
import { initialState, reducer } from '../reducers/patientSummary';

const PatientSummaryContext = createContext(initialState);

const PatientSummaryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <PatientSummaryContext.Provider value={{ state, dispatch }}>
      {children}
    </PatientSummaryContext.Provider>
  );
};

PatientSummaryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PatientSummaryContext, PatientSummaryProvider };
