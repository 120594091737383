import { Reducer } from 'react';
import { Action as DefaultAction } from 'state/types';

export enum ActionTypes {
  CLEAR = 'CLEAR',
  CLEAR_MESSAGE = 'CLEAR_MESSAGE',
  SELECT = 'SELECT',
  SET = 'SET',
  SET_ASSIGNED_PROVIDERS = 'SET_ASSIGNED_PROVIDERS',
  SET_MESSAGE = 'SET_MESSAGE',
  REVIEW = 'REVIEW',
  REVIEW_MANY = 'REVIEW_MANY',
  SET_PROGRAM = 'SET_PROGRAM',
}

export interface Member {
  assignedProviders?: Provider[];
  avatar?: {
    thumb?: {
      url: string;
    };
  };
  current?: boolean;
  first_name: string;
  id: number | string;
  last_name: string;
  reviewed?: boolean;
  uuid: string;
  chat?: {
    message: string | null;
  };
  initial_weight?: number;
  feet: number;
  inches: number;
  gender: string;
  birthdate: string;
}

interface Role {
  description: string;
  display_value: string;
  id: number;
  name: string;
}

interface Provider {
  avatar: string;
  first_name: string;
  id: number;
  last_name: string;
  role: Role;
  role_names: string[];
  roles: Role[];
}

export type CurrentProgram = {
  id: number | null;
  displayValue: string;
  startDate: string;
  endDate: string | null;
  value: string;
  userProgramId: number | null;
  duration: number;
};

export type State = {
  slide: boolean | number;
  currentProgram: CurrentProgram;
  data: Member[];
};

type Payload = {
  [ActionTypes.CLEAR]: never;
  [ActionTypes.CLEAR_MESSAGE]: {
    memberId: number;
  };
  [ActionTypes.SELECT]: {
    id: number;
  };
  [ActionTypes.SET]: {
    patients: Member[];
    reviewedPatients: Member[];
  };
  [ActionTypes.SET_ASSIGNED_PROVIDERS]: {
    patientId: number;
    providers: Provider[];
  };
  [ActionTypes.SET_MESSAGE]: {
    memberId: number;
    message: string;
  };
  [ActionTypes.REVIEW]: {
    id: number;
  };
  [ActionTypes.REVIEW_MANY]: {
    ids: number[];
  };
  [ActionTypes.SET_PROGRAM]: {
    id: number | null;
    displayValue: string;
    startDate: string;
    endDate: string | null;
    value: string;
    userProgramId: number | null;
    duration: number;
  };
};

export type Action = DefaultAction<Payload>;

export const initialState: State = {
  slide: false,
  currentProgram: {
    id: null,
    displayValue: '',
    startDate: '',
    endDate: '',
    value: '',
    userProgramId: null,
    duration: 0,
  },
  data: [],
};

export const reducer: Reducer<State, Action> = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.CLEAR: {
      return {
        slide: false,
        currentProgram: {
          id: null,
          displayValue: '',
          startDate: '',
          endDate: '',
          value: '',
          userProgramId: null,
          duration: 0,
        },
        data: [],
      };
    }

    case ActionTypes.CLEAR_MESSAGE: {
      const { memberId } = action.payload;
      return {
        ...state,
        data: state.data.map((member) => {
          if (member.id === memberId) {
            return { ...member, chat: { message: null } };
          }
          return { ...member };
        }),
      };
    }

    case ActionTypes.SELECT: {
      const { id } = action.payload;
      return {
        ...state,
        data: state.data.map((patient) => ({
          ...patient,
          current: patient.id === id,
        })),
      };
    }

    case ActionTypes.SET: {
      const { patients } = action.payload;
      const nonReviewedPatientsLength = patients.length;
      let { reviewedPatients } = action.payload;
      reviewedPatients = reviewedPatients || [];
      return {
        ...state,
        data: [
          ...patients.map((patient, i) => ({
            ...patient,
            reviewed: false,
            current: i === 0,
          })),
          ...reviewedPatients.map((patient, i) => ({
            ...patient,
            reviewed: true,
            current: nonReviewedPatientsLength === 0 && i === 0,
          })),
        ],
      };
    }

    case ActionTypes.SET_ASSIGNED_PROVIDERS: {
      const { patientId, providers } = action.payload;
      return {
        ...state,
        data: state.data.map((member) => {
          if (member.id === patientId) {
            return { ...member, assignedProviders: providers };
          }
          return member;
        }),
      };
    }

    case ActionTypes.SET_MESSAGE: {
      const { memberId, message } = action.payload;
      return {
        ...state,
        data: state.data.map((member) => {
          if (member.id === memberId) {
            return { ...member, chat: { message } };
          }
          return { ...member };
        }),
      };
    }

    case ActionTypes.REVIEW: {
      const { id } = action.payload;
      return {
        ...state,
        slide: id,
        data: state.data.map((patient) => {
          if (patient.id === id) {
            return {
              ...patient,
              reviewed: !patient.reviewed,
            };
          }

          return patient;
        }),
      };
    }

    case ActionTypes.REVIEW_MANY: {
      const { ids } = action.payload;

      const hashWithMembers: { [key: number]: Member } = {};

      const members = state.data.map((member) => {
        const memberCopy = { ...member };
        hashWithMembers[Number(member.id)] = memberCopy;
        return memberCopy;
      });

      for (const id of ids) {
        if (id in hashWithMembers) {
          hashWithMembers[id].reviewed = true;
        }
      }

      return {
        ...state,
        data: members,
      };
    }

    case ActionTypes.SET_PROGRAM:
      return {
        ...state,
        currentProgram: {
          id: action.payload.id,
          displayValue: action.payload.displayValue,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          value: action.payload.value,
          userProgramId: action.payload.userProgramId,
          duration: action.payload.duration,
        },
      };

    default:
      throw new Error('Unexpected action');
  }
};
