const types = {
  TOGGLE_MODAL: 'TOGGLE_MODAL',
  SET: 'SET',
  ADD: 'ADD',
  DELETE: 'DELETE',
  RELOAD: 'RELOAD',
};

const initialState = {
  modal: false,
  reload: 0,
  data: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_MODAL: {
      return {
        ...state,
        modal: !state.modal,
      };
    }

    case types.SET: {
      const { patientId, data } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [patientId]: data,
        },
      };
    }

    case types.ADD: {
      const { patientId, data } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [patientId]: [...(state.data[patientId] ? state.data[patientId] : []), data],
        },
      };
    }

    case types.DELETE: {
      const { id, patientId } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [patientId]: state.data[patientId].filter((sticky) => sticky.id !== id),
        },
      };
    }

    case types.RELOAD: {
      return {
        ...state,
        reload: Date.now(),
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};

export { initialState, reducer, types };
