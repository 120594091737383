const types = {
  // SET: 'SET',
  RELOAD: 'RELOAD',
};

const initialState = {
  reload: 0,
  // data: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // case types.SET: {
    //   const { patientId, data } = action.payload;
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       [patientId]: data
    //     }
    //   };
    // }

    case types.RELOAD: {
      return {
        ...state,
        reload: Date.now(),
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};

export { initialState, reducer, types };
