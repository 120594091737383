import { createContext, Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { Action, initialState, reducer, State } from '../reducers/weights';

const WeightsContext = createContext<{ state: State; dispatch: Dispatch<Action> }>({
  state: initialState,
  dispatch: () => null,
});

const WeightsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <WeightsContext.Provider value={{ state, dispatch }}>{children}</WeightsContext.Provider>;
};

export { WeightsContext, WeightsProvider };
