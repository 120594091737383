import { BookmarkAdd, FilterAltOff } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridFilterModel,
  GridRowId,
  GridSortModel,
} from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import axios from 'axios';
import Toast from 'components/toast';
import { EFeatures } from 'lib/feature/features.types';
import { AttentionDimension, AttentionResponseEntry } from 'node-api/attention/AttentionApi.types';
import { AttentionClient } from 'node-api/attention/AttentionApiClient';
import { MyPanelLegacyClient } from 'node-api/panel/MyPanelLegacyClient';
import { FC, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { EventNames } from 'services/provider-app-mixpanel-events';
import { ProviderAppMixpanelInstance } from 'services/provider-app-mixpanel-service';
import { ClickableElement } from 'services/shared-mixpanel-service';
import { ClinicContext } from 'state/contexts/clinic';
import { FeaturesContext } from 'state/contexts/features';
import { PatientContext } from 'state/contexts/patient';
import { ActionTypes as PatientActionTypes } from 'state/reducers/patient';
import { debug } from 'utils/helpers';
import { getMemberProfileUrl } from 'utils/members';
import { PanelActionDrawer, PanelQuickAction } from './action-drawer/PanelActionDrawer';
import './panel.css';
import { PanelAssistant } from './PanelAssistant';
import { getColumnConfiguration } from './PanelConfig';
import { getDefaultFilters, PanelFilters, storeDefaultFilters } from './PanelFilters';
import PanelFooter from './PanelFooter';
import { getDrawerWidth, isQuickActionValid } from './panelHelpers';
import { PanelSideBar } from './sidebar/PanelSideBar';
import { PanelListType, PanelRow, PanelSettings } from './types';

const PANEL_SETTINGS_KEY = 'panel-settings';

const PANEL_QUICK_ACTION_LOCAL_STORAGE_KEY = 'panel:quick-action';

export const Panel: FC<RouteComponentProps> = ({ history, location }) => {
  const { state: clinicState } = useContext(ClinicContext);
  const { state: featuresState } = useContext(FeaturesContext);
  const { dispatch: patientDispatch } = useContext(PatientContext);
  const { clinics } = clinicState;

  // The property that will assign into `apiRef` does NOT like being `null` or `undefined`.
  const apiRef = useRef<GridApiCommunity>(null as unknown as GridApiCommunity);

  const forceDrawer = location.search.includes('drawer=true');

  const [loading, setLoading] = useState(true);

  const [settingModalOpen, setSettingModalOpen] = useState(false);
  const [settings, setSettings] = useState<PanelSettings>(
    JSON.parse(localStorage.getItem(PANEL_SETTINGS_KEY) || '{}')
  );
  const [reviewModalOpen, setReviewModalOpen] = useState(false);

  const [panelRows, setPanelRows] = useState<PanelRow[]>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>(getDefaultFilters());
  const [filteredRows, setFilteredRows] = useState<PanelRow[]>([]);
  const [listType, setListType] = useState<PanelListType>('panel');

  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const selectedMembers = panelRows
    .filter((row) => selectedRowIds.includes(row.id))
    .map((row) => row.member);

  const quickActionLocalStorage = localStorage.getItem(PANEL_QUICK_ACTION_LOCAL_STORAGE_KEY);
  const parsedQuickActionLocalStorage = quickActionLocalStorage
    ? JSON.parse(quickActionLocalStorage)
    : null;
  const [quickAction, setQuickAction] = useState<PanelQuickAction>(
    isQuickActionValid(parsedQuickActionLocalStorage) ? parsedQuickActionLocalStorage : 'collapsed'
  );
  useEffect(() => {
    localStorage.setItem(PANEL_QUICK_ACTION_LOCAL_STORAGE_KEY, JSON.stringify(quickAction));
  }, [quickAction]);

  // Add key listeners for command arrow left or command arrow right to select the previous or next patient in the list
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      let selectedId = null;

      // if command + period, open or close the drawer
      if (event.metaKey && (event.key === '.' || event.key === '/')) {
        event.preventDefault();
        ProviderAppMixpanelInstance.track({
          eventName: EventNames.PanelActionDrawerToggle,
          targetType: ClickableElement.SHORTCUT,
          targetValue: 'Space',
        });
        setQuickAction(quickAction === 'direct-chat' ? 'collapsed' : 'direct-chat');
      }
      if (event.metaKey && event.key === 'ArrowLeft') {
        event.preventDefault();
        if (filteredRows.length > 0) {
          const index = filteredRows.findIndex((row) => row.id === selectedRowIds[0]);
          if (index > 0) {
            selectedId = [filteredRows[index - 1].id];
          } else {
            selectedId = [filteredRows[0].id];
          }
        }
      } else if (event.metaKey && event.key === 'ArrowRight') {
        event.preventDefault();
        if (filteredRows.length > 0) {
          const index = filteredRows.findIndex((row) => row.id === selectedRowIds[0]);
          if (index < filteredRows.length - 1) {
            selectedId = [filteredRows[index + 1].id];
          }
        }
      }
      if (selectedId) {
        ProviderAppMixpanelInstance.track({
          eventName: EventNames.PanelKeyboardNavigation,
          targetType: ClickableElement.SHORTCUT,
          targetValue: event.key,
        });
        setSelectedRowIds(selectedId);
        apiRef.current?.setRowSelectionModel(selectedId);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  useEffect(() => {
    setFilteredRows(PanelAssistant.filterPanelRows({ filterModel, panelRows, settings, listType }));
  }, [filterModel, panelRows, settings, listType]);

  const [columnsConfiguration, setColumnsConfiguration] = useState<GridColDef<PanelRow>[]>(
    getColumnConfiguration(settings)
  );

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getPatients = async () => {
      try {
        const myPanelClient = new MyPanelLegacyClient();
        const attentionClient = new AttentionClient();
        const members = await myPanelClient.getMyPanel(source.token);

        patientDispatch({
          type: PatientActionTypes.SET,
          payload: {
            patients: members.map((member) => ({
              ...member,
              feet: 0,
              inches: 0,
              weight: 0,
              gender: 'm',
              birthdate: '1981/01/01',
            })),
            reviewedPatients: [],
          },
        });

        const rows = members.map((member) => ({
          id: member.id,
          member,
          clinic: clinics.find((clinic) => clinic.id === member.clinic_id) || null,
          attention: [] as AttentionResponseEntry[],
        }));

        // Only if the panel is successfully retrieved, get the attention need.
        // This will only be displayed if the feature is enabled, but we can fetch it regardless
        try {
          const attention = await attentionClient.getMembersAttentionNeed({
            memberIds: members.map((p) => p.id),
          });

          rows.forEach((row) => {
            row.attention = attention[row.member.id] || [];
          });
        } catch (err) {
          debug(err as string);
        }
        setPanelRows(rows);

        setLoading(false);
      } catch (err) {
        if (!axios.isCancel(err)) {
          debug(err as string);
          setLoading(false);
        }
      }
    };

    getPatients();

    return () => {
      source.cancel();
    };
  }, []);

  const handleEvent: GridEventListener<'cellClick'> = ({ row, colDef }, event) => {
    if (
      colDef.field === '__check__' ||
      colDef.type === 'checkboxSelection' ||
      colDef.type === 'checkbox'
    ) {
      return;
    }

    const { member } = (row as PanelRow) || {};
    const optionOrCommand = event.shiftKey || event.metaKey || event.ctrlKey;

    if (member) {
      ProviderAppMixpanelInstance.track({
        eventName: EventNames.MemberProfileOpen,
        targetLabel: optionOrCommand ? 'View Profile' : 'Open Profile',
        targetType: ClickableElement.LINK,
        'member-id': member.uuid,
        source: `panel-row-${colDef.field}`,
      });
      // if optionOrCommand open in new tab
      const url = getMemberProfileUrl(member);
      if (optionOrCommand) {
        window.open(url, '_blank');
      } else {
        history.push(url);
      }
      event.preventDefault();
    }
  };

  const handleDataGridStateFilterChange = (
    model: GridFilterModel
    // _: GridCallbackDetails<'filter'>
  ) => {
    // TODO: store the filter model
    ProviderAppMixpanelInstance.track({
      eventName: EventNames.PanelFiltersUpdate,
      targetType: ClickableElement.HEADER,
      targetLocation: model.items[0]?.field, // Only track correctly the first filter
    });
  };
  const handleDataGridStateSortChange = (
    model: GridSortModel
    // _: GridCallbackDetails
  ) => {
    // TODO: store the order model

    ProviderAppMixpanelInstance.track({
      eventName: EventNames.PanelSortUpdate,
      targetType: ClickableElement.HEADER,
      targetLocation: model[0]?.field,
    });
  };

  const handleOpenSettingsDialog = () => {
    ProviderAppMixpanelInstance.track({
      eventName: EventNames.PanelSettingsOpenModal,
      targetType: ClickableElement.BUTTON,
    });
    setSettingModalOpen(true);
  };

  const handleOpenMarkAsAttendedDialog = () => {
    ProviderAppMixpanelInstance.track({
      eventName: EventNames.PanelBulkDismissAttentionOpenModal,
      targetType: ClickableElement.BUTTON,
    });
    setReviewModalOpen(true);
  };

  const handleUpdateSettings = (value: PanelSettings) => {
    setSettingModalOpen(false);
    setSettings(value);
    setColumnsConfiguration(getColumnConfiguration(value));
    localStorage.setItem(PANEL_SETTINGS_KEY, JSON.stringify(value));
  };

  const handleDismissNeedForAttention = async (selectedAttention: AttentionDimension[]) => {
    setReviewModalOpen(false);
    if (selectedAttention?.length > 0) {
      try {
        const memberIds = selectedRowIds.map((id) => Number(id));
        const attentionClient = new AttentionClient();

        await Promise.all(
          selectedAttention.map((dimensionKey) => {
            ProviderAppMixpanelInstance.track({
              eventName: EventNames.PanelBulkDismissAttentionApply,
              targetType: ClickableElement.BUTTON,
              targetValue: dimensionKey,
              dismissCount: memberIds.length,
            });

            return attentionClient.markMemberAsTendedToForDimension({
              memberIds,
              dimensionKey,
            });
          })
        );

        const attention = await attentionClient.getMembersAttentionNeed({
          memberIds,
        });
        const updatedRows = panelRows.map((row) => ({
          ...row,
          attention: memberIds.includes(row.id) ? attention[row.member.id] || [] : row.attention,
        }));
        setPanelRows(updatedRows);
        Toast.show('success', `Members dismissed from ${selectedAttention.length} review(s).`);
      } catch (err) {
        Toast.show('error', 'Error dismissing members from reviews. Please, refresh the page.');
      }
    }
  };

  const MyPanelFooter: FC<PropsWithChildren> = () => (
    <PanelFooter
      panelSettings={{
        initialSettings: settings,
        modalOpen: settingModalOpen,
        onOpenModal: handleOpenSettingsDialog,
        onCloseModal: () => {
          ProviderAppMixpanelInstance.track({
            eventName: EventNames.PanelSettingsCancelModal,
            targetType: ClickableElement.BUTTON,
          });
          setSettingModalOpen(false);
        },
        onUpdateSettings: handleUpdateSettings,
      }}
      markAsAttended={{
        selectedRowIds,
        modalOpen: reviewModalOpen,
        onOpenModal: handleOpenMarkAsAttendedDialog,
        onCloseModal: () => {
          ProviderAppMixpanelInstance.track({
            eventName: EventNames.PanelBulkDismissAttentionCancelModal,
            targetType: ClickableElement.BUTTON,
          });
          setReviewModalOpen(false);
        },
        onDismissNeedForAttention: (dimensions) => {
          handleDismissNeedForAttention(dimensions);
          apiRef.current?.setRowSelectionModel([]);
        },
      }}
    />
  );

  const PanelToolbar: FC<PropsWithChildren> = () => (
    <div
      style={{
        padding: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}>
      <PanelFilters filterModel={filterModel} setFilterModel={setFilterModel} />
      <Tooltip title='Save as Default Filters' placement='top'>
        <IconButton
          aria-label='Save Filters'
          onClick={() => {
            ProviderAppMixpanelInstance.track({
              eventName: EventNames.PanelFiltersSave,
              targetType: ClickableElement.BUTTON,
            });
            storeDefaultFilters(filterModel);
          }}>
          <BookmarkAdd />
        </IconButton>
      </Tooltip>
      <Tooltip title='Reset to Default Filters' placement='top'>
        <IconButton
          aria-label='Reset Filters'
          onClick={() => {
            ProviderAppMixpanelInstance.track({
              eventName: EventNames.PanelFiltersReset,
              targetType: ClickableElement.BUTTON,
            });
            setFilterModel(getDefaultFilters());
            apiRef.current?.setSortModel([]);
          }}>
          <FilterAltOff />
        </IconButton>
      </Tooltip>
    </div>
  );
  const drawerVisible = forceDrawer || featuresState[EFeatures.ProviderPanelShowDrawer];
  return (
    <>
      {drawerVisible ? (
        <PanelActionDrawer
          selectedMembers={selectedMembers}
          quickAction={quickAction}
          setQuickAction={setQuickAction}
        />
      ) : null}
      <div
        style={{
          height: 'calc(100vh - 61px)',
          width: `calc(100vw - ${drawerVisible ? getDrawerWidth(quickAction) : '0px'} - 10px)`,
          padding: 10,
          gap: 10,
          display: 'flex',
          transition: 'width 0.3s',
        }}>
        <PanelSideBar
          filterModel={filterModel}
          onSelectList={setListType}
          panelRows={panelRows}
          selectedList={listType}
          settings={settings}
        />
        <DataGrid
          apiRef={apiRef}
          style={{
            flexGrow: 1,
          }}
          loading={loading}
          rows={filteredRows}
          columns={columnsConfiguration}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 50 },
            },
          }}
          onCellClick={handleEvent}
          checkboxSelection
          slots={{
            toolbar: PanelToolbar,
            footer: MyPanelFooter,
          }}
          onFilterModelChange={handleDataGridStateFilterChange}
          onSortModelChange={handleDataGridStateSortChange}
          onRowSelectionModelChange={(model) => setSelectedRowIds(model)}
        />
      </div>
    </>
  );
};
