import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MemberExercise } from 'node-api/member.types';
import { FC } from 'react';
import MixpanelService, { ClickElementTypes, TrackProps } from 'services/mixpanel-service';
import { AccordionWrapper } from './style';

interface AccordionProps {
  name: string;
  list: MemberExercise[];
  getSectionLabel: (section: MemberExercise) => string;
  mixpanel: TrackProps;
}

const AccordionElement: FC<AccordionProps> = ({ name, list, getSectionLabel, mixpanel }) => {
  return (
    <AccordionWrapper>
      {list.map((section) => (
        <Accordion
          key={`${name}-${section.id}`}
          onChange={() =>
            MixpanelService.trackClick({ type: ClickElementTypes.ACCORDION, ...mixpanel })
          }>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-content-${section.id}`}
            id={`panel-header-${section.id}`}>
            <Typography>{getSectionLabel(section)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {section.memberExercise.map((item) => (
              <div key={`${name}-${section.id}`}>
                <Typography>
                  {`${item.label}:`} <span>{item.value}</span>
                </Typography>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </AccordionWrapper>
  );
};

export default AccordionElement;
