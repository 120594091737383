import { FeaturesApiClient } from '@enara-modules/enara-module-features-api-client';
import { CancelToken } from 'axios';
import { FEATURE, MEMBERS_WEB_APP_URL, SCHEDULE_API_BASE_URL } from 'config';
import { AdvancedFeatures } from 'lib/feature/features.types';
import { SharedEnaraNodeClient } from 'node-api/common/SharedEnaraNodeClient';
import AuthService, { AuthProps } from 'services/auth-service';
import { EventNames } from 'services/provider-app-mixpanel-events';
import { ProviderAppMixpanelInstance } from 'services/provider-app-mixpanel-service';
import { BackgroundEvent } from 'services/shared-mixpanel-service';
import { SanitizedCoreAppointment, SanitizedCoreAppointmentResponse } from './types.v3';

type Attendee = 'member' | 'provider' | 'guest';

class SchedulingV3 extends SharedEnaraNodeClient {
  public async listAppointments(
    {
      memberId,
    }: {
      memberId: number;
    },
    cancelToken?: CancelToken
  ): Promise<SanitizedCoreAppointment[]> {
    const listResponse = await this.axiosClient.get<SanitizedCoreAppointmentResponse>(
      `/v3/members/${memberId}/appointments`,
      { cancelToken }
    );

    return listResponse?.data?.appointments || [];
  }

  private async getLegacyTwilioVideoUrl(
    memberInfo: { id: number; uuid: string },
    tokenFor: Attendee = 'provider'
  ) {
    const { Authorization } = AuthService.getAuth() as AuthProps;

    const response = await this.axiosClient.get<{
      success: 'true' | 'false';
      data: {
        patient_token: string;
        provider_token: string;
        user_type: 'member' | 'provider';
        authorized: true;
        patient_verified: true;
        error_message?: string;
      };
    }>(`video-call/v1/members/${memberInfo.uuid}/provider-token/${Authorization.token}`);

    if (response.status !== 200) {
      throw new Error('Unable to get provider video url');
    }

    const { data } = response;
    if (
      (data.data.user_type === 'provider',
      data.data.authorized === true && data.data.patient_verified === true)
    ) {
      let videoToken: string;
      if (tokenFor === 'provider') {
        videoToken = data.data.provider_token;
      } else {
        videoToken = data.data.patient_token;
      }
      return `${MEMBERS_WEB_APP_URL}/video-call/${videoToken}?memberId=${memberInfo.id}`;
    }

    throw new Error('Unable to get provider video url');
  }

  public async getProviderVideoUrl(
    memberInfo: { id: number; uuid: string },
    providerInfo: { id: number; uuid: string },
    tokenFor: Attendee, // To validate later in member-web-app
    calledFrom: string
  ): Promise<string> {
    const featuresClient = new FeaturesApiClient({
      targetEnv: FEATURE.targetEnv ?? '',
      baseUrl: FEATURE.url ?? '',
    });

    let featuresResponse = null;

    // TODO: Remove all this logic about feature flag and the call for getLegacyTwilioVideoUrl
    // after releasing chime-backed video calls

    try {
      featuresResponse = await featuresClient.getFeatures({
        useRawValues: true,
        featureNames: Object.values(AdvancedFeatures),
      });
    } catch (err) {
      console.error('Error getting feature flags');
      throw new Error('Problems getting the feature flag for video call');
    }

    if (
      !featuresResponse ||
      !featuresResponse.values[AdvancedFeatures.ProviderAllowedNewVideoCall]
    ) {
      console.error(`getProviderVideoUrl::Can't get the feature flag`);
      throw new Error(`The video call feature flag does't exists`);
    }

    const featureFlag = featuresResponse.values[AdvancedFeatures.ProviderAllowedNewVideoCall];
    let isMemberAllowed = featureFlag.enabled;
    if (featureFlag.value) {
      const allowedMembers = JSON.parse(featureFlag.value);
      isMemberAllowed = allowedMembers.includes(memberInfo.id);
    }

    ProviderAppMixpanelInstance.track({
      eventName: EventNames.MemberGetCallLink,
      targetLabel: 'getProviderVideoUrl',
      targetType: BackgroundEvent.CallingService,
      memberUuid: memberInfo.uuid,
      providerUuid: providerInfo.uuid,
      calledFrom,
      source: 'get-provider-url',
      chimeVideoCallEnabled: isMemberAllowed,
    });

    try {
      if (!isMemberAllowed) {
        return this.getLegacyTwilioVideoUrl(memberInfo, tokenFor);
      }

      const url = `video-call/v2/meeting/patient/${memberInfo.uuid}/provider/${providerInfo.uuid}?tokenFor=${tokenFor}`;
      const { data } = await this.axiosClient.get(url);
      if (!data.success) {
        throw new Error('Unable to get provider video url');
      }

      return `${MEMBERS_WEB_APP_URL}/chime-video/${data.videoToken}?source=${calledFrom}`;
    } catch (error) {
      console.error('getProviderVideoUrl::error: ', error);
      throw new Error('Unable to get provider video url');
    }
  }
}

export const schedulingV3Client = new SchedulingV3(SCHEDULE_API_BASE_URL);
