import { Typography } from '@mui/material';
import { MeetingContextContentType } from 'node-api/scribe/Scribe.types';

type Props = { content: MeetingContextContentType };

export const MeetingContextSection = ({ content }: Props) => {
  return (
    <Typography mb={3} textAlign='justify'>
      {content.data.context}
    </Typography>
  );
};
