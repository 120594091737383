import { CancelToken } from 'axios';
import { NODE_API } from 'config';
import { SharedEnaraNodeClient } from './common/SharedEnaraNodeClient';
import { GetExercisesResponse, GetProfileNotificationsResponse } from './member.types';

export type Weight = {
  id: number;
  recordedValue: number;
  source: string;
  sourceCreatedAt: string;
  confidence: number;
  isAnomaly: boolean;
};
export default class MembersClient extends SharedEnaraNodeClient {
  constructor() {
    super(NODE_API.members);
  }

  async exercises(
    { memberId, startDate, endDate }: { memberId: number; startDate: string; endDate: string },

    cancelToken?: CancelToken
  ): Promise<GetExercisesResponse> {
    const { data }: { data: GetExercisesResponse } = await this.axiosClient.get(
      `members/v1/members/${memberId}/exercises`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
        cancelToken,
      }
    );
    return data;
  }

  async profileNotifications(
    { memberId }: { memberId: number },

    cancelToken?: CancelToken
  ): Promise<GetProfileNotificationsResponse> {
    const { data }: { data: GetProfileNotificationsResponse } = await this.axiosClient.get(
      `members/v1/providers/members/${memberId}/profile-notifications`,
      {
        cancelToken,
      }
    );
    return data;
  }

  reviewProfileSubTab(
    {
      providerId,
      memberId,
      tabName,
      source,
    }: { providerId: number; memberId: number; tabName: string; source: string },

    cancelToken?: CancelToken
  ) {
    return this.axiosClient.post(
      `members/v1/providers/${providerId}/health-data-metrics/${tabName}`,
      {
        cancelToken,
        memberId,
        source,
      }
    );
  }

  reviewExercise(
    {
      memberId,
      source,
      memberActivityIds,
    }: { memberId: number; source: string; memberActivityIds: number[] },

    cancelToken?: CancelToken
  ) {
    return this.axiosClient.post(`members/v1/providers/exercise-review`, {
      cancelToken,
      memberId,
      source,
      memberActivityIds,
    });
  }

  getWeights(
    memberId: number,
    { fromDate, toDate }: { fromDate: string; toDate: string },
    cancelToken?: CancelToken
  ) {
    return this.axiosClient.get<{ records: Weight[] }>(
      `/v1/members/${memberId}/health-records/types/scale-weight`,
      {
        cancelToken,
        params: { fromDate, toDate },
      }
    );
  }

  addWeightInAllPlatforms(
    memberId: number,
    { date, source, weightInPounds }: { date: string; source: string; weightInPounds: number },
    cancelToken?: CancelToken
  ) {
    return this.axiosClient.post<{ record: { processedRecords: { id: number }[] } }>(
      `/v1/members/${memberId}/health-records/types/scale-weight`,
      {
        date,
        source,
        value: weightInPounds,
      },
      { cancelToken }
    );
  }

  deleteWeightInAllPlatforms(
    memberId: number,
    {
      coreScaleId,
      healthRecordId,
      memberUuid,
    }: { coreScaleId?: number; healthRecordId?: number; memberUuid?: string },
    cancelToken?: CancelToken
  ) {
    return this.axiosClient.delete<{ record: { id: number } }>(
      `/v1/members/${memberId}/health-records`,
      {
        cancelToken,
        data: {
          legacyScaleId: coreScaleId,
          memberUuid,
          recordId: healthRecordId,
        },
      }
    );
  }
}
