import { Checkbox, FormControlLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AttentionDimension } from 'node-api/attention/AttentionApi.types';
import * as React from 'react';
import { FC } from 'react';
import { EventNames } from 'services/provider-app-mixpanel-events';
import { ProviderAppMixpanelInstance } from 'services/provider-app-mixpanel-service';
import { ClickableElement } from 'services/shared-mixpanel-service';
import { PanelSettings } from './types';

type Props = DialogProps & {
  initialSettings: PanelSettings;
  onUpdateSettings: (settings: PanelSettings) => void;
  onClose: (
    value: PanelSettings | null,
    reason?: 'backdropClick' | 'escapeKeyDown' | 'cancelButtonClick'
  ) => void;
};

/**
 * React Dialog showing all Attention Dimensions.
 * Based on `activeDimensions` for that dimension being True, False, or Null, show a checkbox Marked, Unmarked, or Indeterminate.
 *
 * @param props
 * @returns
 */
export const PanelSettingsDialog: FC<Props> = (props) => {
  const { onClose, onUpdateSettings, initialSettings: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    ProviderAppMixpanelInstance.track({
      eventName: EventNames.PanelSettingsCancelModal,
      targetType: ClickableElement.BUTTON,
    });

    onClose(null, 'cancelButtonClick');
  };

  const handleOk = () => {
    ProviderAppMixpanelInstance.track({
      eventName: EventNames.PanelSettingsConfirm,
      targetType: ClickableElement.BUTTON,
    });
    onUpdateSettings(value);
  };

  const activeDimensions = value.activeDimensions || {};

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth='xs'
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      onClose={onClose}
      {...other}>
      <DialogTitle>My Panel: Active reviews</DialogTitle>
      <DialogContent>Choose which pending reviews to display or hidden.</DialogContent>
      <DialogContent
        dividers
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}>
        {Object.entries(AttentionDimension).map(([key, dim]) => (
          <FormControlLabel
            key={dim}
            control={
              <Checkbox
                checked={activeDimensions[dim as AttentionDimension] === true || undefined}
                indeterminate={
                  activeDimensions[dim as AttentionDimension] === null ||
                  activeDimensions[dim as AttentionDimension] === undefined
                }
                onChange={() =>
                  setValue({
                    ...value,
                    activeDimensions: {
                      ...activeDimensions,
                      [dim]: !activeDimensions[dim as AttentionDimension],
                    },
                  })
                }
              />
            }
            label={key}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};
