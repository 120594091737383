import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';
import { initialState, reducer } from '../reducers/status';

const StatusContext = createContext(initialState);

const StatusProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <StatusContext.Provider value={{ state, dispatch }}>{children}</StatusContext.Provider>;
};

StatusProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { StatusContext, StatusProvider };
