import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { GridFilterModel, GridLogicOperator } from '@mui/x-data-grid';
import Toast from 'components/toast';
import { Clinic } from 'node-api/dashboard.types';
import { FC, PropsWithChildren, useContext } from 'react';
import { ClinicContext } from 'state/contexts/clinic';
import { ProgramContext } from 'state/contexts/program';
import {
  PANEL_STATUS_FILTER_DEFAULT_ONLY_ACTIVE,
  PANEL_TIME_FILTER_OPTIONS,
} from './PanelFilterConstants';

/* Local storage */
const PANEL_FILTERS_KEY = 'panel-filters';
export const getDefaultFilters = (): GridFilterModel =>
  JSON.parse(
    localStorage.getItem(PANEL_FILTERS_KEY) ||
      JSON.stringify(PANEL_STATUS_FILTER_DEFAULT_ONLY_ACTIVE)
  );
export const storeDefaultFilters = (model: GridFilterModel) => {
  const encodedModel = JSON.stringify(model);
  localStorage.setItem(PANEL_FILTERS_KEY, encodedModel);
  Toast.show('info', 'Panel Filter: Your default filters have been saved.');
};

/** Filter display */
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const CLINICS_MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

type DataGridFilterModelProps = {
  filterModel: GridFilterModel;
  setFilterModel: (model: GridFilterModel) => void;
};

type DataGridFilterProps<T> = DataGridFilterModelProps & {
  fieldName: string;
  displayName: string;
  options: T[];
  valueFormatter?: (value: T) => string;
};

/**
 * Generic filter component for DataGrid
 * Based on given options, it will display a dropdown with checkboxes
 * Upon change, it will update the filter model.
 *
 * When type T is not a string, a valueFormatter function must be provided to convert the value to a string.
 * This format is used externally to render options and selected values, and internally to track selected values.
 *
 * When nothing is selected, 'All' is displayed. When `All` is selected, the filter is removed.
 *
 * @param param0
 * @returns
 */
export const DataGridFilter = <T extends object | string>({
  filterModel,
  setFilterModel,
  options,
  valueFormatter = (value: T) => value?.toString() || '',
  fieldName,
  displayName,
}: DataGridFilterProps<T>) => {
  const selectedValues: T[] =
    filterModel.items.find((item) => item.field === fieldName)?.value || [];
  const selectedKeys = selectedValues.map(valueFormatter);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      // There is some inconsistency in the implementation of Select:
      // `value` is sometimes the string used as `key` for the option, and sometimes the value of the option (<T>).
      target: { value },
    } = event;

    // To resolve this, we check if the value is a string or an array of <T>
    // and convert it to the keys of the selected options.
    const updatedKeys =
      typeof value === 'string'
        ? value.split(',')
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          value.map((v) => valueFormatter(v as any as T) || v.toString());

    // If 'All' is selected and there are other options selected, set the selected values to []
    if (updatedKeys.includes('All')) {
      setFilterModel({
        items: [
          ...filterModel.items.filter((item) => item.field !== fieldName),
          {
            field: fieldName,
            operator: 'in',
            value: [],
          },
        ],
        logicOperator: GridLogicOperator.And,
      });
    } else {
      const updatedValues = options.filter((option) =>
        updatedKeys.includes(valueFormatter(option))
      );
      const updatedFilter = {
        items: [
          ...filterModel.items.filter((item) => item.field !== fieldName),
          {
            field: fieldName,
            operator: 'in',
            value: updatedValues,
          },
        ],
        logicOperator: GridLogicOperator.And,
      };
      setFilterModel(updatedFilter);
    }
  };

  const displaySelected =
    selectedValues?.length > 1
      ? `(${selectedValues.length}) ${selectedValues.slice(0, 3).map(valueFormatter).join(', ')}`
      : selectedValues?.length > 0
      ? valueFormatter(selectedValues[0])
      : 'All';

  return (
    <FormControl sx={{ m: 1 }} size='small'>
      <InputLabel shrink htmlFor='select-multiple-native'>
        {displayName}
      </InputLabel>
      <Select
        displayEmpty
        id='demo-multiple-checkbox'
        input={<OutlinedInput label='Tag' />}
        labelId='demo-multiple-checkbox-label'
        MenuProps={CLINICS_MENU_PROPS}
        multiple
        onChange={handleChange}
        renderValue={() => displaySelected}
        sx={{ minWidth: 200, maxWidth: 250 }}
        value={selectedKeys}>
        <MenuItem key='All' value='All'>
          <Checkbox checked={selectedValues.length === 0} />
          <ListItemText primary='All' />
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={valueFormatter(option)} value={valueFormatter(option)}>
            <Checkbox
              checked={!!selectedValues.find((c) => valueFormatter(option) === valueFormatter(c))}
            />
            <ListItemText primary={valueFormatter(option)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const PanelClinicsFilter: FC<DataGridFilterModelProps> = ({ filterModel, setFilterModel }) => {
  const { state: clinicState } = useContext(ClinicContext);
  const { clinics } = clinicState;

  return (
    <DataGridFilter<Clinic>
      filterModel={filterModel}
      setFilterModel={setFilterModel}
      fieldName='clinic_name'
      displayName='Clinics'
      options={clinics}
      valueFormatter={(clinic) => clinic.name}
    />
  );
};

const PanelStatusFilter: FC<DataGridFilterModelProps> = ({ filterModel, setFilterModel }) => {
  const statuses = ['Active', 'Lite', 'Exploring', 'Completed', 'Dropout', 'Disengaged', 'Paused'];

  return (
    <DataGridFilter
      filterModel={filterModel}
      setFilterModel={setFilterModel}
      fieldName='patient_status'
      displayName='Status'
      options={statuses}
    />
  );
};

const PanelProgramFilter: FC<DataGridFilterModelProps> = ({ filterModel, setFilterModel }) => {
  const { state: programState } = useContext(ProgramContext);

  return (
    <DataGridFilter
      filterModel={filterModel}
      setFilterModel={setFilterModel}
      fieldName='current_program'
      displayName='Program'
      options={programState.data}
      valueFormatter={(program) => program.display_value}
    />
  );
};

const PanelTimeFilter: FC<DataGridFilterModelProps> = ({ filterModel, setFilterModel }) => {
  return (
    <DataGridFilter
      filterModel={filterModel}
      setFilterModel={setFilterModel}
      fieldName='program_start_days_ago'
      displayName='Time'
      options={PANEL_TIME_FILTER_OPTIONS}
      valueFormatter={(program) => program.displayText}
    />
  );
};
export const PanelFilters: FC<
  PropsWithChildren & {
    filterModel: GridFilterModel;
    setFilterModel: (model: GridFilterModel) => void;
  }
> = ({ filterModel, setFilterModel }) => {
  return (
    <>
      <PanelClinicsFilter filterModel={filterModel} setFilterModel={setFilterModel} />
      <PanelStatusFilter filterModel={filterModel} setFilterModel={setFilterModel} />
      <PanelProgramFilter filterModel={filterModel} setFilterModel={setFilterModel} />
      <PanelTimeFilter filterModel={filterModel} setFilterModel={setFilterModel} />
    </>
  );
};
