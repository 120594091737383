import api from 'api';
import axios from 'axios';
import { Loading } from 'components/elements';
import { MainSection, MainSectionContainer } from 'components/general-styles';
import ProfileTabsBox from 'components/main/profile/ProfileTabsBox/ProfileTabsBox';
import EventNotes from 'components/main/profile/event-notes';
import StickyNotes from 'components/main/profile/sticky-notes';
import SummaryBox from 'components/main/profile/summary-box/SummaryBox';
import Toast from 'components/toast';
import { NODE_REVIEW_LIST_NAMES } from 'config';
import { isReviewList4 } from 'controllers/review/review-controller';
import useLastProgram from 'hooks/useLastProgram';
import node_api from 'node-api';
import { ReviewListClient } from 'node-api/reviewList/reviewlistClient';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { PatientContext } from 'state/contexts/patient';
import { ProviderContext } from 'state/contexts/provider';
import { ReviewLists4Context } from 'state/contexts/reviewLists4';
import { UserContext } from 'state/contexts/user';
import { ActionTypes as PatientActionTypes } from 'state/reducers/patient';
import { ActionTypes as ProviderActionTypes } from 'state/reducers/provider';
import { debug, getOriginalUserFullName } from 'utils/helpers';
import { getTimezoneByClinicId } from 'utils/timezones';
import Chat from '../profile/chat';
import { getUserInfo } from '../rpm-event/util';
import ReviewList from './review-list/ReviewList';

const Review = ({ match }) => {
  const { dispatch: providerDispatch } = useContext(ProviderContext);
  const { state: patientState, dispatch: patientDispatch } = useContext(PatientContext);
  const { state: userState } = useContext(UserContext);
  const { state: reviewLists4State } = useContext(ReviewLists4Context);

  const { id: reviewList } = match.params;

  const [reviewListLabel, setReviewListLabel] = useState();
  const [patient, setPatient] = useState();
  const [loadingPatientsList, setLoadingPatientsList] = useState(true);
  const [loadingPatientDetails, setLoadingPatientDetails] = useState(true);
  const [user] = useState(getUserInfo(userState));

  const currentProgram = useLastProgram();

  useEffect(() => {
    if (!currentProgram) {
      return;
    }

    patientDispatch({ type: PatientActionTypes.SET_PROGRAM, payload: currentProgram });
  }, [currentProgram]);

  useEffect(() => {
    const patientsListSource = axios.CancelToken.source();

    const fetchPatientsList = async () => {
      try {
        const originalUserFullName = getOriginalUserFullName();

        let data;

        const params = {
          ...(userState.isOriginalUser === false &&
            originalUserFullName !== null && { original_user: originalUserFullName }),
        };

        if (isReviewList4(reviewList, reviewLists4State.data)) {
          data = await new ReviewListClient().getReviewList4(
            { reviewListName: reviewList },
            patientsListSource.token
          );
        } else if (NODE_REVIEW_LIST_NAMES.includes(reviewList)) {
          // Node BE
          const response = await node_api().get(`reviews/${reviewList}/AUTH_TOKEN`, {
            cancelToken: patientsListSource.token,
            params,
          });

          data = response.data;
        } else {
          const response = await api().get(`reviews/${reviewList}/AUTH_TOKEN`, {
            cancelToken: patientsListSource.token,
            params,
          });

          data = response.data;
        }

        patientDispatch({ type: PatientActionTypes.SET, payload: { patients: data.users } });

        setReviewListLabel(data.review_list || data.label);
        setLoadingPatientsList(false);
      } catch (err) {
        if (!axios.isCancel(err)) {
          debug(err);
          setLoadingPatientsList(false);

          Toast.show('error', `Couldn't load review list data, please try again later!`);
        }
      }
    };

    if (reviewList) {
      fetchPatientsList();
    }

    return () => {
      patientDispatch({ type: PatientActionTypes.CLEAR });

      patientsListSource.cancel();
    };
  }, []);

  useEffect(() => {
    const currentPatient = patientState.data.find((p) => p.current);

    if (loadingPatientsList || !currentPatient) {
      return;
    }

    const providersSource = axios.CancelToken.source();
    const videoLinkSource = axios.CancelToken.source();

    const fetchPatientDetails = async () => {
      try {
        // Get providers
        const { data: providers } = await api().get(
          `users/${currentPatient.id}/providers/AUTH_TOKEN`,
          {
            cancelToken: providersSource.token,
          }
        );

        providerDispatch({ type: ProviderActionTypes.SET, payload: { providers } });

        // TODO: Member timezone should be returned from BE, setting it for now based on the clinic ID
        currentPatient.timezone = getTimezoneByClinicId(currentPatient.clinic_id);

        setPatient(currentPatient);
      } catch (err) {
        if (!axios.isCancel(err)) {
          debug(err);
        }
      }

      setLoadingPatientDetails(false);
    };

    fetchPatientDetails();

    return () => {
      providersSource.cancel();
      videoLinkSource.cancel();
    };
  }, [patientState, loadingPatientsList]);

  useEffect(() => {
    if (!loadingPatientsList && !patientState.data.length) {
      // There are no members to review
      setLoadingPatientDetails(false);
    }
  }, [loadingPatientsList, patientState]);

  if (loadingPatientsList || loadingPatientDetails) {
    return <Loading />;
  }

  return (
    <MainSectionContainer>
      {patient ? (
        <>
          <MainSection style={{ minWidth: 800 }}>
            <ReviewList reviewListLabel={reviewListLabel} reviewListName={reviewList} />

            <StickyNotes patientId={patient.id} key={`sticky-${patient.id}`} />

            <EventNotes
              patientUuid={patient.uuid}
              patientId={patient.id}
              key={`event-${patient.id}`}
            />

            <SummaryBox
              patientId={patient.id}
              listLabel={reviewListLabel}
              listName={reviewList}
              key={`summary-${patient.id}`}
              hasReview
            />

            <ProfileTabsBox patient={patient} user={user} reviewList={reviewList} />
          </MainSection>

          <Chat patientId={patient.id} reviewList={reviewListLabel} key={`chat-${patient.id}`} />
        </>
      ) : (
        <div style={{ marginTop: 15 }}>No members to review just yet</div>
      )}
    </MainSectionContainer>
  );
};

Review.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default Review;
