/* eslint-disable */
import { capitalize } from 'lodash';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import ReactTooltip from 'react-tooltip';
import { ChartContext } from 'state/contexts/chart';
import { hasValues } from 'utils/helpers';
import { getTooltipContent } from '../../common';
import Theme from 'styles/theme';

import { ChartWrapper, Serie, SeriesWrappers, Tooltip } from '../../style';

const Charts = React.memo(() => {
  const { state: chartState } = useContext(ChartContext);
  const [data, setData] = useState(chartState.data);
  const [toggle, setToggle] = useState({
    programs: true,
    events: true,
    appointments: true,
    systolic: true,
    diastolic: true,
  });

  const series = ['programs', 'events', 'diastolic', 'systolic', 'appointments'];

  const renderTooltips = () => {
    const tooltips = {
      programs: [],
      events: [],
      appointments: [],
    };

    if (data.programs.values && data.programs.values[0]) {
      if (
        data.healthkits.values.diastolic.length &&
        moment(data.healthkits.values.diastolic[0].date).format('YYYY-MM-DD') >
          moment(data.programs.values[0].start_date).format('YYYY-MM-DD')
      ) {
        const dummyPoint = {
          date: data.programs.values[0].start_date,
          value: data.healthkits.values.diastolic[0].value,
        };
        data.healthkits.values.diastolic.unshift(dummyPoint);
      }
      if (
        data.healthkits.values.systolic.length &&
        moment(data.healthkits.values.systolic[0].date).format('YYYY-MM-DD') >
          moment(data.programs.values[0].start_date).format('YYYY-MM-DD')
      ) {
        const dummyPoint = {
          date: data.programs.values[0].start_date,
          value: data.healthkits.values.systolic[0].value,
        };
        data.healthkits.values.systolic.unshift(dummyPoint);
      }
    }

    for (var i = 0; i < data.healthkits.values.diastolic.length; i++) {
      if (data.healthkits.values.diastolic[i].value === null) {
        data.healthkits.values.diastolic[i].value = 0;
      }
    }
    for (i = 0; i < data.healthkits.values.systolic.length; i++) {
      if (data.healthkits.values.systolic[i].value === null) {
        data.healthkits.values.systolic[i].value = 0;
      }
    }

    series.forEach((serie) => {
      if (
        serie !== 'diastolic' &&
        serie !== 'systolic' &&
        data[serie] &&
        data[serie].values &&
        hasValues(data[serie].values)
      ) {
        tooltips[serie] = data[serie].values.map((annotation, i) => (
          <ReactTooltip
            id={`${serie}-${i}`}
            key={`${serie}-${annotation.start_date}`}
            delayHide={200}
            className='custom'
            effect='solid'
            clickable>
            <Tooltip type={serie}>{getTooltipContent(serie, annotation)}</Tooltip>
          </ReactTooltip>
        ));
      }
    });

    return [...tooltips.programs, ...tooltips.events, ...tooltips.appointments];
  };

  const addTooltipAttributes = (timeout) => {
    setTimeout(() => {
      series.forEach((serie) => {
        if (
          serie !== 'diastolic' &&
          serie !== 'systolic' &&
          data[serie] &&
          data[serie].values &&
          hasValues(data[serie].values)
        ) {
          data[serie].values.forEach((annotation, i) => {
            const a = document.getElementsByClassName(`${serie}-${i}-data`)[0];
            if (a) {
              a.setAttribute('data-for', `${serie}-${i}`);
              a.setAttribute('data-tip', '');
            }
          });
        }
      });
      ReactTooltip.rebuild();
    }, timeout);
  };

  const getAnottations = () => {
    const annotattions = {
      programs: [],
      events: [],
      appointments: [],
    };

    series.forEach((serie) => {
      if (
        serie !== 'diastolic' &&
        serie !== 'systolic' &&
        data[serie] &&
        data[serie].values &&
        hasValues(data[serie].values) &&
        toggle[serie]
      ) {
        annotattions[serie] = data[serie].values.map((annotation, i) => ({
          x: new Date(moment(annotation.start_date).format()).getTime(),
          borderColor: Theme.series[serie],
          label: {
            borderColor: Theme.series[serie],
            style: {
              color: Theme.white,
              background: Theme.series[serie],
              cssClass: `apexchart-annotations apexcharts-yaxis-annotation-${serie} ${serie}-${i}-data`,
            },
            text: annotation.value ? annotation.value : annotation.name,
          },
        }));
      }
    });

    return [...annotattions.programs, ...annotattions.events, ...annotattions.appointments];
  };

  useEffect(() => {
    setData(chartState.data);
  }, [chartState.data]);

  return (
    <>
      <ChartWrapper>
        {renderTooltips()}

        <Chart
          options={{
            chart: {
              fontFamily: Theme.primaryFont,
              stacked: false,
              animations: {
                enabled: true,
              },
              zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true,
              },
              toolbar: {
                autoSelected: 'zoom',
              },
              events: {
                mounted() {
                  addTooltipAttributes(1000);
                },
                zoomed() {
                  addTooltipAttributes(0);
                },
                updated() {
                  addTooltipAttributes(0);
                },
              },
            },
            plotOptions: {
              line: {
                curve: 'smooth',
              },
            },
            stroke: {
              width: [2],
              curve: 'smooth',
            },
            colors: [Theme.series.diastolic, Theme.series.systolic],
            legend: {
              show: false,
            },
            markers: {
              size: [2],
              strokeWidth: 0,
              hover: {
                sizeOffset: 0,
              },
            },
            xaxis: {
              type: 'datetime',
              labels: {
                formatter: (val) => moment(val).format('YYYY-MM-DD'),
              },
            },
            yaxis: {
              labels: {
                // formatter: (val) => `${val}`
                formatter: (val) => `${val.toFixed(0)}`,
              },
            },
            annotations: {
              xaxis: getAnottations(),
            },
            fixed: {
              enabled: true,
              position: 'topRight',
              offsetX: 410,
              offsetY: 410,
            },
            tooltip: {
              y: {
                formatter: (val, { seriesIndex, dataPointIndex, w }) => {
                  return `${val}${
                    (w && ` ${w.globals.initialSeries[seriesIndex].data[dataPointIndex].source}`) ||
                    ''
                  }`;
                },
              },
            },
          }}
          series={[
            {
              name: 'Diastolic',
              type: 'line',
              data: data.healthkits.values.diastolic.map((point) => ({
                x: new Date(moment(point.date).format()).getTime(),
                y: point.value,
                source: point.source ? `(Source: ${point.source})` : '',
              })),
            },
            {
              name: 'Systolic',
              type: 'line',
              data: data.healthkits.values.systolic.map((point) => ({
                x: new Date(moment(point.date).format()).getTime(),
                y: point.value,
                source: point.source ? `(Source: ${point.source})` : '',
              })),
            },
          ]}
          type='line'
          height='100%'
        />
      </ChartWrapper>

      <SeriesWrappers>
        {series.map(
          (serie, index) =>
            ((serie !== 'diastolic' &&
              serie !== 'systolic' &&
              data[serie] &&
              data[serie].values &&
              hasValues(data[serie].values) > 0) ||
              (serie === 'diastolic' && hasValues(data.healthkits.values.diastolic) > 0) ||
              (serie === 'systolic' && hasValues(data.healthkits.values.systolic) > 0)) && (
              <Serie
                key={`${serie}-serie`}
                type={serie}
                visible={toggle[serie]}
                onClick={() =>
                  serie !== 'diastolic' &&
                  serie !== 'systolic' &&
                  setToggle((prev) => ({
                    ...prev,
                    [serie]: !prev[serie],
                  }))
                }>
                <i className={`serie_${index}`} />
                {capitalize(serie)}
              </Serie>
            )
        )}
      </SeriesWrappers>
    </>
  );
});

Charts.propTypes = {};
Charts.defaultProps = {};

export default Charts;
