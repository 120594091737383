const types = {
  SET: 'SET',
  TOGGLE_MODAL: 'TOGGLE_MODAL',
};

const initialState = {
  modal: false,
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET: {
      const { payload } = action;
      return {
        ...state,
        data: payload,
      };
    }

    case types.TOGGLE_MODAL: {
      return {
        ...state,
        modal: !state.modal,
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};
export { initialState, reducer, types };
