import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';
import { initialState, reducer } from '../reducers/event';

const EventContext = createContext(initialState);

const EventProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <EventContext.Provider value={{ state, dispatch }}>{children}</EventContext.Provider>;
};

EventProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { EventContext, EventProvider };
