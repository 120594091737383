import { Close } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import Toast from 'components/toast';
import {
  DiagnosisOutput,
  MedicalProblemOutput,
  MeetingDurationContentType,
  MeetingSummaryStatus,
  ScribeOutputType,
} from 'node-api/scribe/Scribe.types';
import ScribeClient from 'node-api/scribe/ScribeClient';
import { useState } from 'react';
import { getFormattedDateFromISOString } from 'utils/dateHelpers';
import '../../css/notes.css';
import { NoteDetail } from '../../notes.type';
import { NoteEditionPreview } from '../NoteEditionPreview/NoteEditionPreview';
import '../css/note-edition.css';
import '../css/note-preview.css';
import '../css/note.css';
import { ProblemOption } from './sections/MedicalProblemSection';
import { renderNoteEditionSectionsByOutputType } from './sections/render-sections';

type Props = {
  note: NoteDetail;
  onCloseNoteEdition: (isOpen: boolean) => void;
};

export const NoteEdition = ({ note, onCloseNoteEdition }: Props) => {
  const [updatedNote, setUpdatedNote] = useState<NoteDetail>(note);

  const handleMarkAsReviewed = async () => {
    const client = new ScribeClient();
    const response = await client.updateSummary(note.meetingSummary.id, {
      content: updatedNote.content,
      status: MeetingSummaryStatus.REVIEWED,
    });

    if (!response.status || !response.note) {
      Toast.show('error', 'There was an error updating the note');
      return;
    }

    setUpdatedNote(response.note);
    Toast.show('success', 'Note marked as reviewed successfully');
  };

  const handleChangeDiagnosis = (value: {
    premise: keyof Omit<DiagnosisOutput, 'sectionId'>;
    data: string[];
  }) => {
    setUpdatedNote({
      ...updatedNote,
      content: updatedNote.content?.map((section) => {
        if (section.outputType !== ScribeOutputType.DiagnosisSummary) {
          return section;
        }

        return {
          ...section,
          data: {
            ...section.data,
            [value.premise]: value.data,
          },
        };
      }),
    });
  };

  const handleChangeMedicalProblem = (value: {
    sectionId: string;
    order: number;
    outputType: ScribeOutputType.MedicalProblems;
    data: MedicalProblemOutput;
    index: number;
  }) => {
    setUpdatedNote({
      ...updatedNote,
      content: updatedNote.content?.map((section) => {
        if (section.outputType === ScribeOutputType.MedicalProblems) {
          section.data[value.index] = { ...section.data[value.index], ...value.data };
          return {
            ...section,
            data: section.data,
          };
        }
        return section;
      }),
    });
  };

  const handleChangeMeetingDuration = (value: MeetingDurationContentType) => {
    setUpdatedNote({
      ...updatedNote,
      content: updatedNote.content?.map((section) => {
        return section.outputType === value.outputType
          ? {
              ...section,
              ...value,
            }
          : section;
      }),
    });
  };

  const handleDeleteProblem = (problemPosition: number) => {
    setUpdatedNote({
      ...updatedNote,
      content: updatedNote.content?.map((section) => {
        if (section.outputType === ScribeOutputType.MedicalProblems) {
          const problems = [...section.data];
          problems.splice(problemPosition, 1);

          return {
            ...section,
            data: [...problems],
          };
        }

        return section;
      }),
    });
  };

  const handleAddProblem = (problemOption: ProblemOption) => {
    const newProblem: MedicalProblemOutput = {
      name: problemOption.label,
      history: '',
      dataReview: '',
      icd10Code: problemOption.value,
      subjectiveUpdates: '',
      plan: '',
    };

    setUpdatedNote({
      ...updatedNote,
      content: updatedNote.content?.map((section) => {
        if (section.outputType !== ScribeOutputType.MedicalProblems) {
          return section;
        }

        return {
          ...section,
          data: [...section.data, { ...newProblem }],
        };
      }),
    });
  };

  const handleDeleteDiagnosis = (
    premise: keyof Omit<DiagnosisOutput, 'sectionId'>,
    value: string
  ) => {
    setUpdatedNote({
      ...updatedNote,
      content: updatedNote.content?.map((section) => {
        if (section.outputType === ScribeOutputType.DiagnosisSummary) {
          const diagnoses = [...(section.data[premise] || [])];
          diagnoses.splice(section.data[premise]?.indexOf(value) || 0, 1);

          return {
            ...section,
            data: {
              ...section.data,
              [premise]: [...diagnoses],
            },
          };
        }

        return section;
      }),
    });
  };

  return (
    <Box className='note-edition-container'>
      <Box className='note-edition-header'>
        <Box display='flex' alignItems='center'>
          <Box className='note-list-row-icon' mr={1} />
          <Typography
            mr={1}
            sx={{
              color: '#404040',
              opacity: '60%',
            }}>{`${note.meetingType.internalCode} ${getFormattedDateFromISOString(
            note.meeting.createdAt,
            'h:mma'
          )}`}</Typography>
          <Typography
            color='#323648'
            mr={
              1
            }>{`${note.provider.firstName} ${note.provider.lastName}, ${note.provider.specialty}`}</Typography>
        </Box>

        <IconButton size='small' onClick={() => onCloseNoteEdition(false)}>
          <Close />
        </IconButton>
      </Box>

      <Box className='note-edition-container'>
        {note.content && (
          <Box className='note-edition-content'>
            <Box className='note-preview-content-header' mr={1}>
              <Typography
                component='h4'
                fontWeight={600}
                color='#353535'
                sx={{
                  fontSize: '18px !important',
                }}>
                Edit
              </Typography>
            </Box>

            <Box mb={2} pb={3} borderBottom='1px solid #DBDBDB' id='note-edition'>
              {renderNoteEditionSectionsByOutputType(
                [ScribeOutputType.MeetingContext, ScribeOutputType.MeetingDuration],
                updatedNote.content?.sort((a, b) => a.order - b.order) || [],
                {
                  handleAddProblem,
                  handleChangeDiagnosis,
                  handleChangeMeetingDuration,
                  handleDeleteDiagnosis,
                  handleChangeMedicalProblem,
                  handleDeleteProblem,
                }
              )}
            </Box>

            {renderNoteEditionSectionsByOutputType(
              [ScribeOutputType.MedicalProblems, ScribeOutputType.DiagnosisSummary],
              updatedNote.content?.sort((a, b) => a.order - b.order) || [],
              {
                handleAddProblem,
                handleChangeDiagnosis,
                handleChangeMeetingDuration,
                handleDeleteDiagnosis,
                handleChangeMedicalProblem,
                handleDeleteProblem,
              }
            )}
          </Box>
        )}

        <Box className='note-edition-preview'>
          <NoteEditionPreview note={updatedNote} handleMarkAsReviewed={handleMarkAsReviewed} />
        </Box>
      </Box>
    </Box>
  );
};
