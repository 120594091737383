import { capitalize } from 'lodash';
import moment from 'moment-timezone';
import React, { useContext, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import ReactTooltip from 'react-tooltip';
import { ChartContext } from 'state/contexts/chart';
import { WeightsContext } from 'state/contexts/weights';
import Theme from 'styles/theme';
import { hasValues } from 'utils/helpers';
import { getTooltipContent } from '../../common';
import { ChartWrapper, Serie, SeriesWrappers, Tooltip } from '../../style';
import ManageWeight from './manage-weight/manage-weight';

const SERIES = ['programs', 'events', 'weights', 'appointments'];

const Charts = React.memo(() => {
  const { state: chartState } = useContext(ChartContext);
  const { state: weightsState } = useContext(WeightsContext);

  const [toggle, setToggle] = useState({
    programs: true,
    events: true,
    appointments: true,
    weights: true,
  });
  const [weightData, setWeightData] = useState({
    date: Date(),
    weight: '',
    id: null,
  });
  const [showManageWeightModal, setManageWeightModal] = useState(false);

  const chartData = useMemo(() => {
    let allSeries = { appointments: [], events: [], programs: [], weights: [] };

    // First load series from chartState
    if (!!chartState?.data) {
      allSeries = {
        ...allSeries,
        appointments: chartState.data?.appointments?.values ?? [],
        events: chartState.data?.events?.values ?? [],
        programs: chartState.data?.programs?.values ?? [],
      };
    }

    // Then load weights from weightsState
    if (!!weightsState?.weight?.weights?.length) {
      const weightsSeries = [...weightsState.weight.weights];

      // Polish weights
      if (!!chartState?.data?.programs?.values?.length) {
        if (
          moment(weightsSeries[0].date).format('YYYY-MM-DD') >
          moment(chartState.data.programs.values[0].start_date).format('YYYY-MM-DD')
        ) {
          const dummyPoint = {
            date: chartState.data.programs.values[0].start_date,
            hrId: -1,
            id: -1,
            source: 'dummy-point',
            value: weightsSeries[0].value,
          };

          // Set dummy point at the beginning of the list
          weightsSeries.unshift(dummyPoint);
        }
      }

      // Set weights
      allSeries = { ...allSeries, weights: weightsSeries };
    }

    return allSeries;
  }, [chartState, weightsState]);

  const toggleWeightModal = () => {
    return setManageWeightModal((prevState) => !prevState);
  };

  const renderTooltips = () => {
    const tooltips = {
      programs: [],
      events: [],
      appointments: [],
    };

    SERIES.forEach((serie) => {
      if (hasValues(SERIES[serie]) && serie !== 'weights') {
        tooltips[serie] = chartData[serie].map((annotation, i) => (
          <ReactTooltip
            id={`${serie}-${i}`}
            key={`${serie}-${annotation.start_date}`}
            delayHide={200}
            className='custom'
            effect='solid'
            clickable>
            <Tooltip type={serie}>{getTooltipContent(serie, annotation)}</Tooltip>
          </ReactTooltip>
        ));
      }
    });

    return [...tooltips.programs, ...tooltips.events, ...tooltips.appointments];
  };

  const addTooltipAttributes = (timeout) => {
    setTimeout(() => {
      SERIES.forEach((serie) => {
        if (hasValues(chartData[serie]) && serie !== 'weights') {
          chartData[serie].forEach((annotation, i) => {
            const a = document.getElementsByClassName(`${serie}-${i}-data`)[0];
            if (a) {
              a.setAttribute('data-for', `${serie}-${i}`);
              a.setAttribute('data-tip', '');
            }
          });
        }
      });
      ReactTooltip.rebuild();
    }, timeout);
  };

  const getAnottations = () => {
    const annotattions = {
      programs: [],
      events: [],
      appointments: [],
    };

    SERIES.forEach((serie) => {
      if (hasValues(chartData[serie]) && toggle[serie] && serie !== 'weights') {
        annotattions[serie] = chartData[serie].map((annotation, i) => ({
          x: new Date(moment(annotation.start_date).format()).getTime(),
          borderColor: Theme.series[serie],
          label: {
            borderColor: Theme.series[serie],
            style: {
              color: Theme.white,
              background: Theme.series[serie],
              cssClass: `apexchart-annotations apexcharts-yaxis-annotation-${serie} ${serie}-${i}-data`,
            },
            text: annotation.value ?? annotation.name,
          },
        }));
      }
    });

    return [...annotattions.programs, ...annotattions.events, ...annotattions.appointments];
  };

  return (
    <>
      <ChartWrapper>
        {renderTooltips()}
        {showManageWeightModal && (
          <ManageWeight onClose={toggleWeightModal} weightData={weightData} />
        )}

        <Chart
          options={{
            chart: {
              fontFamily: Theme.primaryFont,
              stacked: false,
              animations: {
                enabled: true,
              },
              zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true,
              },
              toolbar: {
                autoSelected: 'zoom',
              },
              events: {
                mounted() {
                  addTooltipAttributes(1000);
                },
                zoomed() {
                  addTooltipAttributes(0);
                },
                updated() {
                  addTooltipAttributes(0);
                },
                markerClick(e, chartContext, opts) {
                  // double click: do manage weight
                  if (e.detail === 2) {
                    let date = '';
                    let weight = '';
                    let id = null;
                    if (opts.seriesIndex !== -1 && opts.dataPointIndex !== -1) {
                      date = moment(
                        opts.w.globals.seriesX[opts.seriesIndex][opts.dataPointIndex]
                      ).format('YYYY-MM-DD');
                      weight = opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex];

                      if (chartData.weights[opts.dataPointIndex].id) {
                        id = chartData.weights[opts.dataPointIndex].id;
                      }

                      setWeightData({ ...weightData, date, weight, id });
                      return toggleWeightModal();
                    }
                  }
                },
              },
            },
            plotOptions: {
              line: {
                curve: 'smooth',
              },
            },
            stroke: {
              width: [2],
              curve: 'smooth',
            },
            colors: [Theme.series.weights],
            markers: {
              size: [2],
              strokeWidth: 0,
              hover: {
                sizeOffset: 0,
              },
            },
            xaxis: {
              type: 'datetime',
              labels: {
                formatter: (val) => moment(val).format('YYYY-MM-DD'),
              },
            },
            yaxis: {
              labels: {
                // formatter: (val) => `${val} lbs.`
                formatter: (val) => `${val.toFixed(0)} lbs.`,
              },
            },
            annotations: {
              xaxis: getAnottations(),
            },
            fixed: {
              enabled: true,
              position: 'topRight',
              offsetX: 410,
              offsetY: 410,
            },
            tooltip: {
              y: {
                formatter: (val, { seriesIndex, dataPointIndex, w }) => {
                  return `${Number.parseFloat(val.toFixed(2)).toString()}${
                    (w &&
                      ` ${w.globals.initialSeries[seriesIndex].data[dataPointIndex].source}${
                        w.globals.initialSeries[seriesIndex].data[dataPointIndex].isAnomaly
                          ? ' (Anomaly)'
                          : ''
                      }`) ||
                    ''
                  }`;
                },
              },
            },
          }}
          series={[
            {
              name: 'Weight',
              type: 'line',
              data: chartData.weights.map((point) => ({
                x: new Date(moment(point.date).format()).getTime(),
                y: point.value,
                confidence: point.confidence,
                isAnomaly: point.isAnomaly,
                source: point.source ? `(Added by ${point.source})` : '',
              })),
            },
          ]}
          type='line'
          height='100%'
        />
      </ChartWrapper>

      <SeriesWrappers>
        {SERIES.map(
          (serie, index) =>
            hasValues(chartData[serie]) > 0 && (
              <Serie
                key={`${serie}-serie`}
                type={serie}
                visible={toggle[serie]}
                onClick={() =>
                  serie !== 'weights' &&
                  setToggle((prev) => ({
                    ...prev,
                    [serie]: !prev[serie],
                  }))
                }>
                <i className={`serie_${index}`} />
                {capitalize(serie)}
              </Serie>
            )
        )}
      </SeriesWrappers>
    </>
  );
});

Charts.propTypes = {};

Charts.defaultProps = {};

export default Charts;
