import {
  DiagnosisOutput,
  DiagnosisSummaryContentType,
  MedicalProblemOutput,
  MedicalProblemsContentType,
  MeetingContextContentType,
  MeetingDurationContentType,
  ScribeOutputType,
  SummaryContentType,
} from 'node-api/scribe/Scribe.types';
import '../../css/note-edition.css';
import { DiagnosisSummarySection } from './DiagnosisSummarySection';
import { MedicalProblemSection, ProblemOption } from './MedicalProblemSection';
import { MeetingContextSection } from './MeetingContextSection';
import { MeetingDurationSection } from './MeetingDurationSection';

export enum MeetingDurationRange {
  LessThanFifteen = '<= 15 minutes',
  BetweenTwentyAndTwentyFive = '20-25 minutes',
  BetweenThirtyAndForty = '30-40 minutes',
  BetweenFortyAndSixty = '40-65 minutes',
}

export enum MDMCode {
  Straightforward = 'Straightforward',
  Low = 'Low',
  Moderate = 'Moderate',
  High = 'High',
}

export const EvaluationAndManagementByCPTCode: {
  [cptCode: number]: {
    meetingDurationRange: MeetingDurationRange;
  };
} = {
  99212: {
    meetingDurationRange: MeetingDurationRange.LessThanFifteen,
  },
  99213: {
    meetingDurationRange: MeetingDurationRange.BetweenTwentyAndTwentyFive,
  },
  99214: {
    meetingDurationRange: MeetingDurationRange.BetweenThirtyAndForty,
  },
  99215: {
    meetingDurationRange: MeetingDurationRange.BetweenFortyAndSixty,
  },
};

export type NoteEditionHandlers = {
  handleChangeMeetingDuration: (value: MeetingDurationContentType) => void;
  handleDeleteDiagnosis: (premise: keyof Omit<DiagnosisOutput, 'sectionId'>, value: string) => void;
  handleChangeMedicalProblem: (value: {
    sectionId: string;
    order: number;
    outputType: ScribeOutputType.MedicalProblems;
    data: MedicalProblemOutput;
    index: number;
  }) => void;
  handleChangeDiagnosis: (value: {
    premise: keyof Omit<DiagnosisOutput, 'sectionId'>;
    data: string[];
  }) => void;
  handleAddProblem: (problem: ProblemOption) => void;
  handleDeleteProblem: (problemPosition: number) => void;
};

export const renderNoteEditionSectionsByOutputType = (
  outputTypes: ScribeOutputType[],
  data: SummaryContentType[],
  handlers: NoteEditionHandlers
): JSX.Element[] => {
  const sections = data.filter((section) => outputTypes.includes(section.outputType));

  return sections.map((section) => {
    switch (section.outputType) {
      case ScribeOutputType.MeetingContext:
        return <MeetingContextSection content={section as MeetingContextContentType} />;

      case ScribeOutputType.MeetingDuration:
        return (
          <MeetingDurationSection
            content={section as MeetingDurationContentType}
            handlers={handlers}
          />
        );

      case ScribeOutputType.MedicalProblems:
        return (
          <MedicalProblemSection
            content={section as MedicalProblemsContentType}
            handlers={handlers}
          />
        );

      case ScribeOutputType.DiagnosisSummary: {
        const diagnosisContent = {
          ...section,
          meetingDurationContent: data.find(
            (s) => s.outputType === ScribeOutputType.MeetingDuration
          ),
        } as DiagnosisSummaryContentType & {
          meetingDurationContent: MeetingDurationContentType;
        };

        return <DiagnosisSummarySection content={diagnosisContent} handlers={handlers} />;
      }

      default:
        return <>There was an error showing the information</>;
    }
  });
};
