import api from 'api';
import axios from 'axios';
import { Button, CheckBox } from 'components/elements';
import Spinner from 'components/elements/loaders/spinner';
import {
  Box,
  BoxContent,
  Section,
  SectionMessage,
  SectionTab,
  SectionTabs,
  SectionTitle,
} from 'components/general-styles';
import Icon from 'components/icon';
import { Task } from 'lib/types';
import moment from 'moment-timezone';
import { FC, useContext, useEffect, useState } from 'react';
import { TaskContext } from 'state/contexts/task';
import { UserContext } from 'state/contexts/user';
import { ActionTypes } from 'state/reducers/task';
import Theme from 'styles/theme';
import { debug } from 'utils/helpers';
import { Action, Content, Description, Details, DueDate, Filter, Item, List } from './style';

type Props = {
  patientId: number;
};

const TasksBox: FC<Props> = ({ patientId }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [onlyMe, setOnlyMe] = useState<boolean>(true);
  const [submittingId, setSubmittingId] = useState<number>();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [selected, setSelected] = useState<'completed' | 'active' | 'future'>('active');

  const { state: taskState, dispatch: taskDispatch } = useContext(TaskContext);
  const { state: userState } = useContext(UserContext);

  const today = moment(new Date()).format('YYYYMMDD');
  const source = axios.CancelToken.source();

  const updateTask = async (taskId: number, status: boolean) => {
    setSubmittingId(taskId);
    setSubmitting(true);

    try {
      await api().put(`tasks/${taskId}/update?auth_token=AUTH_TOKEN&complete=${status}`);

      taskDispatch({
        type: ActionTypes.Update,
        payload: {
          id: patientId,
          taskId,
          status,
        },
      });
    } catch (err) {
      debug(err as string);
    }

    setSubmitting(false);
    setSubmittingId(0);
  };

  const getTasks = async () => {
    try {
      const { data } = await api().get(`tasks/${patientId}/AUTH_TOKEN`, {
        cancelToken: source.token,
      });

      taskDispatch({
        type: ActionTypes.Set,
        payload: {
          id: patientId,
          data: data.tasks || [],
        },
      });

      setLoading(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        debug(err as string);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (taskState.data[patientId]) {
      setTasks(taskState.data[patientId]);
    }
  }, [taskState.data]);

  useEffect(() => {
    if (taskState.reload !== 0) {
      setTimeout(() => {
        setLoading(true);
        getTasks();
      }, 1000);
    }
  }, [taskState.reload]);

  useEffect(() => {
    setLoading(true);
    setTasks([]);
    getTasks();

    return () => {
      source.cancel();
    };
  }, [patientId]);

  const filteredTasks = tasks
    .filter((t) => {
      const start_date = moment(t.start_date).format('YYYYMMDD');
      if (selected === 'completed') {
        if (onlyMe) return t.status === true && userState.id === t.assigned_to_id;
        return t.status === true;
      }
      if (selected === 'active') {
        if (onlyMe)
          return (
            t.status === false &&
            userState.id === t.assigned_to_id &&
            (t.start_date === null || start_date <= today)
          );
        return t.status === false && (t.start_date === null || start_date <= today);
      }
      // future
      if (onlyMe)
        return (
          t.status === false &&
          userState.id === t.assigned_to_id &&
          t.start_date &&
          start_date > today
        );
      return t.status === false && t.start_date && start_date > today;
    })
    .sort((a, b) => {
      return b.start_date > a.start_date ? 1 : -1;
    });

  return (
    <Section>
      <SectionTitle>Tasks</SectionTitle>

      <Box>
        <BoxContent>
          <SectionTabs>
            <SectionTab selected={selected === 'active'} onClick={() => setSelected('active')}>
              Active
            </SectionTab>
            <SectionTab
              selected={selected === 'completed'}
              onClick={() => setSelected('completed')}>
              Completed
            </SectionTab>
            <SectionTab selected={selected === 'future'} onClick={() => setSelected('future')}>
              Future
            </SectionTab>
          </SectionTabs>

          <Filter>
            <CheckBox checked={onlyMe} onClick={() => setOnlyMe((state) => !state)} />
            Show only my tasks
          </Filter>

          {loading && (
            <SectionMessage>
              <p>Loading tasks</p>
              <Spinner size={20} />
            </SectionMessage>
          )}

          {!loading && filteredTasks.length === 0 && (
            <SectionMessage>
              <p>No Tasks</p>
            </SectionMessage>
          )}

          {!loading && filteredTasks.length > 0 && (
            <List>
              {filteredTasks.map((t) => (
                <Item key={`task-${t.id}`}>
                  <Icon icon='task' color='white' background='gray' size={20} />

                  <Content>
                    <Details>
                      <strong>{t.assignee}</strong>
                      {' to '}
                      <span>
                        <strong>{t.assigned_to_name}</strong>
                        {t.start_date && ` on ${moment(t.start_date).utc().format('MM/DD/YY')}`}
                        {t.due_date && (
                          <DueDate
                            pastDue={
                              selected === 'active' && moment(t.due_date).format('YYYYMMDD') < today
                            }>
                            {` and due at ${moment(t.due_date).format('MM/DD/YY')}`}
                          </DueDate>
                        )}
                      </span>
                    </Details>

                    {t.completed_by && (
                      <Details>
                        Completed by {t.completed_by} on {moment(t.date_updated).format('MM/DD/YY')}
                      </Details>
                    )}
                    <Description>{t.description.trim()}</Description>
                  </Content>

                  <Action>
                    <Button
                      color={Theme.salmon}
                      onClick={() => updateTask(t.id, !t.status)}
                      submitting={submitting && submittingId === t.id}>
                      {submittingId !== t.id && <Icon icon='suitcase' color='white' size={16} />}
                      {!t.status ? 'Mark as completed' : 'Mark as uncompleted'}
                    </Button>
                  </Action>
                </Item>
              ))}
            </List>
          )}
        </BoxContent>
      </Box>
    </Section>
  );
};

export default TasksBox;
