import api from 'api';
import axios from 'axios';
import { StatsList } from 'components/elements';
import Spinner from 'components/elements/loaders/spinner';
import { Section, SectionMessage } from 'components/general-styles';
import { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import MixpanelService, { ClickElementTypes } from 'services/mixpanel-service';
import { debug, percentageFormat, weightFormat } from 'utils/helpers';

const PatientsHighLevelStats: FC<RouteComponentProps> = ({ history }) => {
  const [stats, setStats] = useState<
    {
      value: number | string;
      format?: (source: number, decimals: number) => void;
      icon: string;
      label: string;
      onClick?: () => void;
      link?: {
        to: string;
        text: string;
        can?: { I: string; on: string };
        onclick?: void;
      };
      isVisible: boolean;
    }[]
  >([]);
  const [loading, setLoading] = useState(true);

  const processStatsToRender = (data: {
    total_patients: number;
    weight_loss_percent: number;
    weight_loss_total: number;
  }) => {
    const statsList = [
      {
        value: data.total_patients,
        icon: 'user',
        label: 'Total Patients',
        onClick: () => history.push('/patients_statuses'),
        link: {
          to: '/members',
          text: 'View Profiles',
          can: { I: 'enter', on: 'Patients' },
          onclick: MixpanelService.track('Changed section', { Section: 'Patients' }),
        },
        isVisible: true,
      },
      {
        value: data.weight_loss_percent,
        format: percentageFormat,
        icon: 'decreaseChart',
        label: 'Average Weight Loss',
        onClick: () => {
          history.push('/wl_stats');
          MixpanelService.trackClick({
            customEventName: 'Clicked Initial Dashboard',
            name: 'Average Weight Loss',
            type: ClickElementTypes.IMAGE,
          });
        },
        isVisible: true,
      },
      {
        value: '',
        icon: 'decreaseChart',
        label: 'Performance Insights',
        onClick: () => {
          history.push('/dashboards/performance-insights');
          MixpanelService.trackClick({
            customEventName: 'Clicked Performance Insights Dashboard',
            name: 'Performance Insights Dashboard',
            type: ClickElementTypes.IMAGE,
          });
        },
        link: {
          to: '/dashboards/performance-insights',
          text: 'View Dashboard',
          can: { I: 'enter', on: 'Dashboard' },
        },
        isVisible: true,
      },
      {
        value: data.weight_loss_total,
        format: weightFormat,
        icon: 'weightScale',
        label: 'Total Weight Loss',
        isVisible: true,
      },
    ].filter((stat) => stat.isVisible);

    setStats(statsList);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getStats = async () => {
      try {
        const { data } = await api().get<{
          total_patients: number;
          weight_loss_total: number;
          weight_loss_percent: number;
        }>('stats/provider/AUTH_ID/AUTH_TOKEN', {
          cancelToken: source.token,
        });

        processStatsToRender(data);
        setLoading(false);
      } catch (err) {
        if (!axios.isCancel(err)) {
          debug(err as string);
          setLoading(false);
        }
      }
    };

    getStats();

    return () => {
      source.cancel();
    };
  }, []);

  return (
    <Section>
      {loading ? (
        <SectionMessage>
          <p>Loading stats</p>
          <Spinner size={20} />
        </SectionMessage>
      ) : stats.length > 0 ? (
        <StatsList data={stats} bordered />
      ) : (
        <SectionMessage>
          <p>There are no stats available.</p>
        </SectionMessage>
      )}
    </Section>
  );
};

export default PatientsHighLevelStats;
