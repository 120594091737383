/* eslint-disable */
import { Typography } from '@material-ui/core';
import axios from 'axios';
import { Badge, Button, CheckBox } from 'components/elements';
import Icon from 'components/icon';
import Toast from 'components/toast';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  ChangeElementTypes,
  ClickElementTypes,
  EventHandlerNames,
  TrackableElement,
} from 'services/mixpanel-service';
import PictureService from 'services/picture';
import { CommentContext } from 'state/contexts/comment';
import { FeaturesContext } from 'state/contexts/features';
import { PatientContext } from 'state/contexts/patient';
import { debug } from 'utils/helpers';
import { DEFAULT_TIMEZONE } from 'utils/timezones';
import breakfast from '../../../icon/pngs/breakfast.png';
import commentIcon from '../../../icon/pngs/comments-40.png';
import dinner from '../../../icon/pngs/dinner.png';
import lunch from '../../../icon/pngs/lunch.png';
import previous from '../../../icon/pngs/previous_circle.png';
import {
  BodyAverage,
  BodyContainer,
  BodyInnerContainer,
  BodyLine,
  BodyTitle,
  CalendarIcon,
  Cards,
  CommentsCount,
  CommentsCountWrapper,
  DateFilter,
  Datepicker,
  DatepickerContainer,
  Field,
  FilterWrapper,
  Image,
  ImageHeader,
  ImageTimestamp,
  SingleImageContainer,
  UnreviewedPicturesFilter,
  WeekRange,
} from './style';

import { ActionTypes } from 'state/reducers/comment';
import { getFoodsFromCore } from './api';

const Food = ({ patientId, setPicturesTabUnreviewed }) => {
  const { state: featuresState } = useContext(FeaturesContext);
  const { dispatch: commentDispatch } = useContext(CommentContext);
  const { state: patientState } = useContext(PatientContext);
  const patient = patientState.data.find((p) => p.id === patientId);
  const patientTimezone = patient?.timezone || DEFAULT_TIMEZONE;
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const selectedDateRange = useMemo(() => {
    const end = moment(selectedDate, 'YYYY-MM-DD').toDate();
    const endFormatted = selectedDate;
    const mStart = moment(end).subtract(6, 'days');
    const start = mStart.toDate();
    const startFormatted = mStart.format('YYYY-MM-DD');

    const obj = { start, end, startFormatted, endFormatted };
    return obj;
  }, [selectedDate]);

  const getSearchDateRange = (selectedDate) => {
    const end = moment(selectedDate).add(1, 'month').format('YYYY-MM-07');
    const start = moment(selectedDate).subtract(1, 'month').format('YYYY-MM-22');
    const obj = { start, end };
    return obj;
  };

  const refAllMealPictures = useRef([]);
  const refUnreviewedMealPictures = useRef([]);

  const [onlyUnreviewed, setOnlyUnreviewed] = useState(false);
  const [updateWeekPictures, setUpdateWeekPictures] = useState(true);

  const [selectedMonth, setSelectedMonth] = useState(moment(new Date()).format('YYYY-MM'));
  const [previousSelectedMonth, setPreviousSelectedMonth] = useState();

  const searchDateRange = useMemo(() => {
    const sections = selectedMonth.split('-');
    const year = parseInt(sections[0], 10);
    const month = parseInt(sections[1], 10) - 1;
    return getSearchDateRange(moment().year(year).month(month));
  }, [selectedMonth]);

  const [mealPictures, setMealPictures] = useState([]);
  const [weekPictures, setWeekPictures] = useState([]);
  const [unreviewedPictures, setUnreviewedPictures] = useState([]);

  const [breakfastAverage, setBreakfastAverage] = useState('NA');
  const [lunchAverage, setLunchAverage] = useState('NA');
  const [dinnerAverage, setDinnerAverage] = useState('NA');
  const [highlightedDates, setHighlightedDates] = useState([
    { 'react-datepicker__day--highlighted-gray': [] },
    { 'react-datepicker__day--highlighted-green': [] },
  ]);

  const calcAverageTime = (wPictures) => {
    const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;

    const removeDateFromTimestamp = (timestamp) => {
      const time = moment.tz(moment.unix(timestamp), patientTimezone);
      const totalSeconds = time.hour() * 3600 + time.minutes() * 60 + time.seconds();
      return totalSeconds;
    };

    const breakfastTimes = [];
    const lunchTimes = [];
    const dinnerTimes = [];

    wPictures.forEach((meal) => {
      meal.pictures.sort((a, b) => a.timestamp - b.timestamp);
      const bPic = meal.pictures.find((pic) => pic.label === 'Breakfast');
      const lPic = meal.pictures.find((pic) => pic.label === 'Lunch');
      const dPic = meal.pictures.find((pic) => pic.label === 'Dinner');
      if (bPic) {
        breakfastTimes.push(removeDateFromTimestamp(bPic.timestamp));
      }
      if (lPic) {
        lunchTimes.push(removeDateFromTimestamp(lPic.timestamp));
      }
      if (dPic) {
        dinnerTimes.push(removeDateFromTimestamp(dPic.timestamp));
      }
    });
    const bAvg = average(breakfastTimes);
    const lAvg = average(lunchTimes);
    const dAvg = average(dinnerTimes);
    setBreakfastAverage((bAvg && moment.tz(moment.unix(bAvg), 'UTC').format('h:mm a')) || 'NA');
    setLunchAverage((lAvg && moment.tz(moment.unix(lAvg), 'UTC').format('h:mm a')) || 'NA');
    setDinnerAverage((dAvg && moment.tz(moment.unix(dAvg), 'UTC').format('h:mm a')) || 'NA');
  };

  const toggleCommentModal = (element_id, elements, reviewPictureByIdCallback) => {
    commentDispatch({
      type: ActionTypes.TOGGLE_MODAL,
      payload: { element_id, elements, callback: reviewPictureByIdCallback },
    });
  };

  const reviewPictureInTheWeeklyView = (pictureId) => {
    for (const day of refAllMealPictures.current) {
      const viewedPicture = day.pictures.find((pic) => pic.id === pictureId);
      if (viewedPicture) {
        viewedPicture.all_comments_reviewed = true;
        viewedPicture.seen = true;
        break;
      }
    }
  };

  const reviewPictureInTheAllUnreviewedPicturesView = (pictureId) => {
    for (let weekIndex = 0; weekIndex < refUnreviewedMealPictures.current.length; weekIndex++) {
      const week = refUnreviewedMealPictures.current[weekIndex];
      for (let dayIndex = 0; dayIndex < week.days.length; dayIndex++) {
        const day = week.days[dayIndex];
        const viewedPictureIndex = day.food_pictures.findIndex((pic) => pic.id === pictureId);
        if (viewedPictureIndex >= 0) {
          day.food_pictures.splice(viewedPictureIndex, 1);

          // remove day element once there are no pictures to review in the day
          if (day.food_pictures.length === 0) {
            week.days.splice(dayIndex, 1);
          }
          // remove week element once there are no days to review in the week
          if (week.days.length === 0) {
            refUnreviewedMealPictures.current.splice(weekIndex, 1);
          }
          break;
        }
      }
    }
  };

  const setPicturesStates = () => {
    if (onlyUnreviewed) {
      setUnreviewedPictures(refUnreviewedMealPictures.current);
    } else {
      setMealPictures(refAllMealPictures.current);
      fillWeekPictures(refAllMealPictures.current);
    }
  };

  const reviewPictureByIdCallback = (pictureId) => {
    if (pictureId) {
      // 1: Review the first state -> page with the calendar (weekly view)
      reviewPictureInTheWeeklyView(pictureId);
      // 2: Review the second state -> all unreviewed pictures view
      reviewPictureInTheAllUnreviewedPicturesView(pictureId);
      // 3: Removing the notification from the pictures tab when there are no more pictures to review
      setPicturesTabUnreviewed(refUnreviewedMealPictures.current.length !== 0);
      // 4: Set new states
      setPicturesStates();
    }
  };

  const prepareViewFoodData = () => {
    const pictures = [];
    weekPictures.forEach((meal) => {
      meal.pictures.forEach((picture) => {
        pictures.push(picture);
      });
    });
    return pictures.sort((a, b) => a.timestamp - b.timestamp);
  };

  const getPictureLabel = (datetime) => {
    const date = moment().hours(datetime.hour()).minutes(datetime.minute());
    if (date.isBefore(moment('11:00', 'HH:mm'))) {
      return 'Breakfast';
    }
    if (date.isBefore(moment('15:00', 'HH:mm'))) {
      return 'Lunch';
    }
    return 'Dinner';
  };

  const getDateLabel = (day) => {
    const today = moment(new Date()).format('YYYY-MM-DD');
    const yesterday = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');

    if (day === today) {
      return 'Today';
    }
    if (day === yesterday) {
      return 'Yesterday';
    }
    return moment(day).format('dddd');
  };

  const handleChange = (date) => {
    const newEnd = moment(date);

    setSelectedMonth((prev) => {
      setPreviousSelectedMonth(prev);
      return newEnd.format('YYYY-MM');
    });

    const sDate = newEnd.format('YYYY-MM-DD');
    // force updating weekPictures only when the month is changed
    if (!sDate.startsWith(selectedMonth)) {
      setUpdateWeekPictures(true);
    }

    setSelectedDate(sDate);
  };

  const handleMonthChange = (date) => {
    setSelectedMonth((prev) => {
      setPreviousSelectedMonth(prev);
      return moment(date).format('YYYY-MM');
    });
  };

  const onClickPreviousWeek = () => {
    const newEnd = moment(selectedDateRange.end).subtract(7, 'days');

    setSelectedMonth((prev) => {
      setPreviousSelectedMonth(prev);
      return newEnd.format('YYYY-MM');
    });

    const sDate = newEnd.format('YYYY-MM-DD');
    // force updating weekPictures only when the month is changed
    if (!sDate.startsWith(selectedMonth)) {
      setUpdateWeekPictures(true);
    }
    setSelectedDate(sDate);
  };

  const highligthDates = (mPictures) => {
    const mPics = mPictures || mealPictures;
    const dates = [
      { 'react-datepicker__day--highlighted-gray': [] },
      { 'react-datepicker__day--highlighted-green': [] },
    ];
    for (let i = 0; i < mPics.length; i += 1) {
      dates[1]['react-datepicker__day--highlighted-green'].push(
        moment(mPics[i].day, 'YYYY-MM-DD').toDate()
      );
    }

    for (let i = 0; i < 7; i++) {
      const d = moment(selectedDate, 'YYYY-MM-DD').subtract(i, 'days').toDate();
      dates[0]['react-datepicker__day--highlighted-gray'].push(d);
    }
    setHighlightedDates(dates);
  };

  const fillWeekPictures = (mPictures) => {
    // fill the week pictures variable
    const wPictures = [];
    (mPictures || mealPictures).forEach((dayPictures) => {
      if (
        dayPictures.day >= selectedDateRange.startFormatted &&
        dayPictures.day <= selectedDateRange.endFormatted
      ) {
        wPictures.push(dayPictures);
      }
    });
    calcAverageTime(wPictures);
    setWeekPictures(wPictures);
  };

  const getSingleImageContainer = (pictureObject, modalPictures) => (
    <SingleImageContainer key={`${pictureObject.id}`} label={pictureObject.label}>
      <ImageHeader>
        <ImageTimestamp>{pictureObject.datetimeFormatted}</ImageTimestamp>
        <Badge
          key={`badge-picture-${pictureObject.id}`}
          // TODO: seen field needs to be returned from the BE
          visible={!pictureObject.all_comments_reviewed || pictureObject.seen === false}
          top={1}
          right={1}>
          <CommentsCountWrapper>
            <div>
              <img src={commentIcon} alt='' />
            </div>
            <CommentsCount> {pictureObject.comments_count}</CommentsCount>
          </CommentsCountWrapper>
        </Badge>
      </ImageHeader>
      <TrackableElement
        name='Picture'
        type={ClickElementTypes.IMAGE}
        eventHandlerName={EventHandlerNames.ONCLICK}>
        <Image
          src={pictureObject.image_url}
          alt=''
          onClick={() =>
            toggleCommentModal(pictureObject.id, modalPictures, reviewPictureByIdCallback)
          }
        />
      </TrackableElement>
    </SingleImageContainer>
  );

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getUnreviewedFoodPictures = async () => {
      let data;
      try {
        data = await PictureService.unreviewedFoodPictures(
          {
            memberId: patientId,
          },
          source.token
        );
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error(err);
          Toast.show('error', "Couldn't load the unreviewed pictures!");
        }
      }
      if (!data) {
        Toast.show('error', "Couldn't load the unreviewed pictures!");
        refUnreviewedMealPictures.current = [];
        return;
      }

      refUnreviewedMealPictures.current = data.foods.weeks || [];
      setPicturesTabUnreviewed(refUnreviewedMealPictures.current.length > 0);
      setUnreviewedPictures(refUnreviewedMealPictures.current);
    };

    getUnreviewedFoodPictures();
    return () => {
      source.cancel();
    };
  }, [featuresState, patientId]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getFoods = async () => {
      try {
        const data =
          (await getFoodsFromCore(
            patientId,
            searchDateRange.start,
            searchDateRange.end,
            source.token
          )) || [];
        const allMealPictures = [];
        data.foods.forEach((day) => {
          day.date_foods.forEach((picture, index) => {
            const pictureDatetime = moment.tz(moment.unix(picture.timestamp), patientTimezone);
            const pictureDate = pictureDatetime.format('YYYY-MM-DD');
            const pictureData = {
              label: getPictureLabel(pictureDatetime),
              datetime: pictureDatetime,
              datetimeFormatted: pictureDatetime.format('MMM Do YYYY, h:mm a z'),
              ...picture,
            };

            const ind = allMealPictures.findIndex((x) => x.day === pictureDate);
            if (ind < 0) {
              allMealPictures.push({ day: pictureDate, pictures: [pictureData] });
            } else {
              allMealPictures[ind].pictures.push(pictureData);
            }
          });
        });

        refAllMealPictures.current = allMealPictures;

        setMealPictures(refAllMealPictures.current);
        highligthDates(refAllMealPictures.current);
        if (updateWeekPictures) {
          fillWeekPictures(refAllMealPictures.current);
          setUpdateWeekPictures(false);
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          debug(err);
        }
      }
    };

    getFoods();
    return () => {
      source.cancel();
    };
  }, [searchDateRange]);

  useEffect(() => {
    // skiping if the month has changed, we will be calling the BE
    if (selectedMonth === previousSelectedMonth) {
      fillWeekPictures();
      highligthDates();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (onlyUnreviewed) {
      setUnreviewedPictures(refUnreviewedMealPictures.current);
    } else {
      setMealPictures(refAllMealPictures.current);
      highligthDates(refAllMealPictures.current);
      fillWeekPictures(refAllMealPictures.current);
    }
  }, [onlyUnreviewed]);

  return (
    <>
      <BodyContainer>
        <FilterWrapper>
          <UnreviewedPicturesFilter>
            <TrackableElement
              name='Show only unreviewed pictures by week'
              type={ChangeElementTypes.CHECKBOX}
              value={!onlyUnreviewed}
              eventHandlerName={EventHandlerNames.ONCLICK}>
              <CheckBox
                checked={onlyUnreviewed}
                onClick={() => {
                  setOnlyUnreviewed((state) => !state);
                }}
              />
            </TrackableElement>
            Show only unreviewed pictures by week
          </UnreviewedPicturesFilter>

          {!onlyUnreviewed && (
            <DateFilter>
              <Button variant='link' onClick={onClickPreviousWeek}>
                <img src={previous} width={18} height={18} color='teal' alt='' />
                View Previous Week
              </Button>
              <Field>
                <DatepickerContainer>
                  <Datepicker
                    id='select_week-input'
                    name='select_week'
                    selected={selectedDateRange.end}
                    autoComplete='off'
                    onChange={handleChange}
                    onMonthChange={handleMonthChange}
                    dateFormat='yyyy-MM-dd'
                    placeholderText='Select Week'
                    maxDate={new Date()}
                    showMonthDropdown
                    dropdownMode='select'
                    highlightDates={highlightedDates}
                  />
                  <CalendarIcon>
                    <Icon icon='schedule' size={18} color='teal' />
                  </CalendarIcon>
                </DatepickerContainer>
              </Field>
            </DateFilter>
          )}
        </FilterWrapper>

        <BodyAverage>
          {!onlyUnreviewed && weekPictures.length > 0 && (
            <>
              <div>
                <img src={breakfast} width={30} height={30} color='teal' alt='' />
                <span> {breakfastAverage} </span>
              </div>
              <div>
                <img src={lunch} width={30} height={30} color='teal' alt='' />
                <span> {lunchAverage} </span>
              </div>
              <div>
                <img src={dinner} width={30} height={30} color='teal' alt='' />
                <span> {dinnerAverage} </span>
              </div>
            </>
          )}
        </BodyAverage>

        <BodyLine />
        <BodyInnerContainer>
          {onlyUnreviewed ? (
            <>
              {unreviewedPictures.length <= 0 ? (
                <Typography variant='body1'>No unreviewed pictures</Typography>
              ) : (
                unreviewedPictures.map((week, weekIndex) => (
                  <div key={`week-${weekIndex}`}>
                    <WeekRange empty={false}>
                      <span> {`Week :  ${week.starts_at}  -  ${week.ends_at}`} </span>
                    </WeekRange>
                    {week.days.length > 0 &&
                      week.days.map((day, index) => (
                        <div key={`day-${day.name}-${index}`}>
                          <BodyTitle>
                            <span> {day.name} </span>
                          </BodyTitle>
                          <Cards>
                            {day.food_pictures
                              .sort((a, b) => a.timestamp - b.timestamp)
                              .map((picture, i) => {
                                const pictureDatetime = moment.tz(
                                  moment.unix(picture.timestamp),
                                  patientTimezone
                                );
                                const pictureData = {
                                  label: getPictureLabel(pictureDatetime),
                                  datetime: pictureDatetime,
                                  datetimeFormatted:
                                    pictureDatetime.format('MMM Do YYYY, h:mm a z'),
                                  ...picture,
                                };
                                return getSingleImageContainer(pictureData, [pictureData]);
                              })}
                          </Cards>
                        </div>
                      ))}
                  </div>
                ))
              )}
            </>
          ) : (
            <>
              <WeekRange empty={weekPictures.length === 0}>
                <span>
                  {' '}
                  {`Week :  ${selectedDateRange.startFormatted}  -  ${selectedDateRange.endFormatted}`}{' '}
                </span>
              </WeekRange>
              {weekPictures.length > 0 &&
                weekPictures.map((dayMeals, index) => (
                  <div key={`day-${dayMeals.day}-${index}`}>
                    <BodyTitle>
                      <span> {getDateLabel(dayMeals.day)} </span>
                    </BodyTitle>
                    <Cards>
                      {dayMeals.pictures
                        .sort((a, b) => a.timestamp - b.timestamp)
                        .map((picture, i) => {
                          return getSingleImageContainer(picture, prepareViewFoodData());
                        })}
                    </Cards>
                  </div>
                ))}
            </>
          )}
        </BodyInnerContainer>
      </BodyContainer>
    </>
  );
};

Food.propTypes = {
  patientId: PropTypes.number.isRequired,
};

export default Food;
