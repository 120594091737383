export const DIAGNOSES = [
  'Morbid Obesity',
  'Obesity',
  'Overweight',
  'Weight Gain',
  'Depression',
  'Anxiety',
  'Hyperlipidemia',
  'Diabetes type II',
  'PCOS',
  'Constipation',
  'Binge eating syndrome',
  'CAD',
  'Stroke',
  'Non ischemic cardiomyopathy',
  'Fatigue',
  'Snoring',
  'Polyphagia',
];
