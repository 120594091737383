import styled from 'styled-components';

export const AddWeightContent = styled.div`
  top: 10px;
  right: 10px;

  & > button {
    display: contents;
    i {
      margin: 0;
      height: 25px;
      width: 25px;

      svg {
        height: calc(100% - 12px);
        width: calc(100% - 12px);
      }
    }
  }
`;

export const Stats = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 0 16px;
  padding: 32px;

  div {
    display: flex;
    flex-direction: column;
    margin-right: 32px;

    &:last-child {
      margin: 0;
    }
  }

  h4 {
    color: ${({ theme }) => theme.gray};
    font: 600 15px ${({ theme }) => theme.primaryFont};
    margin: 0;
  }

  span {
    color: ${({ theme }) => theme.gray};
    font: 300 13px ${({ theme }) => theme.primaryFont};
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.grayDark};
    font: 600 23px ${({ theme }) => theme.primaryFont};
    margin: 4px 0 0;
  }

  h4 + p {
    margin: 18px 0 0;
  }
`;
