import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';
import { Action, State, initialState, reducer } from '../reducers/provider';

const ProviderContext = createContext<{ state: State; dispatch: Dispatch<Action> }>({
  state: initialState,
  dispatch: () => null,
});

const ProviderProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ProviderContext.Provider value={{ state, dispatch }}>{children}</ProviderContext.Provider>
  );
};

export { ProviderContext, ProviderProvider };
