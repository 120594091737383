import { FC, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import {
  TrackableElementProps,
  TrackableElement,
  ClickElementTypes,
  EventHandlerNames,
} from 'services/mixpanel-service';
import { Thumbnail } from './style';

interface ClickableImageProps {
  imageURL: string;
  mixpanelProps: TrackableElementProps;
}

const ClickableImageElement: FC<ClickableImageProps> = ({ imageURL, mixpanelProps }) => {
  const [lightboxSrc, setLightboxSrc] = useState<string | null>(null);
  const { name: eventName, ...mixpanelRemainingProps } = mixpanelProps;
  return (
    <>
      {imageURL ? (
        <>
          <TrackableElement
            name={eventName}
            type={ClickElementTypes.IMAGE}
            eventHandlerName={EventHandlerNames.ONCLICK}
            mixpanelProps={mixpanelRemainingProps}>
            <Thumbnail imageURL={imageURL} onClick={() => setLightboxSrc(imageURL)} />
          </TrackableElement>
          {lightboxSrc && (
            <Lightbox mainSrc={lightboxSrc} onCloseRequest={() => setLightboxSrc(null)} />
          )}
        </>
      ) : null}
    </>
  );
};

export default ClickableImageElement;
