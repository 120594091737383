import {
  calculateBmi,
  convertFeetNInchesToMeters,
  convertGramsToLbs,
  convertLbsToKgs,
} from 'helpers/healthData';
import { round } from 'helpers/math';
import moment from 'moment';
import { Reducer } from 'react';
import { Action as DefaultAction } from 'state/types';

export enum ActionTypes {
  ADD_WEIGHT = 'ADD_WEIGHT',
  DELETE_WEIGHT = 'DELETE_WEIGHT',
  SET = 'SET',
}

export type State = {
  bmi: {
    current: number;
    initial: number;
  };
  weight: {
    initial: number;
    stats: {
      weightLoss: number;
      weightLossPercentage: number;
    };
    weights: {
      date: string;
      id: number; // Health Records ID
      source: string;
      value: number;
      isAnomaly?: boolean;
      confidence?: number;
    }[];
  };
};

type Payload = {
  [ActionTypes.ADD_WEIGHT]: {
    weight: State['weight']['weights'][0];
  };
  [ActionTypes.DELETE_WEIGHT]: {
    weight: Pick<State['weight']['weights'][0], 'id'>;
  };
  [ActionTypes.SET]: {
    height: {
      feet: number;
      inches: number;
    };
    initialWeightInLbs: number;
    weightsInGrams: State['weight']['weights'];
  };
};

export type Action = DefaultAction<Payload>;

export const initialState: State = {
  bmi: {
    current: 0,
    initial: 0,
  },
  weight: {
    initial: 0,
    stats: {
      weightLoss: 0,
      weightLossPercentage: 0,
    },
    weights: [],
  },
};

export const reducer: Reducer<State, Action> = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.ADD_WEIGHT: {
      const { weight: newWeight } = action.payload;
      const { weights } = state.weight;

      // Calculate new list with sorted weights
      const sortedWeights = [...weights, newWeight].sort(
        (a, b) => Number(moment(a.date)) - Number(moment(b.date))
      );

      // Pre compute some values
      const initialWeight =
        state.weight.initial === 0 ? sortedWeights[0].value : state.weight.initial;
      const weightLoss = initialWeight - sortedWeights[sortedWeights.length - 1].value;
      return {
        ...state,
        weight: {
          initial: initialWeight,
          stats: {
            weightLoss: round(weightLoss, 2),
            weightLossPercentage: round((weightLoss / initialWeight) * 100, 2),
          },
          weights: sortedWeights,
        },
      };
    }
    case ActionTypes.DELETE_WEIGHT: {
      const { weight: deletedWeight } = action.payload;
      const { initial, weights } = state.weight;

      // Remove the deleted weight
      const afterDeleteWeights = weights.filter(({ id }) => deletedWeight.id !== id);

      // Pre compute some values
      const weightLoss = initial - afterDeleteWeights[afterDeleteWeights.length - 1].value;
      return {
        ...state,
        weight: {
          initial,
          stats: {
            weightLoss: round(weightLoss, 2),
            weightLossPercentage: round((weightLoss / initial) * 100, 2),
          },
          weights: afterDeleteWeights,
        },
      };
    }
    case ActionTypes.SET: {
      const { height, initialWeightInLbs, weightsInGrams } = action.payload;
      let currentBmi = 0;
      let initialBmi = 0;
      let weightLossInLbs = 0;
      let weightLossPercentage = 0;

      // Weights should always be sorted ASC + in lbs
      const sortedWeights = weightsInGrams
        .sort((a, b) => Number(moment(a.date)) - Number(moment(b.date)))
        .map(({ value: valueInGrams, ...rest }) => ({
          ...rest,
          value: round(convertGramsToLbs(valueInGrams), 2),
        }));

      // Set initial weight
      const initialOrFirstWeightInLbs = sortedWeights[0]?.value ?? initialWeightInLbs ?? 0;

      // Calculate bmi
      if (!!initialOrFirstWeightInLbs && (height?.feet || height?.inches)) {
        initialBmi = round(
          calculateBmi(
            convertLbsToKgs(Number.parseFloat(`${initialOrFirstWeightInLbs}`)),
            convertFeetNInchesToMeters(height.feet ?? 0, height.inches ?? 0)
          ),
          2
        );
      }

      const currentWeightInLbs = sortedWeights[sortedWeights.length - 1]?.value ?? null;
      if (!!currentWeightInLbs && (height?.feet || height?.inches)) {
        currentBmi = round(
          calculateBmi(
            convertLbsToKgs(Number.parseFloat(`${currentWeightInLbs}`)),
            convertFeetNInchesToMeters(height.feet ?? 0, height.inches ?? 0)
          ),
          2
        );
      }

      // Calculate stats
      if (!!initialOrFirstWeightInLbs && !!currentWeightInLbs) {
        weightLossInLbs = round(initialOrFirstWeightInLbs - currentWeightInLbs, 2);
        if (weightLossInLbs !== 0) {
          weightLossPercentage = round((weightLossInLbs / initialOrFirstWeightInLbs) * 100, 2);
        }
      }

      return {
        bmi: { current: currentBmi, initial: initialBmi },
        weight: {
          initial: initialOrFirstWeightInLbs,
          stats: { weightLoss: weightLossInLbs, weightLossPercentage },
          weights: sortedWeights,
        },
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};
